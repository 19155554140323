import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query';

import { Loader } from '@/components/common/Loader';
import { IPagination } from '@/interface/pagination.interface';

export const useFetchQuerys = <TData,>(
	queryFn: QueryFunction<TData, QueryKey>,
	queryKey: QueryKey,
	options?: IPagination,
) => {
	const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
		queryKey,
		queryFn,
		staleTime: Infinity,
		...options,
	});

	const renderLoading = () => {
		if (isLoading || isFetching) {
			return (
				<div className="w-full">
					<Loader size={4} />
				</div>
			);
		}
		return null;
	};

	const renderError = () => {
		if (isError) {
			return <div>Error: {error.message}</div>;
		}
		return null;
	};

	return {
		data,
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
		renderLoading,
		renderError,
	};
};
