import { useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-responsive-modal';

import arrowUpRightIcon from '@/assets/svg/arrow-up-right.svg';
import personIcon from '@/assets/svg/person-icon.svg';
import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { Title } from '@/components/common/Title/Title';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';
import { SocialAccount, User } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { editUserValidationSchema } from '@/helper/validations/edit-user-profile-validation';
import { editChef } from '@/service/api/chef';
import { editUser } from '@/service/api/user';

export enum SocialMedia {
	FACEBOOK = 'facebook',
	TWITTER = 'twitter',
	INSTAGRAM = 'instagram',
	YOUTUBE = 'youtube',
}
interface ProfileUserSettingsProps {
	user: User;
}

function hasSocialUsernameChange(
	socialAccountValues: Partial<SocialAccount>[],
	currentSocialAccount: Partial<SocialAccount>[],
) {
	for (let i = 0; i < socialAccountValues.length; i++) {
		const element = currentSocialAccount.find(
			(social) => social.name === socialAccountValues[i].name,
		);
		if (!element) {
			if (socialAccountValues[i].username !== '') {
				return true;
			}
		} else {
			if (socialAccountValues[i].username !== element.username) {
				return true;
			}
		}
	}
	return false;
}

export default function ProfileUserSettings({
	user,
}: ProfileUserSettingsProps) {
	const validationSchema = editUserValidationSchema();
	const { fetchUser } = useAuth();
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [openSocialAccountModal, setOpenSocialAccountModal] = useState(false);

	function findSocialMedia(name: string) {
		const trackSocialAccount = user!.chef!.socialAccount!.find((social) => {
			return social.name === name;
		});
		if (trackSocialAccount) {
			return trackSocialAccount.username;
		}
		return '';
	}
	const formik = useFormik({
		initialValues: {
			name: user?.name || '',
			last_name: user?.last_name || '',
			bio: user.chef?.bio || '',
			socialAccount: [
				{
					name: SocialMedia.FACEBOOK,
					username: user.chef?.socialAccount
						? findSocialMedia(SocialMedia.FACEBOOK)
						: '',
				},
				{
					name: SocialMedia.TWITTER,
					username: user.chef?.socialAccount
						? findSocialMedia(SocialMedia.TWITTER)
						: '',
				},
				{
					name: SocialMedia.INSTAGRAM,
					username: user.chef?.socialAccount
						? findSocialMedia(SocialMedia.INSTAGRAM)
						: '',
				},
				{
					name: SocialMedia.YOUTUBE,
					username: user.chef?.socialAccount
						? findSocialMedia(SocialMedia.YOUTUBE)
						: '',
				},
			],
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setOpenConfirmModal(false);
				if (values.last_name !== user.last_name || values.name !== user.name) {
					await editUser({
						userId: user?.id,
						newUserInfo: {
							name: values.name,
							last_name: values.last_name,
						},
					});
				}

				if (user.chef) {
					let valuesToEdit = {};
					if (values.bio !== user.chef.bio) {
						valuesToEdit = { bio: values.bio };
					}
					if (
						hasSocialUsernameChange(
							values.socialAccount,
							user?.chef?.socialAccount && user?.chef?.socialAccount.length > 0
								? user?.chef?.socialAccount
								: [],
						)
					) {
						valuesToEdit = {
							...valuesToEdit,
							socialAccount: values.socialAccount,
						};
					}
					if (Object.keys(valuesToEdit).length > 0) {
						await editChef(user.chef!.id, valuesToEdit);
					}
				}
				notifySuccess('Profile updated successfully');
				fetchUser();
			} catch (error) {
				if (error instanceof Error) notifyError(error.message);
				else notifyError('An error occurred while updating the field');
			} finally {
				setSubmitting(false);
				() => setOpenConfirmModal(openConfirmModal);
			}
		},
	});

	const handleConfirm = () => {
		formik.handleSubmit();
	};

	return (
		<div className="flex flex-col items-center pt-10 lg:pt-0">
			<span className="text-center text-slate-400">Personal Details</span>
			<Title textTitle={`Profile`} />

			<div className="justify-center items-center">
				<form
					className="pt-5 flex flex-col gap-[42px] min-w-[300px] h-[440px] mt-2"
					onSubmit={(e) => {
						e.preventDefault();
						setOpenConfirmModal(true);
					}}
				>
					<div className="flex flex-col gap-4">
						<div className="relative">
							<input
								type="text"
								name="name"
								placeholder="Enter your first name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.name}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="name">NAME</label>
							</div>

							<img
								src={personIcon}
								alt="person-icon"
								className=" absolute top-[18px] right-5"
							/>

							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.name && formik.errors.name && (
									<div>{String(formik.errors.name)}</div>
								)}
							</div>
						</div>

						<div className="relative">
							<input
								type="text"
								name="last_name"
								placeholder="Enter your last name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.last_name}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="last_name">LAST NAME</label>
							</div>
							<img
								src={personIcon}
								alt="person-icon"
								className=" absolute top-[18px] right-5"
							/>
							<div className="text-alertRed pl-6 pb-4 text-[9px]">
								{formik.touched.last_name && formik.errors.last_name && (
									<div>{String(formik.errors.last_name)}</div>
								)}
							</div>
							{user.chef && (
								<div className="relative mb-4 ">
									<button
										className=" w-full flex justify-between items-center gap-2 py-2 px-6 rounded-2xl focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50 resize-none "
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
											setOpenSocialAccountModal(true);
										}}
										data-cy="social-accounts-modal-button"
									>
										<span>Social Accounts</span>
										<img
											src={arrowUpRightIcon}
											alt="arrow up right"
											className="absolute w-4 h-4 bg-grayText rounded-full right-5"
										/>
									</button>
								</div>
							)}
							{openSocialAccountModal && (
								<div className="absolute">
									<Modal
										open={openSocialAccountModal}
										onClose={() => setOpenSocialAccountModal(false)}
										styles={{
											modal: {
												borderRadius: '24px',
												width: 'auto',
												marginTop: '20px',
												marginBottom: '20px',
											},
										}}
									>
										<div className="flex justify-around items-center flex-col relative gap-3  mt-6">
											{formik.values.socialAccount.map((account, index) => (
												<div
													className="w-auto h-[45px] rounded-full px-6 pt-2 focus:outline-none focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
													key={index}
												>
													<div className="flex justify-between">
														<div>
															<div className=" text-[9px] text-loginTextGrey">
																<label
																	htmlFor={`socialAccount.${index}.username`}
																	className="uppercase"
																>
																	{account.name} username
																</label>
															</div>
															<input
																type="hidden"
																name={`socialAccount.${index}.name`}
																value={account.name}
															/>
															<input
																type="text"
																name={`socialAccount.${index}.username`}
																placeholder="Username"
																onChange={formik.handleChange}
																onBlur={formik.handleBlur}
																value={account.username}
																className=" text-[13px] font-semibold bg-transparent focus:bg-transparent focus:outline-none focus:ring-0"
																data-cy={`social-${account.name}`}
															/>
														</div>
														<img
															src={personIcon}
															alt="person-icon"
															className=" "
														/>
													</div>
													<div className="text-alertRed  text-[9px] w-full text-center">
														{formik.touched.socialAccount &&
															formik.errors.socialAccount &&
															formik.errors.socialAccount[index] !==
																undefined && (
																<div>
																	{String(
																		(
																			formik.errors.socialAccount[
																				index
																			] as Partial<SocialAccount>
																		)?.username,
																	)}
																</div>
															)}
													</div>
												</div>
											))}
										</div>
									</Modal>
								</div>
							)}

							{user.chef && (
								<div className="relative">
									<textarea
										name="bio"
										placeholder="Enter your biography"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.bio}
										className="w-full h-[128px] rounded-2xl px-6 py-6 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50 resize-none top-18"
									/>
									<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
										<label htmlFor="bio">BIOGRAPHY</label>
									</div>

									<div className="text-alertRed pl-6 text-[9px]">
										{formik.touched.bio && formik.errors.bio && (
											<div>{String(formik.errors.bio)}</div>
										)}
									</div>
								</div>
							)}
							<button
								data-cy="edit-user-submit"
								type="submit"
								className={`w-[156px] h-[52px] mt-3 rounded-full bg-brandRed text-white flex justify-center items-center mx-auto transition-all ${
									formik.isValid && !formik.isSubmitting
										? 'hover:text-brandRed hover:bg-white hover:ring-2 hover:ring-brandRed hover:cursor-pointer'
										: 'cursor-not-allowed opacity-50'
								}`}
								disabled={formik.isSubmitting || !formik.isValid}
							>
								{formik.isSubmitting ? (
									<Loader size={1.4} />
								) : (
									<span>Save changes</span>
								)}
							</button>

							<PopUp
								open={openConfirmModal}
								onClose={() => setOpenConfirmModal(false)}
							>
								<div className="flex flex-col gap-y-2 p-6">
									<p className="text-lg text-center text-slate-600 font-semibold my-4">
										Are you sure you want to save the changes?
									</p>
									<ActionButtons
										buttonOneText="Yes"
										buttonTwoText="No"
										onButtonClickOne={handleConfirm}
										onButtonClickTwo={() => setOpenConfirmModal(false)}
									/>
								</div>
							</PopUp>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
