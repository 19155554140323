import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter } from 'react-router-dom';

import { ProtectedRouteRole } from '../routeProtector';

import { ErrorBoundaryFallback } from '@/components/error-boundary/ErrorBoundaryFallback';
import ErrorBoundaryNoAuthWrapper from '@/components/error-boundary/ErrorBoundaryNoAuthWrapper';
import { NotFound } from '@/components/error-boundary/NotFound';
import { Role } from '@/context/interfaces';
import { ManageChefs } from '@/pages/admin/chef/ManageChefs';
import { UsersTable } from '@/pages/admin/user/UsersTable';
import { AllChefsPage } from '@/pages/chef/AllChefsPage';
import { ChefDashboard } from '@/pages/chef/ChefDashboard';
import { ChefPage } from '@/pages/chef/ChefPage';
import { ChefLayoutProfile } from '@/pages/chef/chef-profile/ChefProfileLayout';
import { ChefProfilePage } from '@/pages/chef/chef-profile/ChefProfilePage';
import { CreateRecipe } from '@/pages/chef/chef-profile/CreateRecipe';
import { EditRecipe } from '@/pages/chef/chef-profile/EditRecipe';
import { InstagramPendingPostsPage } from '@/pages/chef/chef-profile/InstagramPendingPostsPage';
import { EarlyUsersTable } from '@/pages/early-access-users-table/EarlyUsersTable';
import { ExploreRecipes } from '@/pages/explore-recipes/ExploreRecipes';
import { ForgotPassword } from '@/pages/forgot-password/ForgotPassword';
import { HomeLandingPage } from '@/pages/landing-page/HomeLandingPage';
import { LogIn } from '@/pages/log-in/LogIn';
import { PaymentCancelledPage } from '@/pages/payments/PaymentCancelledPage';
import { PaymentPage } from '@/pages/payments/PaymentPage';
import { PaymentSuccessPage } from '@/pages/payments/PaymentSuccessPage';
import { RecipeDetailPage } from '@/pages/recipe/RecipeDetailPage';
import { ResendVerification } from '@/pages/resend-verification/ResendVerification';
import { SearchResults } from '@/pages/search-results/SearchResults';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { SignUp } from '@/pages/sign-up/SignUp';
import { UserPage } from '@/pages/user/UserPage';
import { CollectionDetailPage } from '@/pages/user/collections/CollectionDetailPage';
import { AnalyticsWrapper } from '@/service/analytics/AnalyticsWrapper';

import Root from '@pages/Root';
import About from '@pages/about/About';
import Home from '@pages/home/Home';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ErrorBoundary fallbackRender={() => <ErrorBoundaryFallback />}>
				<Root />
			</ErrorBoundary>
		),
		children: [
			{
				path: '/home',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={
							<AnalyticsWrapper eventName="page_view_home">
								<Home />
							</AnalyticsWrapper>
						}
					/>
				),
			},
			{
				path: '/about',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<About />}
					/>
				),
			},
			{
				path: '/recipe/detail/:id',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<RecipeDetailPage />}
					/>
				),
			},
			{
				path: '/early-user',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN]}
						component={<EarlyUsersTable />}
					/>
				),
			},
			{
				path: '/admin/users',
				element: (
					<ProtectedRouteRole roles={[Role.ADMIN]} component={<UsersTable />} />
				),
			},
			{
				path: '/trending',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={
							<AnalyticsWrapper eventName="page_view_all_recipes">
								<ExploreRecipes />
							</AnalyticsWrapper>
						}
					/>
				),
			},
			{
				path: '/chef/profile',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.CHEF]}
						component={<ChefLayoutProfile />}
					/>
				),
				children: [
					{
						index: true,
						element: (
							<ProtectedRouteRole
								roles={[Role.ADMIN, Role.CHEF]}
								component={
									<AnalyticsWrapper eventName="page_view_private_profile_chef">
										<ChefProfilePage />
									</AnalyticsWrapper>
								}
							/>
						),
					},
					{
						path: '/chef/profile/instagram-posts',
						element: (
							<ProtectedRouteRole
								roles={[Role.ADMIN, Role.CHEF]}
								component={<InstagramPendingPostsPage />}
							/>
						),
					},
					{
						path: '/chef/profile/recipes/create',
						element: (
							<ProtectedRouteRole
								roles={[Role.ADMIN, Role.CHEF]}
								component={
									<AnalyticsWrapper eventName="page_view_recipe_create">
										<CreateRecipe />
									</AnalyticsWrapper>
								}
							/>
						),
					},
					{
						path: '/chef/profile/recipes/edit/:id',
						element: (
							<ProtectedRouteRole
								roles={[Role.ADMIN, Role.CHEF]}
								component={
									<AnalyticsWrapper eventName="page_view_recipe_edit">
										<EditRecipe />
									</AnalyticsWrapper>
								}
							/>
						),
					},
				],
			},
			{
				path: '/profile/collection/:collectionUuid',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER, Role.CHEF]}
						component={<CollectionDetailPage />}
					/>
				),
			},
			{
				path: '/chefs',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={
							<AnalyticsWrapper eventName="page_view_all_chefs">
								<AllChefsPage />
							</AnalyticsWrapper>
						}
					/>
				),
			},
			{
				path: '/chefs/:uuid',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<ChefPage />}
					/>
				),
			},
			{
				path: '/chefs-dashboard/:id',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.CHEF]}
						component={<ChefDashboard />}
					/>
				),
			},
			{
				path: '/user/me',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={
							<AnalyticsWrapper eventName="page_view_private_profile_user">
								<UserPage />
							</AnalyticsWrapper>
						}
					/>
				),
			},
			{
				path: '/admin/chefs',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN]}
						component={<ManageChefs />}
					/>
				),
			},
			{
				path: '/search/:param?',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={
							<AnalyticsWrapper eventName="page_view_search_results">
								<SearchResults />
							</AnalyticsWrapper>
						}
					/>
				),
			},
			{
				path: '/settings/me',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<SettingsPage />}
					/>
				),
			},
			{
				path: '/subscriptions/:message?',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<PaymentPage />}
					/>
				),
			},
			{
				path: '/subscriptions/payment-success',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<PaymentSuccessPage />}
					/>
				),
			},
			{
				path: '/subscriptions/payment-cancel',
				element: (
					<ProtectedRouteRole
						roles={[Role.ADMIN, Role.USER]}
						component={<PaymentCancelledPage />}
					/>
				),
			},
		],
	},
	{
		element: <ErrorBoundaryNoAuthWrapper noAuthRoutes={true} />,
		children: [
			{
				index: true,
				element: <HomeLandingPage />,
			},
			{
				path: '/signup',
				element: (
					<AnalyticsWrapper eventName="page_view_signup">
						<SignUp />
					</AnalyticsWrapper>
				),
			},
			{
				path: '/login',
				element: (
					<AnalyticsWrapper eventName="page_view_login">
						<LogIn />
					</AnalyticsWrapper>
				),
			},
			{ path: '/forgot-password', element: <ForgotPassword /> },
			{ path: '/resend-verification', element: <ResendVerification /> },
		],
	},
	{
		path: '*',
		element: <NotFound />,
	},
]);

export default router;
