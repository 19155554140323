import { useQuery } from '@tanstack/react-query';

import { setInstagramToken } from '@/service/api/instagram';

export const useSetInstagramToken = (
	chefId: number | undefined,
	code: string | null,
) => {
	const { isSuccess, isError, error, isLoading } = useQuery({
		queryKey: ['setInstagramToken', chefId],
		queryFn: () => setInstagramToken(chefId, code),
		retry: false,
		enabled: !!chefId && !!code,
	});

	return {
		isLoading,
		isSuccess,
		isError,
		error,
	};
};
