interface IPaginationParams {
	take: number;
	skip: number;
	totalEntities: number;
	setSkip: React.Dispatch<React.SetStateAction<number>>;
}

export const usePagination = ({
	take,
	skip,
	totalEntities,
	setSkip,
}: IPaginationParams) => {
	const totalPages = Math.ceil(totalEntities / take);
	const currentPage = skip / take;

	const onHandleNext = () => {
		if (currentPage === totalPages - 1) return;
		setSkip(skip + take);
	};

	const onHandlePrevious = () => {
		if (currentPage === 0) return;
		setSkip(skip - take);
	};

	return {
		totalPages,
		currentPage,
		onHandleNext,
		onHandlePrevious,
	};
};
