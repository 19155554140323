import * as Yup from 'yup';

export const createCollectionValidationSchema = () => {
	return Yup.object({
		title: Yup.string()
			.trim()
			.required('Title is required')
			.min(1, 'Title must be between 1 and 80 characters')
			.max(80, 'Title must be between 1 and 80 characters')
			.matches(/^(?!\s*$).+/, 'Title cannot be empty or just spaces'),
	});
};
