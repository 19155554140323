import { useEffect, useState } from 'react';

import {
	useDeleteUserUnfollowChef,
	usePutUserFollowChef,
} from './useUserFollowChef';

import { Chef, User } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { notifyError } from '@/helper/notifications';

export const useManageFollowingChef = (chef: Chef) => {
	const [isFollow, setIsFollow] = useState(false);
	const { user, setUser } = useAuth();
	const followChefMutation = usePutUserFollowChef();
	const deleteUnfollowChefMutation = useDeleteUserUnfollowChef();

	const { id } = chef;

	useEffect(() => {
		if (user) {
			const follow = !!user.following?.find((chef) => chef.id === id);
			setIsFollow(follow);
		}
	}, [user, id]);

	const addFollowChef = () => {
		followChefMutation.mutate(
			{ userId: user?.id || 0, chefId: id },
			{
				onSuccess: () => {
					setIsFollow(true);
					setUser((previousUser) => {
						if (!previousUser) return null;

						const updateUser = {
							...previousUser,
							following: [...previousUser.following, chef],
						};

						return updateUser;
					});
				},
				onError: (error: Error) => {
					setIsFollow(false);
					notifyError(`${error.message}`);
				},
			},
		);
	};

	const deleteUnfollowChef = () => {
		deleteUnfollowChefMutation.mutate(
			{ userId: user?.id || 0, chefId: id },
			{
				onSuccess: () => {
					setIsFollow(false);
					setUser((previousUser) => {
						if (!previousUser) return null;

						const updateUser: User = {
							...previousUser,
							following:
								previousUser?.following?.filter((chef) => chef.id !== id) || [],
						};

						return updateUser as User;
					});
				},
				onError: (error: Error) => {
					setIsFollow(true);
					notifyError(`${error.message}`);
				},
			},
		);
	};

	return {
		isFollow,
		addFollowChef,
		deleteUnfollowChef,
		isPendingFollowChef: followChefMutation.isPending,
		isPendingunFollowChef: deleteUnfollowChefMutation.isPending,
	};
};
