import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { getChefRecipes } from '@/service/api/recipe';

export const useGetChefRecipes = (
	chefUuid: string,
	options: IPagination,
	isChefProfile?: boolean,
) => {
	const {
		data: chefRecipes,
		isLoading: isLoadingChefRecipes,
		isError: isErrorChefRecipes,
		error: errorChefRecipes,
		refetch: refetchChefRecipes,
	} = useQuery({
		queryKey: ['chef-recipes', chefUuid, options.take, options.skip],
		queryFn: () => getChefRecipes(chefUuid, options),
		retry: false,
		enabled: !!isChefProfile,
	});

	return {
		chefRecipes,
		refetchChefRecipes,
		totalEntities: chefRecipes?.total as number,
		isLoadingChefRecipes,
		isErrorChefRecipes,
		errorChefRecipes,
	};
};
