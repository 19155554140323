import { MasonryColumn } from '@/components/common/recipe/masonry-recipe/MasonryColumn';
import useWindowWidth from '@/hooks/useWindowWidth';
import { IRecipe } from '@/interface/recipe.interfaces';

interface ChefCardsReSizeProps {
	recipes: IRecipe[];
	columnReverse?: boolean;
	itemsCenter?: boolean;
	isExploreRecipesPage?: boolean;
	userHasSubscription?: boolean;
	isRefetchingMostLikedRecipes?: boolean;
}

export const TrendingGridComponent = ({
	recipes,
	columnReverse,
	itemsCenter,
	userHasSubscription,
	isRefetchingMostLikedRecipes,
}: ChefCardsReSizeProps) => {
	const windowWidth = useWindowWidth();
	const sliceArrayIntoFragments = (recipes: IRecipe[]) => {
		const fragmentSize = 3;
		const fragments = [];

		for (let i = 0; i < recipes.length; i += fragmentSize) {
			fragments.push(recipes.slice(i, i + fragmentSize));
		}

		return {
			first_fragment: fragments[0],
			second_fragment: fragments[1],
			third_fragment: fragments[2],
			fourth_fragment: fragments[3],
			fifth_fragment: fragments[4],
			sixth_fragment: fragments[5],
		};
	};

	const recipes_fragments = sliceArrayIntoFragments(recipes);
	const fourColumnsSkeletons = windowWidth > 920 && windowWidth < 1100;
	const fiveColumnsSkeletons =
		windowWidth > 1100 &&
		windowWidth < 1300 &&
		recipes_fragments.sixth_fragment;

	return (
		<div
			data-cy="trending-masonry-component"
			className={`grid  w-full px-6 min-[375px]:grid-cols-2  sm:grid-cols-3 min-[920px]:grid-cols-4 min-[1100px]:grid-cols-5 min-[1300px]:grid-cols-6 min-[1540px]:grid-cols-[repeat(6,235px)] min-[1540px]:justify-between  ${
				itemsCenter && 'items-center'
			} ${windowWidth < 1540 && 'gap-x-3'}
			`}
		>
			<MasonryColumn
				recipes={
					isRefetchingMostLikedRecipes
						? []
						: (recipes_fragments.first_fragment as IRecipe[])
				}
				isReverse={!columnReverse}
				userHasSubscription={userHasSubscription}
			/>
			<MasonryColumn
				recipes={
					isRefetchingMostLikedRecipes
						? []
						: (recipes_fragments.second_fragment as IRecipe[])
				}
				isReverse={columnReverse}
				userHasSubscription={userHasSubscription}
			/>
			<MasonryColumn
				recipes={
					isRefetchingMostLikedRecipes
						? []
						: (recipes_fragments.third_fragment as IRecipe[])
				}
				isReverse={!columnReverse}
				userHasSubscription={userHasSubscription}
			/>
			<MasonryColumn
				recipes={
					isRefetchingMostLikedRecipes
						? []
						: (recipes_fragments.fourth_fragment as IRecipe[])
				}
				isReverse={columnReverse}
				userHasSubscription={userHasSubscription}
			/>
			<MasonryColumn
				recipes={
					isRefetchingMostLikedRecipes
						? []
						: (recipes_fragments.fifth_fragment as IRecipe[])
				}
				isReverse={!columnReverse}
				userHasSubscription={userHasSubscription}
			/>
			{!(
				windowWidth > 1100 &&
				windowWidth < 1300 &&
				!recipes_fragments.sixth_fragment
			) && (
				<MasonryColumn
					recipes={
						isRefetchingMostLikedRecipes
							? []
							: (recipes_fragments.sixth_fragment as IRecipe[])
					}
					isReverse={columnReverse}
					userHasSubscription={userHasSubscription}
				/>
			)}

			{fourColumnsSkeletons && (
				<>
					<MasonryColumn recipes={undefined} isReverse={!columnReverse} />
					<MasonryColumn recipes={undefined} isReverse={columnReverse} />
				</>
			)}
			{fiveColumnsSkeletons && (
				<>
					<MasonryColumn recipes={undefined} isReverse={!columnReverse} />
					<MasonryColumn recipes={undefined} isReverse={columnReverse} />
					<MasonryColumn recipes={undefined} isReverse={!columnReverse} />
					<MasonryColumn recipes={undefined} isReverse={columnReverse} />
				</>
			)}
		</div>
	);
};
