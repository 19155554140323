import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { updateRecipe } from '@/service/api/recipe';

export const useUpdateRecipe = () => {
	const updateRecipeMutation = useMutation({
		mutationFn: updateRecipe,
		onSuccess: () => {
			notifySuccess('Recipe updated successfully');
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { updateRecipeMutation };
};
