import { useQuery } from '@tanstack/react-query';

import { getAllEarlyAccessUser } from '@/service/api/earlyAccessUser';

export const useGetAllEarlyUsers = (take: number, skip: number) => {
	const {
		data: allEarlyUsers,
		isLoading: isLoadingAllEarlyUsers,
		isError: isErrorAllEarlyUsers,
		error: errorAllEarlyUsers,
	} = useQuery({
		queryKey: ['all-early-users', take, skip],
		queryFn: () => getAllEarlyAccessUser(take, skip),
		retry: false,
		staleTime: 10000,
	});

	return {
		allEarlyUsers,
		isLoadingAllEarlyUsers,
		isErrorAllEarlyUsers,
		errorAllEarlyUsers,
	};
};
