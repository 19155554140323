import { AxiosError } from 'axios';

export function handleAxiosError(
	error: unknown,
	defaultMessage: string,
): string {
	let errorMessage = defaultMessage;

	if (error instanceof AxiosError) {
		const errorMessageFromBackend = error.response?.data.message;

		if (error.message === 'Network Error') {
			errorMessage =
				'Connection refused. Please check your network connection.';
		}
		if (error.response?.status === 500) {
			errorMessage = defaultMessage;
		} else if (
			error.response?.status === 400 &&
			Array.isArray(errorMessageFromBackend)
		) {
			errorMessage = errorMessageFromBackend.join(', ');
		} else {
			errorMessage = errorMessageFromBackend;
		}
	} else if (error instanceof Error) {
		if (error.message === 'Network Error') {
			errorMessage =
				'Connection refused. Please check your network connection.';
		}
		console.log(error);
	}

	return errorMessage;
}
