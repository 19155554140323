import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { deleteCollection } from '@/service/api/collection';

export const useDeleteCollection = () => {
	const deleteCollectionMutation = useMutation({
		mutationFn: (collectionId: number) => deleteCollection(collectionId),
		onSuccess: () => {
			notifySuccess('Collection deleted successfully');
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { deleteCollectionMutation };
};
