import userBlackIcon from '@/assets/svg/user-black.svg';

interface RecipeCardProps {
	height: 'normal' | 'higher' | 'high';
	message?: string;
}
export const RecipeDinamicCardSkeletond = ({
	height,
	message,
}: RecipeCardProps) => {
	let imageStyle = '';
	switch (height) {
		case 'normal':
			imageStyle = 'h-[240px] md:h-[300px]';
			break;
		case 'high':
			imageStyle = 'h-[300px] md:h-[360px]';
			break;
		case 'higher':
			imageStyle = 'h-[340px] md:h-[420px]';
			break;
		default:
			imageStyle = 'h-[240px] md:h-[300px]';
			break;
	}
	return (
		<div
			data-cy="recipe-dinamic-card-skeleton"
			className="flex flex-col w-[250px] min-[375px]:w-full 2xl:w-[235px]"
		>
			<div
				className={`flex justify-center items-center pb-10 ${imageStyle} rounded-3xl bg-slate-200`}
			>
				{message && (
					<p className="text-xl font-semibold text-slate-400">{message}</p>
				)}
			</div>
			<div className="px-2.5 mt-2 mb-4 text-slate-300">
				<p className="text-xl font-bold">**********</p>
				<div className="flex items-center text-sm gap-2 ">
					<img
						src={userBlackIcon}
						alt="avatar"
						className="object-cover w-5 h-5 rounded-full"
					/>
					<p className="">********</p>
				</div>
			</div>
		</div>
	);
};
