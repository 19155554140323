import { useState } from 'react';
import { Link } from 'react-router-dom';

import arrowLeftIcon from '@/assets/svg/arrowleft-icon.svg';
import arrowRightIcon from '@/assets/svg/arrowright-icon.svg';
import instagramColorfulIcon from '@/assets/svg/colorful/instagram.svg';
import { InstagramPendingPost } from '@/components/chef/chef-profile/InstagramPendingPost';
import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { Title } from '@/components/common/Title/Title';
import { RecipeDinamicCard } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCard';
import { Chef } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { useFetchInstagramPosts } from '@/hooks/chef/useFetchInstagramPosts';
import { useGptInstagramPostCheck } from '@/hooks/chef/useGptInstagramPostCheck';
import { useCreateRecipe } from '@/hooks/recipes/useCreateRecipe';
import {
	InstagramPageDirection,
	InstagramPost,
} from '@/interface/instagram.interfaces';
import { IRecipe } from '@/interface/recipe.interfaces';
import { getInstagramCode } from '@/service/api/instagram';

export const InstagramPendingPostsPage = () => {
	const { user } = useAuth();
	const {
		posts,
		isLoadingInstagramPosts,
		isErrorInstagramPosts,
		refetchInstagramPosts,
		setDirection,
		direction,
	} = useFetchInstagramPosts(user?.chef?.id as number);
	const { gtpInstagramPostCheckMutation } = useGptInstagramPostCheck();
	const { createRecipeMutation } = useCreateRecipe();
	const [open, setOpen] = useState(false);
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);

	const chefId = user?.chef?.id as number;

	const onNavigateToInstagramAuthorizationWindow = async (): Promise<void> => {
		const code = await getInstagramCode();

		window.location.href = code;
	};

	const onGtpInstagramPostCheckMutation = (prompt: InstagramPost): void => {
		onOpenModal();
		gtpInstagramPostCheckMutation.mutate(prompt);
	};

	const onCreateRecipe = (recipData: IRecipe) => {
		createRecipeMutation.mutate(
			{
				...recipData,
				chef: chefId,
			},
			{
				onSuccess: () => {
					onCloseModal();
				},
			},
		);
	};

	const goToNextPage = (): void => {
		setDirection(InstagramPageDirection.NEXT);
		if (direction === InstagramPageDirection.NEXT) {
			refetchInstagramPosts();
		}
	};

	const goToPreviousPage = (): void => {
		setDirection(InstagramPageDirection.PREVIOUS);
		if (direction === InstagramPageDirection.PREVIOUS) {
			refetchInstagramPosts();
		}
	};

	if (isLoadingInstagramPosts) {
		return (
			<div className="flex justify-center items-center h-[30vh]">
				<Loader size={4} />
			</div>
		);
	}

	if (posts?.data?.length === 0 || isErrorInstagramPosts) {
		return (
			<div className="flex flex-col justify-center items-center h-[30vh]">
				<span>No pending posts yet</span>
				<button
					data-cy="connect-with-instagram-button"
					onClick={onNavigateToInstagramAuthorizationWindow}
					className="flex items-center gap-x-2 py-2 px-4 border-2 text-pink-500 border-pink-500 rounded-3xl bg-pink-200"
				>
					<span className=" font-bold">Connect with Instagram</span>
					<img
						src={instagramColorfulIcon}
						alt="instagram-icon"
						className="w-6"
					/>
				</button>
			</div>
		);
	}

	return (
		<div>
			<div className="text-center sm:text-left">
				<Title
					textTitle="Your recipes on "
					colorText="pink-500"
					titleWithColor="Instagram"
				/>
			</div>
			<div className="grid min-[880px]:grid-cols-2 xl:grid-cols-3 place-items-center gap-y-10 mt-10">
				{posts?.data?.map((post) => (
					<InstagramPendingPost
						data-cy="instagram-pending-post"
						key={post.id}
						post={post}
						onGtpInstagramPostCheckMutation={onGtpInstagramPostCheckMutation}
					/>
				))}
			</div>

			<section className="flex items-center justify-center gap-[6px] mx-auto mt-10 mb-10 h-[52px]">
				{posts?.previous && (
					<button
						onClick={goToPreviousPage}
						className={`w-[52px] h-[52px] bg-buttonGrey rounded-full flex items-center justify-center hover:bg-gray-100`}
					>
						<img src={arrowLeftIcon} alt="arrow-icon" className="w-4 h-4" />
					</button>
				)}
				{posts?.next && (
					<button
						onClick={goToNextPage}
						className={`w-[52px] h-[52px] bg-brandOrange rounded-full flex items-center justify-center hover:bg-[#E1B864]`}
					>
						<img src={arrowRightIcon} alt="arrow-icon" className="w-4 h-4" />
					</button>
				)}
			</section>

			<PopUp
				open={open}
				onClose={onCloseModal}
				topCss={gtpInstagramPostCheckMutation.isPending ? '25vh' : '5vh'}
			>
				{gtpInstagramPostCheckMutation.isPending ? (
					<div className="flex flex-col gap-y-2 p-10 items-center text-slate-600 font-semibold">
						<Loader size={1.6} />
						<p>Kitched</p>
						<p>is checking your post</p>
					</div>
				) : gtpInstagramPostCheckMutation.isError ? (
					<div className="flex flex-col  gap-y-2 p-10 text-center text-alertRed font-semibold">
						<p>
							An error occurred while extracting the recipe information. Please
							try again or create the recipe manually.
						</p>
						<div className="flex justify-center mt-5">
							<Link to={'/chef/profile/recipes/create'}>
								<button className="order-last grow min-[412px]:order-none min-[412px]:grow-0 flex items-center justify-center h-12 gap-x-2 py-4 px-4 bg-brandOrange rounded-3xl text-white transition-colors hover:bg-[#E1B864]">
									<span>Create recipe manually</span>
								</button>
							</Link>
						</div>
					</div>
				) : (
					gtpInstagramPostCheckMutation.isSuccess && (
						<div className=" min-[375px]:w-[235px] flex flex-col items-center ">
							<RecipeDinamicCard
								recipe={{
									...gtpInstagramPostCheckMutation.data,
									chef: {
										user: {
											id: user?.id,
											name: user?.name,
											last_name: user?.last_name,
										},
									} as unknown as Chef,
									isFree: true,
								}}
								mediaUrl={
									gtpInstagramPostCheckMutation.data.instagramInformation
										?.media_url
								}
								thumbnailUrl={
									gtpInstagramPostCheckMutation.data.instagramInformation
										?.thumbnail_url
								}
								isVideo={
									gtpInstagramPostCheckMutation.data.instagramInformation
										?.media_type === 'VIDEO'
								}
								height="normal"
								disableHoverEffect={true}
								disableNavigation={true}
							/>
							<div className="w-full flex flex-col gap-y-2 mt-4">
								<button
									disabled={createRecipeMutation.isPending}
									onClick={() =>
										onCreateRecipe(gtpInstagramPostCheckMutation.data)
									}
									className={`py-2 px-4 text-white rounded-xl bg-brandOrange transition-colors hover:bg-[#E1B864]
									${createRecipeMutation.isPending && 'cursor-not-allowed'}`}
								>
									Create Recipe
								</button>
								<button
									onClick={onCloseModal}
									className="py-2 px-4 text-white rounded-xl bg-alertRed/70 transition-colors hover:bg-alertRed"
								>
									Cancel
								</button>
							</div>
						</div>
					)
				)}
			</PopUp>
		</div>
	);
};
