import { useInstantSearch } from 'react-instantsearch';

import { Loader } from '../common/Loader';

export const AlgoliaStatusLoader = () => {
	const { status } = useInstantSearch();

	if (status === 'loading' || status === 'stalled') {
		return <Loader size={1} />;
	} else {
		return <></>;
	}
};
