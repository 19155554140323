export function RecipeIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.001 5H20.001"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.001 8H17.001"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.001 11.5C21.001 16.75 16.751 21 11.501 21C6.25098 21 2.00098 16.75 2.00098 11.5C2.00098 6.25 6.25098 2 11.501 2"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.001 22L20.001 20"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export function CreatorsIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.15298 11.7105L12.9666 11.7039M8.49988 1.3335C9.92776 1.3335 11.1204 2.3335 11.4107 3.66757C12.1732 3.46227 12.9863 3.56674 13.6713 3.958C14.3563 4.34926 14.8571 4.99525 15.0634 5.75387C15.2697 6.51248 15.1648 7.32158 14.7715 8.00318C14.3783 8.68477 13.7291 9.18301 12.9666 9.38831V14.6668H4.03312V9.38831C3.65561 9.28656 3.30193 9.11182 2.99229 8.87407C2.68264 8.63632 2.42309 8.34021 2.22846 8.00265C2.03382 7.66509 1.90792 7.2927 1.85793 6.90672C1.80793 6.52075 1.83484 6.12876 1.9371 5.75313C2.03936 5.3775 2.21497 5.02559 2.45392 4.71749C2.69287 4.40939 2.99046 4.15114 3.32971 3.95748C3.66897 3.76381 4.04323 3.63854 4.43115 3.58879C4.81906 3.53905 5.21302 3.56582 5.59053 3.66757C5.73484 3.00651 6.10214 2.41448 6.6314 1.98987C7.16066 1.56526 7.82001 1.33364 8.49988 1.3335Z"
				stroke={'#000000'}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export function SavedRecipeIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.499 10.6484H9.49902"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 8.21094V13.2109"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.8213 2H7.18129C5.05129 2 3.32129 3.74 3.32129 5.86V19.95C3.32129 21.75 4.61129 22.51 6.19129 21.64L11.0713 18.93C11.5913 18.64 12.4313 18.64 12.9413 18.93L17.8213 21.64C19.4013 22.52 20.6913 21.76 20.6913 19.95V5.86C20.6813 3.74 18.9513 2 16.8213 2Z"
				stroke={'#000000'}
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
