import userNoImage from '@/assets/image/user-no-image.jpg';
import { CdnImage } from '@/components/images/CdnImage';
import { Chef } from '@/context/interfaces';

interface ChefSmallCardInfoProps {
	chef: Chef;
}

export const ChefSmallCardInfo = ({ chef }: ChefSmallCardInfoProps) => {
	const profileImage = chef.user?.profile_image?.src
		? chef.user?.profile_image?.src
		: userNoImage;

	const thereIsNoProfileImage = profileImage === userNoImage;

	return (
		<div className="flex flex-col w-full md:w-[235px] md:px-4">
			<CdnImage
				src={profileImage}
				alt="chef-avatar"
				className={`w-full h-[200px] min-[500px]:h-[250px] md:h-[300px] object-cover rounded-3xl cursor-pointer ${
					thereIsNoProfileImage ? 'ring-1 ring-gray-300' : ''
				}`}
			/>
			<div className="mt-1.5 px-[5px] md:px-[2px]">
				<p className="truncate text-[15px] tracking-[0.02em] font-helveticaNeue text-gray-600">{`${chef?.user?.name} ${chef?.user?.last_name}`}</p>
			</div>
		</div>
	);
};
