import { Form, Formik } from 'formik';

import { FormField } from '@/pages/forgot-password/FormField';
import { emailValidationSchema } from '@/pages/forgot-password/validationSchemas/EmailValidation.schema';

const initialValues = {
	email: '',
};

const emailFormField = {
	label: 'Email',
	id: 'email',
	name: 'email',
	type: 'email',
	placeholder: 'Enter your email',
};

export const EmailForm = ({
	onSubmit,
}: {
	onSubmit: (values: typeof initialValues) => any;
}) => {
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={emailValidationSchema}
			onSubmit={(values) => onSubmit(values)}
		>
			<Form>
				<FormField {...emailFormField} />
				<button
					data-cy="send-code-form-button"
					type="submit"
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
				>
					Send Code
				</button>
			</Form>
		</Formik>
	);
};
