import { ChefSort } from '@/interface/filter.interface';

export const chefSortItems = [
	{
		id: 0,
		name: 'Most Popular',
		sort: ChefSort.MOST_POPULAR,
	},
	{
		id: 1,
		name: 'Most Recipes',
		sort: ChefSort.MOST_RECIPES,
	},
	{
		id: 2,
		name: 'Most Followed',
		sort: ChefSort.MOST_FOLLOWED,
	},
];
