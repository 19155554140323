import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { Slider } from '../common/slider/Slider';
import { HomeCollectionModal } from './components/HomeCollectionModal';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import { Loader } from '@/components/common/Loader';
import { Title } from '@/components/common/Title/Title';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { useAuth } from '@/context/useAuth';
import { useGetAllCollectionsByUser } from '@/hooks/collection/useGetAllCollectionsByUser';
import { usePagination } from '@/hooks/usePagination';
import { ICollection } from '@/interface/collection.interfaces';
import { CollectionCard } from '@/pages/user/collections/CollectionCard';

export const CollectionHomeSection = () => {
	const { user } = useAuth();
	const ADMIN_UUID = Number(import.meta.env.VITE_ADMIN_UUID);
	const [skip, setSkip] = useState(0);
	const take = 10;
	const {
		collections,
		totalCollections,
		isLoadingCollections,
		isErrorCollections,
		refetchCollections,
	} = useGetAllCollectionsByUser(ADMIN_UUID, { take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities: totalCollections,
			setSkip,
		});

	const [open, setOpen] = useState(false);
	const [currentCollection, setCurrentCollection] = useState<ICollection>({
		title: 'No Collection',
	} as ICollection);
	const onOpenModal = (collection: ICollection) => {
		setCurrentCollection(collection);
		setOpen(true);
	};
	const onCloseModal = () => {
		setOpen(false);
	};

	useEffect(() => {
		refetchCollections();
	}, [skip]);

	if (
		(isLoadingCollections === false && totalCollections === 0) ||
		isErrorCollections
	)
		return null;

	return (
		<div data-cy="home-collections-section" className="w-full mt-5">
			<div className="flex flex-col items-start gap-3 px-5 sm:flex-row sm:items-center">
				<Title
					textTitle="Kitched"
					colorText="brandRed"
					titleWithColor="Collections"
				/>
				{!!user?.chef && (
					<Link to={`/chef/profile`} className="self-end sm:self-start">
						<button className="relative flex py-3 px-4 bg-brandOrange hover:bg-[#E1B864] rounded-full text-white font-semibold transition-all :text-brandOrange :bg-white :ring-2 :ring-brandOrange :cursor-pointer font-helveticaNeue tracking-[0.02em]">
							<span>Create Collection</span>
							<img src={arrowUpRight} alt="arrow-up-right-icon" />
						</button>
					</Link>
				)}
			</div>

			{isLoadingCollections ? (
				<div className="h-[366px] flex justify-center items-center">
					<Loader size={3} />
				</div>
			) : (
				<div className="flex flex-col justify-center items-center  lg:items-center lg:gap-x-4">
					<Slider cardsType={{ type: 'COLLECTIONS' }}>
						{collections?.map((collection) => (
							<SwiperSlide
								key={collection.id}
								onClick={() => onOpenModal(collection)}
							>
								<CollectionCard collection={collection} />
							</SwiperSlide>
						))}
					</Slider>

					<div>
						<NavigationButtons
							currentPage={currentPage}
							totalPages={totalPages}
							handleNextClick={onHandleNext}
							handlePrevClick={onHandlePrevious}
						/>
					</div>
				</div>
			)}
			<HomeCollectionModal
				open={open}
				onClose={onCloseModal}
				collection={currentCollection}
			/>
		</div>
	);
};
