import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { getCollectionsByUser } from '@/service/api/collection';

export const useGetCollectionsByUser = (options: IPagination) => {
	const {
		data: collectionsData,
		isLoading: isLoadingCollections,
		isError: isErrorCollections,
		error: errorCollections,
		refetch: refetchCollections,
	} = useQuery({
		queryKey: ['collections', options.skip, options.take],
		queryFn: () => getCollectionsByUser(options),
	});

	return {
		collections: collectionsData?.data || [],
		totalCollections: collectionsData?.total || 0,
		refetchCollections,
		isLoadingCollections,
		isErrorCollections,
		errorCollections,
	};
};
