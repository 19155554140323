import { useEffect, useState } from 'react';

import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { usePagination } from '@/hooks/usePagination';
import { useGetUserSavedRecipes } from '@/hooks/user/useGetUserSavedRecipes';
import { ICollection } from '@/interface/collection.interfaces';

interface SelectRecipeCollectionModalProps {
	open: boolean;
	onClose: () => void;
	onAddRecipe: (recipeId: number) => void;
	onRemoveRecipe: (recipeId: number) => void;
	collection?: ICollection;
}

export const SelectRecipeCollectionModal = ({
	open,
	onClose,
	onAddRecipe,
	onRemoveRecipe,
	collection,
}: SelectRecipeCollectionModalProps) => {
	const [skip, setSkip] = useState<number>(0);
	const take = 10;

	const {
		userSavedRecipes,
		refetchUserSavedRecipes,
		isLoadingUserSavedRecipes,
		isErrorUserSavedRecipes,
		totalUserSavedEntities,
	} = useGetUserSavedRecipes({ take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities: totalUserSavedEntities || 0,
			setSkip,
		});

	useEffect(() => {
		if (open) {
			refetchUserSavedRecipes();
		}
	}, [open, refetchUserSavedRecipes, skip]);

	if (isLoadingUserSavedRecipes) {
		return (
			<PopUp open={open} onClose={onClose} topCss="15vh">
				<div className="flex justify-center items-center w-72 h-72">
					<Loader size={2} />
				</div>
			</PopUp>
		);
	}

	if (isErrorUserSavedRecipes) {
		return (
			<PopUp open={open} onClose={onClose} topCss="10vh">
				<div className="p-4">
					<p className="text-alertRed">
						Error loading recipes. Please try again.
					</p>
				</div>
			</PopUp>
		);
	}

	return (
		<PopUp open={open} onClose={onClose}>
			<div className="max-h-[85vh] min-w-[89vw] flex flex-col gap-y-4 p-2 min-[400px]:overflow-y-auto min-[400px]:modal-content font-helveticaNeue">
				<h1 className="mt-2 text-2xl tracking-tight font-bold text-center sm:text-left sm:ml-8">
					Choose A Recipe
				</h1>

				<section className="grid justify-center gap-y-10 gap-x-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
					{userSavedRecipes?.data.map((recipe) => (
						<div key={recipe.id} className="w-full max-w-[235px]">
							<RecipesCard
								recipe={recipe}
								disableNavigation={true}
								collection={collection}
								onAddRecipe={onAddRecipe}
								onRemoveRecipe={onRemoveRecipe}
								userHasSubscription={true}
							/>
						</div>
					))}

					{userSavedRecipes?.data.length === 0 && (
						<p className="text-center text-gray-500 mb-14">
							No saved recipes found
						</p>
					)}
				</section>

				<NavigationButtons
					currentPage={currentPage}
					totalPages={totalPages}
					handleNextClick={onHandleNext}
					handlePrevClick={onHandlePrevious}
				/>
			</div>
		</PopUp>
	);
};
