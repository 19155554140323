import { RecipeSort } from '@/interface/filter.interface';

export const trendingRecipesFiltersMenu = [
	{
		id: 0,
		name: 'Latest',
		sort: RecipeSort.LATEST,
	},
	{
		id: 1,
		name: 'Free',
		sort: RecipeSort.IS_FREE,
	},
	{
		id: 2,
		name: 'Last Day',
		sort: RecipeSort.LAST_DAY,
	},
	{
		id: 3,
		name: 'Last Week',
		sort: RecipeSort.LAST_WEEK,
	},
	{
		id: 4,
		name: 'Last Month',
		sort: RecipeSort.LAST_MONTH,
	},
	{
		id: 5,
		name: 'Oldest',
		sort: RecipeSort.OLDEST,
	},
];

export const chefRecipesFiltersMenu = [
	{
		id: 0,
		name: 'Latest',
		sort: RecipeSort.LATEST,
	},
	{
		id: 1,
		name: 'Oldest',
		sort: RecipeSort.OLDEST,
	},
	{
		id: 2,
		name: 'Most Liked',
		sort: RecipeSort.MOST_LIKED,
	},
];
