export interface InstagramPostsAndPaging {
	data: InstagramPost[];
	next: boolean;
	previous: boolean;
}

export interface InstagramPost {
	id: string;
	caption: string;
	media_type: string;
	media_url: string;
	permalink: string;
	thumbnail_url?: string;
}

export enum InstagramPageDirection {
	NEXT = 'next',
	PREVIOUS = 'previous',
}
