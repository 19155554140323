import { useState } from 'react';

import { PopUp } from '../PopUp';

import { IRecipeStep } from '@/interface/recipe.interfaces';

// const mockRecipeSteps = [
// 	'Lorem ipsum dolor sit',
// 	'Lorem ipsum dolor sit amet',
// 	'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
// ];

export const MethodComponent = ({
	instructions,
}: {
	instructions: IRecipeStep[];
}) => {
	const [openMethodsModal, setOpenMethodsModal] = useState(false);
	const instructionsOrdered = instructions.sort((a, b) => a.number - b.number);
	const displayedInstructions = instructionsOrdered.slice(0, 5);

	const handleInstructionsModal = () => setOpenMethodsModal(!openMethodsModal);

	return (
		<div className="w-full">
			<h4 className="text-[26px] leading-[28.6px] tracking-[0.02em] font-semibold mb-2">
				Method:
			</h4>
			<div>
				{/* shows all when md size */}
				<div className="hidden md:flex md:flex-col mt-4 gap-3">
					{instructionsOrdered.map((step) => {
						return (
							<div key={step.id} className="flex gap-3">
								<div className="flex flex-col items-center">
									<span className="w-10 h-10 text-xl flex justify-center items-center rounded-full bg-yellow-400 text-white font-bold">
										{step.number}
									</span>
									{/* <div className="border-l-2 border-yellow-400 grow"></div> */}
								</div>
								<p className="my-auto">{step.description}</p>
							</div>
						);
					})}
				</div>
				{/* shows less when small size */}
				<div className="flex flex-col gap-3 md:hidden mt-4">
					{displayedInstructions.map((step) => (
						<div key={step.id} className="flex gap-3">
							<div className="flex flex-col items-center">
								<span className="w-10 h-10 text-xl flex justify-center items-center rounded-full bg-yellow-400 text-white font-bold">
									{step.number}
								</span>
								{/* <div className="border-l-2 border-yellow-400 grow"></div> */}
							</div>
							<p className="my-auto">{step.description}</p>
						</div>
					))}
				</div>
				{/* Show all methods button */}
				{instructions && instructions.length > 5 && (
					<div className="flex items-center justify-center mt-4 md:hidden">
						<button
							onClick={handleInstructionsModal}
							className="p-2 bg-brandGreen rounded text-white"
						>
							Show All Methods
						</button>
					</div>
				)}
			</div>

			{openMethodsModal && (
				<PopUp open={openMethodsModal} onClose={handleInstructionsModal}>
					<div className="flex flex-col gap-y-2 p-6">
						{instructions?.map((step) => (
							<div key={step.id} className="flex gap-x-3 mb-3">
								<div className="flex flex-col items-center">
									<span className="w-10 h-10 text-xl flex justify-center items-center rounded-full bg-yellow-400 text-white font-bold">
										{step.number}
									</span>
									{/* <div className="border-l-2 border-yellow-400 grow"></div> */}
								</div>
								<p className="my-auto">{step.description}</p>
							</div>
						))}
						<div className="flex items-center justify-center mt-4">
							<button
								onClick={handleInstructionsModal}
								className="p-2 bg-brandRed rounded text-white"
							>
								Show Less
							</button>
						</div>
					</div>
				</PopUp>
			)}
		</div>
	);
};
