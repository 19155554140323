import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { fetchChefsNames } from '@/service/api/chef';

export const useGetAllChefsNames = (options: IPagination) => {
	const {
		data: chefs,
		isLoading: isLoadingChefs,
		isFetching: isFetchingChefs,
		refetch: refetchChefs,
		isError: isErrorLoadingChefs,
		error: errorChefs,
	} = useQuery({
		queryKey: ['chefs-user-name'],
		queryFn: () => fetchChefsNames(options),
		staleTime: Infinity,
	});

	return {
		chefs,
		refetchChefs,
		totalEntities: chefs?.total as number,
		isLoadingChefs,
		isFetchingChefs,
		isErrorLoadingChefs,
		errorChefs,
	};
};
