import axios, { InternalAxiosRequestConfig } from 'axios';

import { refreshUserToken } from '@/service/cognito/cognito.services';

const Api = axios.create({
	baseURL: import.meta.env.VITE_API_BASE_URL,
});

Api.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		const { session } = await refreshUserToken();
		if (!session) return config;

		const token = session.getAccessToken().getJwtToken();

		config.headers.Authorization = `Bearer ${token}`;

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export default Api;
