import { useState } from 'react';
import {
	EmailIcon,
	EmailShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';

import shareBlackIcon from '@/assets/svg/share-black-icon.svg';
import { notifyError } from '@/helper/notifications';

export const ShareSocialMediaComponent = () => {
	const [copyMessageVisible, setCopyMessageVisible] = useState(false);

	let shareUrl = window.location.href;
	const title = 'Check out this amazing recipe!';

	if (!shareUrl.includes('www.')) {
		const url = new URL(shareUrl);
		shareUrl = `${url.protocol}//www.${url.host}${url.pathname}${url.search}${url.hash}`;
	}

	const handleCopyUrl = () => {
		navigator.clipboard
			.writeText(shareUrl)
			.then(() => {
				setCopyMessageVisible(true);
				setTimeout(() => setCopyMessageVisible(false), 3000);
			})
			.catch((error: unknown) => {
				console.error('Failed to copy: ', error);
				if (error instanceof Error) notifyError(error.message);
				else notifyError('Failed to copy');
			});
	};

	return (
		<div data-cy="detail-page-share-social-media" className="p-4">
			<div className="flex items-center font-semibold text-3xl mb-6">
				<span className="mr-2">Send Recipe</span>
			</div>
			<div className="grid grid-cols-2 md:grid-cols-3 lg:flex lg:gap-4 mb-4">
				<div
					className="flex items-center justify-center bg-slate-100 w-16 h-16 rounded-full transform hover:scale-110 cursor-pointer"
					onClick={handleCopyUrl}
				>
					<img src={shareBlackIcon} alt="share-icon" className="w-8 h-8" />
				</div>
				<div className="flex items-center justify-center bg-slate-100 w-16 h-16 rounded-full transform hover:scale-110 cursor-pointer">
					<EmailShareButton
						url={shareUrl}
						subject={title}
						body="Check out this recipe: "
					>
						<EmailIcon size={48} round />
					</EmailShareButton>
				</div>
				<div className="flex items-center justify-center bg-slate-100 w-16 h-16 rounded-full transform hover:scale-110 cursor-pointer">
					<TwitterShareButton url={shareUrl} title={title}>
						<TwitterIcon size={48} round />
					</TwitterShareButton>
				</div>
				<div className="flex items-center justify-center bg-slate-100 w-16 h-16 rounded-full transform hover:scale-110 cursor-pointer">
					<WhatsappShareButton url={shareUrl} title={title}>
						<WhatsappIcon size={48} round />
					</WhatsappShareButton>
				</div>
				<div className="flex items-center justify-center bg-slate-100 w-16 h-16 rounded-full transform hover:scale-110 cursor-pointer">
					<TelegramShareButton url={shareUrl} title={title}>
						<TelegramIcon size={48} round />
					</TelegramShareButton>
				</div>
			</div>
			{copyMessageVisible && (
				<div className="text-lg font-medium mt-2">
					Recipe copied to clipboard!
				</div>
			)}
		</div>
	);
};
