import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '@/assets/svg/kitched-red-logo.svg';
import { useAuth } from '@/context/useAuth';

interface MessageData {
	route?: string;
}

export const HomeLandingPage = () => {
	const { user, loading } = useAuth();
	const navigate = useNavigate();

	user && navigate('/home');

	useEffect(() => {
		const handleMessage = (event: MessageEvent<MessageData>) => {
			// Todo: Check if the message comes from the correct origin for security
			const { route } = event.data;
			if (route) {
				navigate(route); // Update the URL and navigate to the desired route
			}
		};

		// Set up the message listener
		window.addEventListener('message', handleMessage);

		// Clean up the listener on component unmount
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [navigate]);

	if (loading) {
		return (
			<div className="bg-gradient-to-b from-brandBeigeStrong to-white w-full h-full">
				<div className="flex justify-center items-center w-full h-full pt-32">
					<img
						src={logo}
						alt="Kitched Logo"
						className="h-10 md:h-14 lg:h-16 animate-pulse"
					/>
				</div>
			</div>
		);
	}

	return (
		<div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
			{/* Load a temporary iframe to mask the url whilst show the framer landing page */}
			<iframe
				src="https://same-selfie-631336.framer.app/"
				title="Kitched"
				style={{ width: '100%', height: '100%', border: 'none' }}
			/>
		</div>
	);
};
