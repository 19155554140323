import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from '@/components/common/Loader';
import { Role, User } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { notifyInfo } from '@/helper/notifications';

interface ProtectedRoleRouteProps {
	roles: Role[];
	component: JSX.Element;
}
const PROTECTOR_ERROR_MESSAGE = 'Oops, looks like you need to sign in!';
export const ProtectedRouteRole: React.FC<ProtectedRoleRouteProps> = ({
	roles,
	component,
}) => {
	const { user, loading } = useAuth();
	const navigate = useNavigate();

	const isAuthorized = (user: User | null): boolean => {
		if (!user) return false;
		return roles.some(
			(role) => user.role === role || (role === Role.CHEF && user.chef),
		);
	};

	useEffect(() => {
		if (loading) return;
		if (!isAuthorized(user)) {
			if (user) navigate('/home', { replace: true });
			else navigate('/login', { replace: true });
			notifyInfo(PROTECTOR_ERROR_MESSAGE);
		}
	}, [loading, user, navigate, roles]);

	if (loading && !user) {
		return (
			<div className="flex items-center justify-center mt-[25vh]">
				<Loader />
			</div>
		);
	}

	return isAuthorized(user) ? component : null;
};
