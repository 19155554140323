import { useState } from 'react';

import editPencilIcon from '@/assets/svg/edit-pencil-icon.svg';
import { ProfileImage } from '@/components/images/ProfileImage';
import { User } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { editUser } from '@/service/api/user';

interface UpdateProfileImageProps {
	user: User;
}

const UpdateProfileImage = ({ user }: UpdateProfileImageProps) => {
	const { fetchUser } = useAuth();
	const [loading, setLoading] = useState(false);

	const handleImageUpload = async (uploadedFile: File) => {
		try {
			if (!uploadedFile)
				throw new Error('Please select an uploadedFile to upload');

			await editUser({
				userId: user?.id,
				newProfileImage: uploadedFile,
			});

			notifySuccess('Image uploaded successfully!');
			await fetchUser();
		} catch (error) {
			if (error instanceof Error) notifyError(error.message);
			else notifyError('There was an error uploading the image');
		}
	};

	const handleFileUploads = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setLoading(true);
		const uploadedFile = event.target.files?.[0];

		if (uploadedFile) {
			await handleImageUpload(uploadedFile);
		}

		setLoading(false);
	};

	return (
		<div className="flex flex-col items-start lg:flex-row">
			<div className="relative group w-12 h-12">
				<ProfileImage
					loading={loading}
					dataCy="user-image"
					alt="avatar"
					className="w-full h-full rounded-full object-cover transition-opacity duration-300 group-hover:opacity-50"
				/>
				<div className="absolute inset-0 flex flex-col justify-center items-center bg-gray-800 bg-opacity-50 rounded-full opacity-0 transition-opacity duration-300 group-hover:opacity-100">
					<label htmlFor="image-upload" className="cursor-pointer">
						<img
							data-cy="edit-pencil-icon"
							src={editPencilIcon}
							alt="Edit profile"
							className="w-6 h-6"
						/>
					</label>
					<input
						id="image-upload"
						name="image-upload"
						type="file"
						accept="image/*"
						className="hidden"
						onChange={handleFileUploads}
					/>
				</div>
			</div>
		</div>
	);
};

export default UpdateProfileImage;
