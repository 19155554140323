import { Form, Formik } from 'formik';

import { FormField } from '@/pages/forgot-password/FormField';
import { confirmationValidationSchema } from '@/pages/forgot-password/validationSchemas/ConfirmationForm.schema';

const initialValues = {
	'new-password': '',
	'confirm-new-password': '',
	code: '',
};

const confirmationFormFields = {
	newPassword: {
		label: 'New password',
		id: 'new-password',
		name: 'new-password',
		type: 'password',
		placeholder: 'Enter your new password',
	},
	confirmNewPassword: {
		label: 'Confirm new password',
		id: 'confirm-new-password',
		name: 'confirm-new-password',
		type: 'password',
		placeholder: 'Confirm your new password',
	},
	code: {
		label: 'Code',
		id: 'code',
		name: 'code',
		type: 'text',
		placeholder: 'Enter your code',
	},
};

export const ConfirmationForm = ({
	onSubmit,
	codeSent,
}: {
	onSubmit: (values: typeof initialValues) => any;
	codeSent: boolean;
}) => {
	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={confirmationValidationSchema}
			onSubmit={(values) => onSubmit(values)}
		>
			<Form>
				<FormField {...confirmationFormFields.newPassword} />
				<FormField {...confirmationFormFields.confirmNewPassword} />
				<FormField {...confirmationFormFields.code} />
				<div className="flex justify-around gap-2">
					<button
						data-cy="confirmation-form-button"
						type="submit"
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
					>
						Confirm
					</button>
					{codeSent && (
						<span
							className="text-gray-900 text-[12px] text-center self-center"
							data-cy="confirmation-form-message"
						>
							Did not receive your code? Make sure your account was previously
							created. If it was created by an administrator, admin should reset
							it for you.
						</span>
					)}
				</div>
			</Form>
		</Formik>
	);
};
