import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@/context/useAuth';
import { notifyError } from '@/helper/notifications';
import { editUserPreferences } from '@/service/api/user';

export const useUpdatePreferences = () => {
	const { user, setUser } = useAuth();
	const queryClient = useQueryClient();

	const updatePreferencesMutation = useMutation({
		mutationFn: editUserPreferences,
		onSuccess: (data) => {
			if (user) {
				setUser({
					...user,
					cookingLevel: data.cookingLevel,
					countryCuisinePreferences: data.countryCuisinePreferences,
					dietaryPreferences: data.dietaryPreferences,
					dietaryConcernsAndAllergies: data.dietaryConcernsAndAllergies,
				});
			}

			queryClient.setQueryData(['user-preferences', data.id], data);
		},
		onError: (error: unknown) => {
			if (error instanceof Error) notifyError(error.message);
			else notifyError('Preferences update failed');
		},
	});

	return { updatePreferencesMutation };
};
