import { useEffect, useRef, useState } from 'react';

import deleteIcon from '@/assets/svg/delete-icon.svg';
import menuIcon from '@/assets/svg/menu-collections-icon.svg';
import renameIcon from '@/assets/svg/rename-icon.svg';

interface CollectionHeaderMenuProps {
	onDelete: () => void;
	onRename: () => void;
}

export const CollectionHeaderMenu = ({
	onDelete,
	onRename,
}: CollectionHeaderMenuProps) => {
	const [openMenu, setOpenMenu] = useState(false);
	const menuRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setOpenMenu(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [menuRef]);

	const handleMenu = () => {
		setOpenMenu(!openMenu);
	};

	return (
		<div className="relative" ref={menuRef}>
			<button
				className="w-12 h-12 flex items-center justify-center bg-slate-100 rounded-full"
				onClick={handleMenu}
				data-cy="collection-header-menu-button"
			>
				<img src={menuIcon} alt="menu-icon" className="w-6 h-6" />
			</button>
			{openMenu && (
				<div className="absolute right-0 mt-2 w-32 bg-slate-100 rounded-lg shadow-lg z-10">
					<button
						data-cy="collection-header-menu-delete"
						className="w-full text-left text-sm px-4 py-2 hover:text-slate-600"
						onClick={() => {
							onDelete();
							setOpenMenu(false);
						}}
					>
						<div className="flex gap-2 items-center">
							<img src={deleteIcon} alt="Delete" className="w-4 h-4" />
							<p>Delete</p>
						</div>
					</button>
					<button
						data-cy="collection-header-menu-rename"
						className="w-full text-left text-sm px-4 py-2 hover:text-slate-600"
						onClick={() => {
							onRename();
							setOpenMenu(false);
						}}
					>
						<div className="flex gap-2 items-center">
							<img src={renameIcon} alt="Rename" className="w-4 h-4" />
							<p>Rename</p>
						</div>
					</button>
				</div>
			)}
		</div>
	);
};
