import facebookIcon from '@/assets/svg/facebook-icon.svg';
import googleIcon from '@/assets/svg/google-icon.svg';
import twitterIcon from '@/assets/svg/twitter-icon.svg';

export const SocialIcons = () => {
	return (
		<div className="w-[182px] h-[50px] flex justify-between mx-auto mt-5 mb-1.5 hidden">
			<div className="w-[50px] h-[50px] rounded-full flex justify-center items-center cursor-pointer bg-brandBeige transition-colors hover:cursor-not-allowed">
				<img src={twitterIcon} alt="recipe-icon" style={{ width: '18px' }} />
			</div>

			<div className="w-[50px] h-[50px] rounded-full flex justify-center items-center cursor-pointer bg-brandOrange transition-colors hover:cursor-not-allowed">
				<img src={googleIcon} alt="recipe-icon" style={{ width: '18px' }} />
			</div>

			<div className="w-[50px] h-[50px] rounded-full flex justify-center items-center cursor-pointer bg-brandBeige transition-colors hover:cursor-not-allowed">
				<img src={facebookIcon} alt="recipe-icon" style={{ width: '18px' }} />
			</div>
		</div>
	);
};
