import { useQuery } from '@tanstack/react-query';

import { User } from '@/context/interfaces';
import { fetchUserByEmail } from '@/service/api/user';

const useGetUserByEmail = (email: string) => {
	const {
		data: user,
		isError: isErrorLoadingUser,
		error: errorLoadingUser,
		refetch: refetchUser,
		isFetching: isFetchingUser,
	} = useQuery<User | null, Error>({
		queryKey: ['userByEmail', email],
		queryFn: () => fetchUserByEmail(email),
		enabled: false,
	});

	return {
		user,
		isErrorLoadingUser,
		errorLoadingUser,
		refetchUser,
		isFetchingUser,
	};
};

export default useGetUserByEmail;
