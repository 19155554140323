import redBg from '@assets/image/side-wave-red-bg.png';
import whiteBg from '@assets/image/side-wave-white-bg.png';
import yewllowBg from '@assets/image/side-wave-yellow-bg.png';

export const subscriptionPlans = [
	{
		id: 1,
		name: 'FREE',
		param: 'free',
		price: '$0',
		period: ' Free forever',
		freeTrial: false,
		features: [
			'Hundreds of recipes',
			'Desktop, tablet and mobile access',
			'Easy save and filter',
			'Dietary preferences',
			'No ads',
		],
		bgColor: whiteBg,
		buttonTextColor: 'text-slate-400',
		textColor: 'text-black',
	},
	{
		id: 2,
		name: 'ESSENTIAL',
		param: 'essential',
		price: '$10.99',
		period: '/month',
		freeTrial: true,
		features: [
			'Everything in Free',
			'Personalised recipe recommendations',
			'Personalised weekly planner and meal plans',
			'Curate and share recipe collections',
		],
		bgColor: redBg,
		buttonTextColor: 'text-friendlyRed',
		textColor: 'text-white',
	},
	{
		id: 2,
		name: 'PRO',
		param: 'pro',
		price: '$100',
		period: '/year',
		freeTrial: true,
		features: [
			'Everything is Essential',
			'Shopping lists',
			'Grocery delivery',
			'Partner discounts',
			'Event priority',
		],
		bgColor: yewllowBg,
		buttonTextColor: 'text-brandRed',
		textColor: 'text-white',
	},
];
