import { ITitleProps } from '@/interface/title.interface';

export const Title = ({
	textTitle,
	colorText,
	titleWithColor,
	optionalText,
}: ITitleProps) => {
	return (
		<h3
			data-cy="recipesforyou-title"
			className="text-2xl sm:text-3xl md:text-4xl text-center font-semibold md:text-left 2xl:text-5xl tracking-[-0.04em]"
		>
			{textTitle} <span className={`text-${colorText}`}>{titleWithColor} </span>
			{optionalText && <span>{optionalText}</span>}
		</h3>
	);
};
