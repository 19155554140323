import algoliasearch from 'algoliasearch';
import { useEffect, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { useNavigate, useParams } from 'react-router-dom';

import { AlgoliaPagination } from '@/components/algolia/AlgoliaPagination';
import { AlgoliaResults } from '@/components/algolia/AlgoliaResults';
import { AlgoliaSearchBar } from '@/components/algolia/AlgoliaSearchBar';
import { CustomAlgoliaSearchBox } from '@/components/algolia/CustomAlgoliaSearchBox';

const searchClient = algoliasearch(
	import.meta.env.VITE_ALGOLIA_APP_ID ?? '',
	import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY ?? '',
);
const algoliaIndex = import.meta.env.VITE_ALGOLIA_INDEX ?? 'demo_kitched_index';

export const SearchResults = () => {
	const { param } = useParams();
	const [filter, setFilter] = useState('');
	const [searchWord, setSearchWord] = useState<string>();
	const [clearAll, setClearAll] = useState(false);
	const navigate = useNavigate();
	const recipesPerPage = 18;

	useEffect(() => {
		if (param?.startsWith('tag-')) {
			setSearchWord(param.substring(4));
		} else {
			setSearchWord(param);
		}
	}, [param]);

	const handleSearch = (query: string) => {
		navigate(`/search/${query}`);
	};

	return (
		<InstantSearch indexName={algoliaIndex} searchClient={searchClient}>
			<Configure hitsPerPage={recipesPerPage} filters={filter} />

			<div className="flex flex-col items-center px-5 lg:items-start sm:px-6 gap-y-4 mt-6">
				<h3
					data-cy="search-page-title"
					className="pt-8  font-semibold text-xl md:text-4xl 2xl:text-5xl"
				>
					What kind of <span className="text-brandRed">recipes</span> are you
					looking for?
				</h3>
				<div className="flex flex-col">
					<CustomAlgoliaSearchBox
						searchWord={searchWord as string}
						onSearch={handleSearch}
					/>
					<span className="text-xs text-slate-300 ml-2">
						Search by name or tag
					</span>
				</div>

				<AlgoliaSearchBar
					setFilter={setFilter}
					filter={filter}
					onSearch={handleSearch}
					setClearAll={setClearAll}
					clearAll={clearAll}
				/>

				<AlgoliaResults clearAll={clearAll} setClearAll={setClearAll} />

				<AlgoliaPagination />
			</div>
		</InstantSearch>
	);
};
