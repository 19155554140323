import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import closeIconSvg from '@/assets/svg/close-icon.svg';

interface PopUpProps {
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
	topCss?: string;
}

export const PopUp = ({ open, onClose, children, topCss }: PopUpProps) => {
	const closeIcon = (
		<div
			data-cy="closed-popup"
			className="bg-slate-50 rounded-full h-10 w-10 flex items-center justify-center"
		>
			<img
				src={closeIconSvg}
				alt="close icon"
				className="w-5 h-5"
				data-cy="comingsoon-button-close-modal"
			/>
		</div>
	);

	const modalStyles = {
		modal: {
			maxWidth: '92%',
			width: 'auto',
			borderRadius: '24px',
			margin: 'auto',
			marginTop: '20px',
			marginBottom: '20px',
			top: topCss || '',
		},
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			styles={modalStyles}
			closeIcon={closeIcon}
		>
			{children}
		</Modal>
	);
};
