import { useParams } from 'react-router-dom';

import { RecipeForm } from './RecipeForm';

import { Loader } from '@/components/common/Loader';
import { useGetOneRecipeById } from '@/hooks/recipes/useGetOneRecipeById';

export const EditRecipe = () => {
	const { id } = useParams();

	const {
		recipesById,
		isLoadingRecipesById,
		isErrorRecipesById,
		errorRecipesById,
	} = useGetOneRecipeById(id as string);

	if (isLoadingRecipesById) {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<Loader size={4} />
			</div>
		);
	}

	if (isErrorRecipesById) {
		return (
			<div className="flex justify-center text-2xl items-center h-[40vh]">
				<p>{errorRecipesById?.message}</p>
			</div>
		);
	}

	return <RecipeForm recipesById={recipesById} />;
};
