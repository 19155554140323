import { QueryFunction, QueryKey } from '@tanstack/react-query';
import {
	PaginationState,
	createColumnHelper,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { Chef } from '@/context/interfaces';
import { useFetchQuerys } from '@/helper/useFetchQuerys';
import { useDeleteChef } from '@/hooks/chef/useDeleteChef';
import { IGetAllResponse } from '@/interface/get-all-response';
import { fetchChefs } from '@/service/api/chef';

const fetchChefsWrapped: QueryFunction<IGetAllResponse<Chef>, QueryKey> = ({
	queryKey,
}) => {
	const [, pageIndex, pageSize] = queryKey as [string, number, number];
	return fetchChefs({ take: pageSize, skip: pageIndex * pageSize });
};

const useChefTable = () => {
	const columnHelper = createColumnHelper<Chef>();
	const { deleteChef, isDeletingChef } = useDeleteChef();

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const { renderLoading, renderError, data, isLoading } = useFetchQuerys(
		fetchChefsWrapped,
		['chefs', pagination.pageIndex, pagination.pageSize],
	);

	const handleDelete = async (id: number) => {
		await deleteChef(id);
	};

	const columns = [
		columnHelper.accessor('user.name', {
			header: () => 'Name',
			cell: (info) => info.getValue() + ' ' + info.row.original.user.last_name,
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor('user.email', {
			header: () => 'Email',
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor('id', {
			id: 'actions',
			header: () => 'Actions',
			cell: (info) => (
				<button
					onClick={() => handleDelete(info.getValue())}
					className={`bg-red-500 text-white p-1 rounded hover:bg-red-700 ${
						isDeletingChef ? 'cursor-not-allowed opacity-50 ' : ''
					}`}
				>
					Remove
				</button>
			),
			footer: (info) => info.column.id,
		}),
	];

	const total = data?.total ?? 0;
	const pageSize = pagination.pageSize ?? 1;
	const pageCount = Math.ceil(total / pageSize);

	const table = useReactTable({
		data: data?.data || [],
		columns,
		pageCount,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
		state: {
			pagination,
		},
		manualPagination: true,
	});

	return {
		table,
		isDeletingChef,
		isLoading,
		renderLoading,
		renderError,
	};
};

export default useChefTable;
