import chefAvatar from '@/assets/image/chef-avatar.png';
import { CdnImage } from '@/components/images/CdnImage';

interface ChefAvatarProps {
	src: string;
}

export const ChefAvatar = ({ src }: ChefAvatarProps) => {
	const profileImage = src ? src : chefAvatar;

	return (
		<div
			data-cy="chef-avatar"
			className="overflow-hidden w-[200px] 2xl:w-[235px] h-[300px] rounded-3xl"
		>
			<CdnImage
				src={profileImage}
				alt="Chef Avatar"
				className="object-cover w-full h-[300px] rounded-3xl"
			/>
		</div>
	);
};
