import { Link, Outlet, useLocation } from 'react-router-dom';

import instagramColorfulIcon from '@/assets/svg/colorful/instagram.svg';
import follow from '@/assets/svg/follow.svg';
import settingsIcon from '@/assets/svg/settings-black.svg';
import avatar from '@/assets/svg/user.svg';
import { Loader } from '@/components/common/Loader';
import { CdnImage } from '@/components/images/CdnImage';
import { useAuth } from '@/context/useAuth';
import { ScrollToTop } from '@/helper/scrollToTop';
import useWindowWidth from '@/hooks/useWindowWidth';
import { getInstagramCode } from '@/service/api/instagram';

export const ChefLayoutProfile = () => {
	const { user } = useAuth();
	const location = useLocation();
	const windowWidth = useWindowWidth();

	const onNavigateToInstagramAuthorizationWindow = async (): Promise<void> => {
		const code = await getInstagramCode();

		window.location.href = code;
	};

	if (!user)
		return (
			<div className="flex justify-center items-center h-[80vh]">
				<Loader size={4} />
			</div>
		);

	return (
		<div className="flex flex-col gap-y-10 px-5 sm:px-6  font-helveticaNeue">
			<ScrollToTop />
			<div className="flex flex-col gap-y-10">
				<div
					data-cy="detail-page-chef-presentation"
					className="flex items-center justify-between gap-[2px] md:gap-2  w-full"
				>
					<div className="flex flex-col sm:flex-row justify-start items-start sm:items-center sm:justify-center gap-2 w-[85%]">
						<div className="flex flex-col min-[480px]:flex-row justify-start items-start min-[480px]:items-center gap-2 min-[480px]:gap-2 ">
							<CdnImage
								src={user?.profile_image?.src || avatar}
								alt="avatar"
								className="w-16 h-16 rounded-full object-cover"
							/>
							<div className="flex flex-col leading-3 gap-2 h-16 justify-center">
								<span className=" text-slate-400 text-[13px]">
									{user?.email}
								</span>
								<div className="text-lg min-[480px]:text-xl lg:text-2xl font-semibold text-wrap ">
									<span data-cy="chef-profile-page-name" className="mr-2">
										{user.name}
									</span>
									<span data-cy="chef-profile-page-last-name">
										{user.last_name}
									</span>
								</div>
							</div>
						</div>
						{windowWidth >= 1024 && (
							<button className="order-last grow min-[412px]:order-none min-[412px]:grow-0 flex items-center justify-center h-12 gap-x-2 py-4 px-4 bg-brandRed rounded-3xl text-white transition-colors hover:bg-[#E1B864]">
								<span data-cy="profile-plus-text">Profile Plus</span>
								<img src={follow} alt="follow-icon" />
							</button>
						)}
						<div className="flex-1"></div>
						<div className="flex gap-x-4 ">
							<div className="flex flex-col items-center">
								<span className="text-xs text-slate-400">Following</span>
								<span
									data-cy="chef-profile-page-following"
									className="font-bold text-2xl"
								>
									{user?.numberOfFollowings}
								</span>
							</div>
							<div className="flex flex-col items-center">
								<span className="text-xs text-slate-400">Followers</span>
								<span
									data-cy="chef-profile-page-followers"
									className="font-bold text-2xl"
								>
									{user?.chef?.numberOfFollowers}
								</span>
							</div>
						</div>
					</div>
					{windowWidth > 350 && <div className=""></div>}
					<div
						className="self-start sm:self-center"
						data-cy="settings-button-container"
					>
						<Link to={`/settings/me`}>
							<button className="border-gray-300 border-[1px] bg-[#F8F8F8] hover:bg-gray-300 w-[60px] min-[340px]:w-[102px] h-[52px] rounded-full flex justify-center items-center gap-1">
								<img
									src={settingsIcon}
									alt="settings icon"
									className="w-4 h-4"
								/>
								{windowWidth > 340 && (
									<p className="text-[13px] font-bold">Settings</p>
								)}
							</button>
						</Link>
					</div>
				</div>

				{windowWidth >= 1024 && (
					<div className="flex flex-col items-end gap-4">
						{location.pathname === '/chef/profile/instagram-posts' ? (
							<div className="flex flex-col gap-4 sm:flex-row">
								<Link
									to={'/chef/profile'}
									className="flex items-center gap-x-2 py-2 px-4 text-white rounded-3xl bg-brandRed"
								>
									<span>Your list of recipes in Kitched</span>
								</Link>
								<button
									onClick={onNavigateToInstagramAuthorizationWindow}
									className="flex items-center gap-x-2 py-2 px-4 text-pink-500 rounded-3xl bg-pink-200"
								>
									<span className=" font-bold">Connect with Instagram</span>
									<img
										src={instagramColorfulIcon}
										alt="instagram-icon"
										className="w-6"
									/>
								</button>
							</div>
						) : (
							<Link
								data-cy="chef-profile-page-instagram-posts-link"
								to={'/chef/profile/instagram-posts'}
								className="flex items-center gap-x-2 py-2 px-4 rounded-3xl bg-slate-100 hover:bg-gray-300
							"
							>
								<span className=" font-bold">Your Instagram recipes</span>
								<img
									src={instagramColorfulIcon}
									alt="instagram-icon"
									className="w-6"
								/>
							</Link>
						)}
						{location.pathname !== '/chef/profile/recipes/create' && (
							<div className="flex justify-end">
								<Link to={'/chef/profile/recipes/create'}>
									<button
										className="order-last grow min-[412px]:order-none min-[412px]:grow-0 flex items-center justify-center gap-x-2 py-2 px-4 bg-brandRed rounded-3xl text-white transition-colors hover:bg-[#E1B864]"
										data-cy="create-recipe-button"
									>
										Create a recipe manually
									</button>
								</Link>
							</div>
						)}
					</div>
				)}
				<Outlet />
			</div>
		</div>
	);
};
