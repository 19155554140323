import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { ICollection } from '@/interface/collection.interfaces';
import { addRecipeToCollection } from '@/service/api/collection';

interface AddRecipeToCollectionData {
	collectionId: number;
	recipeId: number;
}

export const useAddRecipeToCollection = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation<ICollection, Error, AddRecipeToCollectionData>({
		mutationFn: async ({
			collectionId,
			recipeId,
		}: AddRecipeToCollectionData) => {
			return addRecipeToCollection(collectionId, recipeId);
		},
		onError: (error) => {
			notifyError(error.message);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['collection'] });
		},
		onSuccess: () => {
			notifySuccess('Recipe added to collection successfully');
		},
	});

	return mutation;
};
