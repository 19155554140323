import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { createRecipe } from '@/service/api/recipe';

export const useCreateRecipe = () => {
	const createRecipeMutation = useMutation({
		mutationFn: createRecipe,
		onSuccess: () => {
			notifySuccess('Recipe created successfully');
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { createRecipeMutation };
};
