export interface IPagination {
	take: number;
	skip: number;
	sort?: string;
	tags?: string;
}

export const defaultPortfolioPaginationOptions = {
	take: 6,
	skip: 0,
};
