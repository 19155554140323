import * as Yup from 'yup';

export const changepasswordValidationSchema = () => {
	return Yup.object({
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		password: Yup.string().required('Password is required'),
		newPassword: Yup.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(
				/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/,
				'Password must contain a special character or a space that is neither at the beginning nor at the end',
			)
			.matches(/^(?!\s).*$/, 'Password must not start with a space')
			.matches(/^(?!.*\s$).*$/, 'Password must not end with a space'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('newPassword')], 'Passwords must match')
			.required('Confirm password is required'),
	});
};
