export const collectionsCardsConfig = {
	320: {
		slidesPerView: 1.5,
	},
	375: {
		slidesPerView: 1.8,
	},
	430: {
		slidesPerView: 2,
	},
	500: {
		slidesPerView: 2.3,
	},
	570: {
		slidesPerView: 2.6,
	},
	640: {
		slidesPerView: 3.01,
	},
	768: {
		slidesPerView: 3.5,
	},
	900: {
		slidesPerView: 4.01,
	},
	1124: {
		slidesPerView: 4.4,
	},
	1280: {
		slidesPerView: 5.01,
	},
	1380: {
		slidesPerView: 5.3,
	},
	1440: {
		slidesPerView: 5.7,
	},
	1536: {
		slidesPerView: 5.5,
	},
};

export const smallCardsConfig = {
	320: {
		slidesPerView: 1.35,
	},
	375: {
		slidesPerView: 2.01,
	},
	640: {
		slidesPerView: 3.01,
	},
	768: {
		slidesPerView: 3.5,
	},
	900: {
		slidesPerView: 4.01,
	},
	1124: {
		slidesPerView: 4.4,
	},
	1280: {
		slidesPerView: 5.01,
	},
	1380: {
		slidesPerView: 5.3,
	},
	1440: {
		slidesPerView: 5.7,
	},
	1536: {
		slidesPerView: 5.35,
	},
};
