import homepageVideo from '@/assets/video/homepage-video.mp4';

export const BackgroundVideoSection = () => {
	return (
		<div
			data-cy="background-video-section"
			className="relative flex flex-col w-full mb-4 xl:mb-0"
		>
			<div className="h-96 sm:h-[550px] w-full">
				<video
					src={homepageVideo}
					className="brightness-50 object-cover w-full h-full"
					autoPlay
					loop
					controls={false}
					muted
				/>
			</div>

			<div className="hidden lg:block">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1000 45"
					style={{ transform: 'rotate(180deg)' }}
					className="absolute bottom-[6px] left-0 w-full"
				>
					<path d="M0 0v2s250 43 500 43 500-43 500-43V0H0Z" fill="white"></path>
				</svg>
			</div>
			<div className="w-full h-2 bg-white"></div>
		</div>
	);
};
