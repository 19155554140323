import { useEffect, useState } from 'react';

import { useUpdatePreferences } from './useUpdateUserPreferences';

import { User } from '@/context/interfaces';
import {
	CountryCuisinePreferences,
	DietaryConcernsAndAllergies,
	DietaryPreferences,
	ICookingLevel,
	UserPreferences,
} from '@/interface/user.interfaces';

export const useUserPreferences = (user: User) => {
	const { updatePreferencesMutation } = useUpdatePreferences();

	const [preferences, setPreferences] = useState<UserPreferences>({
		cookingLevel: user.cookingLevel,
		countryCuisinePreferences: user.countryCuisinePreferences,
		dietaryPreferences: user.dietaryPreferences,
		dietaryConcernsAndAllergies: user.dietaryConcernsAndAllergies,
	});

	const handleCountryCuisineChange = (
		countryCuisinePreferences: CountryCuisinePreferences,
	) => {
		const updatedChefs = preferences.countryCuisinePreferences?.includes(
			countryCuisinePreferences,
		)
			? preferences.countryCuisinePreferences.filter(
					(rp) => rp !== countryCuisinePreferences,
			  )
			: [
					...(preferences.countryCuisinePreferences || []),
					countryCuisinePreferences,
			  ];
		const newPreferences = {
			...preferences,
			countryCuisinePreferences: updatedChefs,
		};
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	const handledietaryPreferencesChange = (
		dietaryPreferences: DietaryPreferences,
	) => {
		const updatedChefs = preferences.dietaryPreferences?.includes(
			dietaryPreferences,
		)
			? preferences.dietaryPreferences.filter((c) => c !== dietaryPreferences)
			: [...(preferences.dietaryPreferences || []), dietaryPreferences];
		const newPreferences = { ...preferences, dietaryPreferences: updatedChefs };
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	const handledietaryConcernsAndAllergiesChange = (
		dietaryConcernsAndAllergies: DietaryConcernsAndAllergies,
	) => {
		const updatedIngredients =
			preferences.dietaryConcernsAndAllergies?.includes(
				dietaryConcernsAndAllergies,
			)
				? preferences.dietaryConcernsAndAllergies.filter(
						(i) => i !== dietaryConcernsAndAllergies,
				  )
				: [
						...(preferences.dietaryConcernsAndAllergies || []),
						dietaryConcernsAndAllergies,
				  ];
		const newPreferences = {
			...preferences,
			dietaryConcernsAndAllergies: updatedIngredients,
		};
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	const handleCookingLevelChange = (level: ICookingLevel) => {
		const newPreferences = { ...preferences, cookingLevel: level };
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	useEffect(() => {
		setPreferences({
			cookingLevel: user.cookingLevel,
			countryCuisinePreferences: user.countryCuisinePreferences || [],
			dietaryPreferences: user.dietaryPreferences || [],
			dietaryConcernsAndAllergies: user.dietaryConcernsAndAllergies || [],
		});
	}, [user]);

	return {
		preferences,
		handledietaryPreferencesChange,
		handledietaryConcernsAndAllergiesChange,
		handleCookingLevelChange,
		handleCountryCuisineChange,
		isPendingPreferencesChange: updatePreferencesMutation.isPending,
	};
};
