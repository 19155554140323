export const enum COLLECTION_ERRORS_MESSAGES {
	NOT_FOUND = 'We are sorry, but the collection you are looking for does not exist.',
	GET_ALL_COLLECTION_ERROR = 'Oops! There was a problem loading the collections. Please try again later!',
	GET_COLLECTION_BY_ID_ERROR = 'Oops! There was a problem loading the collection. Please try again later!',
	CREATE_COLLECTION_ERROR = 'Error creating collection',
	UPDATE_COLLECTION_ERROR = 'Error updating collection',
	DELETE_COLLECTION_ERROR = 'Error deleting collection',
	ADD_RECIPE_TO_COLLECTION_ERROR = 'Error adding recipe to collection',
	REMOVE_RECIPE_FROM_COLLECTION_ERROR = 'Error removing recipe from collection',
}
