import { useFetchChefById } from '../../hooks/chef/useFetchChefById';

import favRedIcon from '@/assets/svg/favorite-recipe-red-icon.svg';
import saveRecipeIcon from '@/assets/svg/save-recipe-black-icon.svg';
import { Loader } from '@/components/common/Loader';

export const ChefDashboard = () => {
	const { chef, isLoadingChef, errorChef, isErrorLoadingChef, isFetchingChef } =
		useFetchChefById();

	if (isErrorLoadingChef) {
		return (
			<div className="w-full grid place-content-center text-xl text-alertRed">
				{errorChef?.message}
			</div>
		);
	}

	const today = new Date();
	const fiveMinutesAgo = new Date(today.getTime() - 5 * 60 * 1000);
	const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

	const newFollowers = chef?.followers?.filter((follower) => {
		const followerDate = new Date(follower.updatedAt);
		return followerDate >= sevenDaysAgo && followerDate <= fiveMinutesAgo;
	});

	return (
		<div>
			{isLoadingChef ||
				(isFetchingChef && (
					<div className="flex justify-center items-center">
						<Loader />
					</div>
				))}
			<h3
				className="text-gray-700 text-3xl font-medium"
				data-cy="dashboard-title"
			>
				Dashboard
			</h3>
			<div className="mt-4">
				<div className="flex flex-wrap -mx-6">
					<div className="w-full px-6 sm:w-1/2 xl:w-1/3">
						<div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<div className="p-3 bg-indigo-600 bg-opacity-75 rounded-full">
								<svg
									className="w-8 h-8 text-white"
									viewBox="0 0 28 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M18.2 9.08889C18.2 11.5373 16.3196 13.5222 14 13.5222C11.6804 13.5222 9.79999 11.5373 9.79999 9.08889C9.79999 6.64043 11.6804 4.65556 14 4.65556C16.3196 4.65556 18.2 6.64043 18.2 9.08889Z"
										fill="currentColor"
									/>
									<path
										d="M25.2 12.0444C25.2 13.6768 23.9464 15 22.4 15C20.8536 15 19.6 13.6768 19.6 12.0444C19.6 10.4121 20.8536 9.08889 22.4 9.08889C23.9464 9.08889 25.2 10.4121 25.2 12.0444Z"
										fill="currentColor"
									/>
									<path
										d="M19.6 22.3889C19.6 19.1243 17.0927 16.4778 14 16.4778C10.9072 16.4778 8.39999 19.1243 8.39999 22.3889V26.8222H19.6V22.3889Z"
										fill="currentColor"
									/>
									<path
										d="M8.39999 12.0444C8.39999 13.6768 7.14639 15 5.59999 15C4.05359 15 2.79999 13.6768 2.79999 12.0444C2.79999 10.4121 4.05359 9.08889 5.59999 9.08889C7.14639 9.08889 8.39999 10.4121 8.39999 12.0444Z"
										fill="currentColor"
									/>
									<path
										d="M22.4 26.8222V22.3889C22.4 20.8312 22.0195 19.3671 21.351 18.0949C21.6863 18.0039 22.0378 17.9556 22.4 17.9556C24.7197 17.9556 26.6 19.9404 26.6 22.3889V26.8222H22.4Z"
										fill="currentColor"
									/>
									<path
										d="M6.64896 18.0949C5.98058 19.3671 5.59999 20.8312 5.59999 22.3889V26.8222H1.39999V22.3889C1.39999 19.9404 3.2804 17.9556 5.59999 17.9556C5.96219 17.9556 6.31367 18.0039 6.64896 18.0949Z"
										fill="currentColor"
									/>
								</svg>
							</div>
							<div className="mx-5">
								<h4
									data-cy="chef-followers-length"
									className="text-2xl font-semibold text-gray-700"
								>
									{chef?.followers?.length}
								</h4>
								<div data-cy="chef-followers-text" className="text-gray-500">
									Followers
								</div>
							</div>
						</div>
					</div>

					<div className="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
						<div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<img
								src={favRedIcon}
								alt="fav-icon"
								className="object-cover w-10 h-10 rounded-full"
							/>

							<div className="mx-5">
								<h4
									data-cy="chef-favoritesRecipes-length"
									className="text-2xl font-semibold text-gray-700"
								>
									{chef?.followers?.reduce(
										(totalFavoritesRecipes, follower) => {
											return (
												totalFavoritesRecipes +
												(follower.favoritesRecipes?.length || 0)
											);
										},
										0,
									)}
								</h4>
								<div
									data-cy="chef-favoritesRecipes-text"
									className="text-gray-500"
								>
									Total of Favorites in Your Recipes
								</div>
							</div>
						</div>
					</div>

					<div className="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
						<div className="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
							<img
								src={saveRecipeIcon}
								alt="save-recipe-icon"
								className="object-cover w-10 h-10 rounded-full"
							/>

							<div className="mx-5">
								<h4
									data-cy="chef-savedRecipes-length"
									className="text-2xl font-semibold text-gray-700"
								>
									{chef?.followers?.reduce((totalSavedRecipes, follower) => {
										return (
											totalSavedRecipes + (follower.savedRecipes?.length || 0)
										);
									}, 0)}
								</h4>
								<div data-cy="chef-savedRecipes-text" className="text-gray-500">
									Saved recipes
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-8"></div>

			<div className="flex flex-col mt-8 mb-20">
				<div className="-my-2 py-2 overflow-x-auto sm:-mx-6-6 lg:-mx-8 lg:px-8">
					<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table className="min-w-full">
							<thead>
								<tr>
									<th
										className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
										data-cy="name-of-user-column"
									>
										Name
									</th>
									<th
										className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
										data-cy="type-of-user-column"
									>
										Type of User
									</th>
									<th
										data-cy="follow-up-date-column"
										className="sm:px px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
									>
										Follow-up date
									</th>
								</tr>
							</thead>
							<tbody className="bg-white" data-cy="new-followers">
								{newFollowers?.slice(0, 10).map((follower) => (
									<tr key={follower.id}>
										<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
											<div className="flex items-center">
												<div className="flex-shrink-0 h-10 w-10">
													<img
														className="h-10 w-10 rounded-full"
														src={follower?.profile_image?.src}
														alt={follower.name}
													/>
												</div>
												<div className="ml-4">
													<div className="text-sm leading-5 font-medium text-gray-900">
														{follower.name} {follower.last_name}
													</div>
													<div className="text-sm leading-5 text-gray-500">
														{follower.email}
													</div>
												</div>
											</div>
										</td>
										<td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
											<div className="text-sm leading-5 text-gray-500">
												{follower.chef ? 'Chef' : 'User'}
											</div>
										</td>
										<td className="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap">
											<div className="text-sm leading-5 text-gray-500">
												{new Date(follower?.updatedAt).toLocaleDateString()}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};
