import { useQuery } from '@tanstack/react-query';

import { getRecipeById } from '@/service/api/recipe';

export const useGetOneRecipeById = (id: string) => {
	const {
		data: recipesById,
		isLoading: isLoadingRecipesById,
		isError: isErrorRecipesById,
		error: errorRecipesById,
	} = useQuery({
		queryKey: ['recipe-by-id', id],
		queryFn: () => getRecipeById(id),
		retry: false,
	});

	return {
		recipesById,
		isLoadingRecipesById,
		isErrorRecipesById,
		errorRecipesById,
	};
};
