import { ReactNode, useEffect } from 'react';

import { logEvent } from '@/service/analytics/analytics';

interface AnalyticsWrapperProps {
	children: ReactNode;
	eventName: string;
}

export const AnalyticsWrapper = ({
	children,
	eventName,
}: AnalyticsWrapperProps) => {
	useEffect(() => {
		logEvent(eventName);
	}, [eventName]);

	return <>{children}</>;
};
