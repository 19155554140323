import type { ForwardRefExoticComponent, RefAttributes } from 'react';

import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import _Table from './Table';
import type { TableProps } from './Table';
import Td from './Td';
import Th from './Th';
import Tr from './Tr';

export type { TableProps } from './Table';
export type { THeadProps } from './THead';
export type { TBodyProps } from './TBody';
export type { TFootProps } from './TFoot';
export type { TrProps } from './Tr';
export type { ThProps } from './Th';
export type { TdProps } from './Td';

type CompoundedComponent = ForwardRefExoticComponent<
	TableProps & RefAttributes<HTMLElement>
> & {
	THead: typeof THead;
	TBody: typeof TBody;
	TFoot: typeof TFoot;
	Th: typeof Th;
	Tr: typeof Tr;
	Td: typeof Td;
};

const Table = _Table as CompoundedComponent;

Table.THead = THead;
Table.TBody = TBody;
Table.TFoot = TFoot;
Table.Th = Th;
Table.Tr = Tr;
Table.Td = Td;

export { Table };

export default Table;
