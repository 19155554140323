// import { UseMutationResult } from '@tanstack/react-query';
// import { useState } from 'react';
import { openBillingPortal } from '../../service/api/payments';

import { subscriptionPlans } from '@/assets/mock/subscription-plans';
import { useAuth } from '@/context/useAuth';
import { notifyError, notifyInfo } from '@/helper/notifications';

// import {
// 	CustomerDto,
// 	createPaymentSessionDto,
// } from '@/interface/payment.interfaces';
// import { SuscriberPlanEnum } from '@/interface/subscription.interfaces';
import checkedBoxRedIcon from '@assets/svg/checked-red-icon.svg';

// interface PaymentWallComponentProps {
// 	createPaymentSessionMutation: UseMutationResult<
// 		{ sessionUrl: string },
// 		unknown,
// 		createPaymentSessionDto
// 	>;
// 	customerDto: CustomerDto;
// }

export const PaymentWallComponent = () => {
	// const { isPending } = createPaymentSessionMutation;
	// const [selectedPlan, setSelectedPlan] = useState('');
	const { user } = useAuth();
	const activePlan = user?.subscription?.activePlan;

	const openBillingPortalAndNavigate = async () => {
		try {
			const { url } = await openBillingPortal();
			window.open(url, '_blank', 'noopener,noreferrer');
		} catch (error) {
			notifyError(`${error}`);
		}
	};

	// const handleSelectPlan = (
	// 	suscriberPlan: SuscriberPlanEnum,
	// 	selectedPlan: string,
	// ) => {
	// 	setSelectedPlan(selectedPlan);
	// 	createPaymentSessionMutation.mutate({
	// 		customerDto,
	// 		suscriberPlan,
	// 	});
	// };

	return (
		<>
			{subscriptionPlans.map((plan) => {
				// const suscriberPlan =
				// 	plan.param === 'essential'
				// 		? SuscriberPlanEnum.ESSENTIAL
				// 		: SuscriberPlanEnum.PRO;

				return (
					<div
						data-cy={`${plan.param}-plan-card`}
						key={plan.name}
						className="relative flex flex-col w-[300px] h-[500px] rounded-xl p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10 bg-white"
					>
						{((!activePlan && plan.name == 'FREE') ||
							activePlan === plan.param) && (
							<div className="absolute -top-2 -right-2 px-2 py-4 rounded-full bg-green-500 ring-1 ring-white text-white">
								Active
							</div>
						)}

						<div className="flex flex-col gap-x-4 text-brandRedStrong border-b border-x-gray-400 pb-3">
							<h3 className="text-base font-semibold leading-7">{plan.name}</h3>
							<div className="flex items-baseline gap-x-2">
								<span className="text-5xl font-bold tracking-tight">
									{plan.price}
								</span>
							</div>
							{plan.name == 'FREE' ? (
								<p className="text-sm text-[#6a0136]">Free forever</p>
							) : (
								<p className="text-sm text-[#6a0136]">
									{plan.param == 'essential' ? 'Per month' : 'Per year'}
								</p>
							)}
						</div>

						<ul role="list" className="mt-6 space-y-3 text-sm leading-6">
							{plan.features.map((feature, index) => (
								<li
									key={index}
									className="flex gap-x-3 text-[#6a0136] font-normal text-[18px] leading-6"
									data-cy={`${plan.param}-plan-feature`}
								>
									<img src={checkedBoxRedIcon} alt="checked-icon" />
									{feature}
								</li>
							))}
						</ul>

						{activePlan === plan.param ? (
							<button
								data-cy={`${plan.param}-plan-button`}
								className="block rounded-xl bg-brandRedStrong px-3.5 py-3 mt-8 text-center text-white font-semibold leading-6 shadow-sm border border-white "
								onClick={openBillingPortalAndNavigate}
							>
								Unsubscribe
							</button>
						) : (
							(plan.param === 'essential' && (
								<button
									data-cy={`${plan.param}-plan-button`}
									className={`${
										plan.name === 'Basic' && 'hidden'
									}block rounded-xl bg-brandRedStrong px-3.5 py-3 mt-8 text-center text-white font-semibold leading-6 shadow-sm border border-white `}
									// Temporarily block upgrade - 23 Oct 2024
									// onClick={() => handleSelectPlan(suscriberPlan, plan.param)}
									onClick={() => notifyInfo('The pro plan is coming soon')}
								>
									Coming soon
								</button>
							)) ||
							(plan.param === 'pro' && (
								<button
									data-cy={`${plan.param}-plan-button`}
									className={`${
										plan.name === 'Basic' && 'hidden'
									} block rounded-xl bg-brandRedStrong px-3.5 py-3 mt-8 text-center text-white font-semibold leading-6 shadow-sm border border-white `}
									onClick={() => notifyInfo('The pro plan is coming soon')}
								>
									Coming soon
								</button>
							))
						)}
					</div>
				);
			})}
		</>
	);
};
