import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { removeRecipeFromCollection } from '@/service/api/collection';

interface RemoveRecipeFromCollectionData {
	collectionId: number;
	recipeId: number;
}

export const useRemoveRecipeFromCollection = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation<void, Error, RemoveRecipeFromCollectionData>({
		mutationFn: async ({
			collectionId,
			recipeId,
		}: RemoveRecipeFromCollectionData) => {
			return removeRecipeFromCollection(collectionId, recipeId);
		},
		onError: (error) => {
			notifyError(error.message);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['collection'] });
		},
		onSuccess: () => {
			notifySuccess('Recipe removed from collection successfully');
		},
	});

	return mutation;
};
