import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { NavigationButtons } from './buttons/navigation-button/NavigationButtons';

import checkedIcon from '@/assets/svg/checked-black-icon.svg';
import chevronDownBlackIcon from '@/assets/svg/chevron-down-black.svg';

interface DropdownMenuProps<T> {
	options: T[];
	selectedValues: T[];
	label: string;
	onChange: (value: T) => void;
	renderItem: (item: T) => React.ReactNode;
	formStyle?: boolean;
	currentPage: number;
	totalPages: number;
	hasMore: boolean;
	onHandleNext: () => void;
	onHandlePrevious: () => void;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

function DropdownMenuComingSoon<T>({
	options,
	selectedValues,
	label,
	onChange,
	renderItem,
	formStyle,
	currentPage,
	totalPages,
	hasMore,
	onHandleNext,
	onHandlePrevious,
}: DropdownMenuProps<T>) {
	const handleItemClick = (item: T) => {
		onChange(item);
	};

	return (
		<Menu as="div" className="relative inline-block text-left w-full">
			<div className="flex flex-col items-center w-full">
				<div
					className={`relative w-full flex justify-center rounded-3xl bg-slate-50 hover:bg-gray-200 border-none ring-none shadow-sm ${
						formStyle && 'w-2/3 lg:w-3/4 2xl:w-2/3 bg-transparent shadow-none'
					}`}
				>
					<div className="absolute left-5 top-2.5 text-[9px] text-loginTextGrey">
						<label htmlFor={label}>{label}</label>
					</div>
					<Menu.Button
						data-cy={`button-${label.toLowerCase().replace(/ /g, '-')}-menu`}
						className="w-full inline-flex justify-between gap-x-1.5 pl-1 pr-3 px-6 pt-1"
					>
						{!formStyle ? (
							<span className="block pl-4 pt-5 pb-1 text-left text-sm font-semibold text-black">
								{label}
							</span>
						) : (
							<span className="pl-3.5 pt-4">
								{selectedValues.length > 0
									? renderItem(selectedValues[0])
									: label}
							</span>
						)}
						<img src={chevronDownBlackIcon} className="mt-1" />
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute w-[90%] max-h-52 lg:w-[80%] z-10 mt-14 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black bg-slate-100 border-none overflow-y-auto global-scrollbar">
						<div className="py-1 mb-4">
							{options?.map((option, index) => (
								<Menu.Item key={index}>
									{({ active }) => (
										<div
											data-cy={`update-${label
												.toLowerCase()
												.replace(/ /g, '-')}-${String(option)
												.toLowerCase()
												.replace(' ', '-')}`}
											className={classNames(
												active ? 'bg-gray-300/50' : '',
												'flex items-center pl-2 cursor-pointer',
												selectedValues.includes(option) ? 'opacity-50' : '',
											)}
											onClick={() => handleItemClick(option)}
										>
											{renderItem(option)}
											{selectedValues.includes(option) && (
												<img
													src={checkedIcon}
													alt="checked"
													className="ml-2 mt-[2px] w-3 h-3"
												/>
											)}
										</div>
									)}
								</Menu.Item>
							))}

							{hasMore && (
								<NavigationButtons
									currentPage={currentPage}
									totalPages={totalPages}
									handleNextClick={onHandleNext}
									handlePrevClick={onHandlePrevious}
								/>
							)}
						</div>
					</Menu.Items>
				</Transition>
			</div>
		</Menu>
	);
}

export default DropdownMenuComingSoon;
