import { useEffect, useRef } from 'react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import {
	collectionsCardsConfig,
	smallCardsConfig,
} from '@/components/common/slider/constants';
import { ISliderProps } from '@/interface/slider-props.interface';

export const Slider = ({ children, cardsType }: ISliderProps) => {
	const swiperRef = useRef<SwiperType | null>(null);

	useEffect(() => {
		const swiperInstance = swiperRef.current?.el;
		const handleMouseEnter = () => swiperRef.current?.autoplay.stop();
		const handleMouseLeave = () => swiperRef.current?.autoplay.start();

		if (swiperInstance) {
			swiperInstance.addEventListener('mouseenter', handleMouseEnter);
			swiperInstance.addEventListener('mouseleave', handleMouseLeave);

			return () => {
				swiperInstance.removeEventListener('mouseenter', handleMouseEnter);
				swiperInstance.removeEventListener('mouseleave', handleMouseLeave);
			};
		}
	}, []);

	return (
		<div className="w-[95vw] lg:w-[85vw]">
			<Swiper
				breakpoints={
					cardsType.type === 'COLLECTIONS'
						? collectionsCardsConfig
						: smallCardsConfig
				}
				spaceBetween={10}
				modules={[Autoplay, Navigation]}
				autoplay={{ delay: 4000 }}
				pagination={{
					clickable: true,
				}}
				onSwiper={(swiper) => {
					swiperRef.current = swiper;
				}}
				style={{
					paddingBottom: '35px',
					paddingTop: '35px',
				}}
			>
				{children}
			</Swiper>
		</div>
	);
};
