import { useState } from 'react';

import { PopUp } from '../PopUp';

import { IIngredient } from '@/interface/ingredient.interfaces';

const mockIngredients = [
	'Ingredient 1',
	'Ingredient 2',
	'Ingredient 3',
	'Ingredient 4',
	'Ingredient 5',
];

export const IngredientsComponent = ({
	ingredients,
	blockRecipeDetails,
}: {
	ingredients: IIngredient[];
	blockRecipeDetails?: boolean;
}) => {
	const [openIngredientsModal, setOpenIngredientsModal] = useState(false);
	const handleIngredientsModal = () =>
		setOpenIngredientsModal(!openIngredientsModal);
	const displayedIngredients = ingredients.slice(0, 3);

	if (blockRecipeDetails) {
		return (
			<div className="w-full">
				<h4 className="text-[26px] leading-[28.6px] tracking-[0.02em] font-semibold  mb-2">
					Ingredients:
				</h4>
				<div className="flex flex-wrap gap-2 md:gap-4">
					{mockIngredients.map((ingredient, index) => (
						<div
							data-cy="detail-page-recipe-ingredients"
							key={index}
							className="flex flex-col grow items-center gap-y-2 bg-slate-200 buttons-shape"
						>
							<div className="flex flex-wrap justify-center">
								<span className="mr-1">{`${ingredient}`}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="w-full">
			<h4 className="text-[26px] leading-[28.6px] tracking-[0.02em] font-semibold  mb-2">
				Ingredients:
			</h4>
			<div className="hidden md:flex flex-wrap gap-4">
				{ingredients.map((ingredient) => (
					<div
						data-cy="detail-page-recipe-ingredients"
						key={ingredient.id}
						className="flex flex-col grow items-center gap-y-2 bg-slate-200 buttons-shape"
					>
						<div className="flex flex-wrap justify-center">
							<span className="mr-1">{`${ingredient.quantity} (${ingredient.unit}) ${ingredient.name}`}</span>
						</div>
					</div>
				))}
			</div>
			<div className="flex flex-wrap gap-4 md:hidden">
				{displayedIngredients.map((ingredient) => (
					<div
						key={ingredient.id}
						className="flex flex-col grow items-center gap-y-2 bg-slate-200 buttons-shape"
					>
						<div className="flex flex-wrap justify-center">
							<span className="mr-1">{`${ingredient.quantity} (${ingredient.unit}) ${ingredient.name}`}</span>
						</div>
					</div>
				))}
				{ingredients.length > 3 && (
					<div className="flex items-center justify-center mt-4">
						<button
							onClick={handleIngredientsModal}
							className="p-2 bg-brandGreen text-white rounded"
						>
							Show All Ingredients
						</button>
					</div>
				)}
			</div>

			{openIngredientsModal && (
				<PopUp open={openIngredientsModal} onClose={handleIngredientsModal}>
					<div className="block flex-col gap-y-2 p-6">
						{ingredients?.map((ingredient) => (
							<div
								data-cy="popup-ingredient"
								key={ingredient.id}
								className="flex flex-col grow items-center gap-y-2 bg-slate-200 buttons-shape"
							>
								<div className="flex flex-wrap justify-center">
									<span className="mr-1">{`${ingredient.quantity} (${ingredient.unit}) ${ingredient.name}`}</span>
								</div>
							</div>
						))}
						<div className="flex items-center justify-center mt-4">
							<button
								onClick={handleIngredientsModal}
								className="p-2 bg-brandRed rounded text-white"
							>
								Show Less
							</button>
						</div>
					</div>
				</PopUp>
			)}
		</div>
	);
};
