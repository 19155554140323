import { Form, Formik } from 'formik';

import { Loader } from '@/components/common/Loader';
import { FormField } from '@/pages/forgot-password/FormField';
import { emailValidationSchema } from '@/pages/forgot-password/validationSchemas/EmailValidation.schema';

interface ResendVerificationFormValues {
	email: string;
}

const resendVerificationFormField = {
	label: 'Email',
	id: 'email',
	name: 'email',
	type: 'email',
	placeholder: 'Enter your email',
};

interface ResendVerificationFormProps {
	onSubmit: (values: ResendVerificationFormValues) => Promise<void>;
	isLoading: boolean;
}

export const ResendVerificationForm = ({
	onSubmit,
	isLoading,
}: ResendVerificationFormProps) => {
	return (
		<Formik
			enableReinitialize
			initialValues={{ email: '' }}
			validationSchema={emailValidationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<FormField
						{...resendVerificationFormField}
						disabled={isLoading || isSubmitting}
					/>
					<div className="flex justify-around gap-2">
						<button
							data-cy="resend-verification-form-button"
							type="submit"
							className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-center items-center text-[14px]"
							disabled={isLoading || isSubmitting}
							style={{ width: '120px', height: '40px' }}
						>
							{isLoading || isSubmitting ? <Loader size={1.5} /> : 'Send code'}
						</button>
						<span
							className="text-gray-900 text-[12px] text-center self-center"
							data-cy="verification-form-message"
						>
							If the code is not received, make sure your account was previously
							created.
						</span>
					</div>
				</Form>
			)}
		</Formik>
	);
};
