import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { putSubscription } from '@/service/api/payments';

export const usePutFreeSubscription = () => {
	const { mutate, isPending } = useMutation({
		mutationFn: putSubscription,
		onSuccess: () => {
			notifySuccess('Subscription successfully granted');
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { mutate, isPending };
};
