import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

const ErrorBoundaryNoAuthWrapper = ({
	noAuthRoutes,
}: {
	noAuthRoutes?: boolean;
}) => (
	<ErrorBoundary
		fallbackRender={() => <ErrorBoundaryFallback noAuthRoutes={noAuthRoutes} />}
	>
		<Outlet />
	</ErrorBoundary>
);

export default ErrorBoundaryNoAuthWrapper;
