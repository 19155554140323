import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { createChef } from '@/service/api/chef';

const useCreateChef = (closeModal: () => void) => {
	const queryClient = useQueryClient();

	const createChefMutation = useMutation({
		mutationFn: createChef,
		onSuccess: (chef, user) => {
			queryClient.invalidateQueries({ queryKey: ['chefs'] });
			queryClient.invalidateQueries({
				queryKey: ['userByEmail', user.email],
			});
			queryClient.setQueryData(['userByEmail', user.email], { ...user, chef });
			closeModal();
			notifySuccess('Creator created successfully');
		},
		onError: () => notifyError('Creator creation failed'),
	});

	return {
		createChefMutation,
	};
};

export default useCreateChef;
