import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserPool,
	CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { User } from '@/context/interfaces';
import { AuthData } from '@/interface/cognito.interfaces';
import { getUser } from '@/service/api/user';

const userPool = new CognitoUserPool({
	UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID ?? 'local_6aCjukq',
	ClientId:
		import.meta.env.VITE_COGNITO_CLIENT_ID ?? '2v08h8po0xm5f6ccadp6lirxg',
	endpoint: import.meta.env.VITE_COGNITO_ENDPOINT ?? 'http://localhost:9229/',
});

export const authenticateUser = async ({
	email,
	password,
}: AuthData): Promise<User | undefined> => {
	const userData = {
		Username: email,
		Pool: userPool,
	};

	const authenticationDetails = new AuthenticationDetails({
		Username: email,
		Password: password,
	});

	const userCognito = new CognitoUser(userData);
	userCognito.setAuthenticationFlowType('USER_PASSWORD_AUTH');

	const result = await new Promise<CognitoUserSession>((resolve, reject) => {
		userCognito.authenticateUser(authenticationDetails, {
			onSuccess: resolve,
			onFailure: reject,
			newPasswordRequired: () => {
				if (!password) {
					return reject(
						new Error('New password is required to complete authentication.'),
					);
				}

				userCognito.completeNewPasswordChallenge(
					password,
					{},
					{
						onSuccess: resolve,
						onFailure: reject,
					},
				);
			},
		});
	});

	return getUser(result.getAccessToken().getJwtToken());
};

export const confirmNewPassword = async (
	newPassword: string,
	code: string,
	email: string,
): Promise<void> => {
	const cognitoUser = new CognitoUser({
		Username: email,
		Pool: userPool,
	});
	return new Promise((resolve, reject) => {
		cognitoUser.confirmPassword(code, newPassword, {
			onSuccess() {
				resolve();
			},
			onFailure(err) {
				reject(err);
			},
		});
	});
};

export const passwordRecovery = async (email: string) => {
	const cognitoUser = new CognitoUser({
		Username: email,
		Pool: userPool,
	});

	return new Promise<void>((resolve, reject) => {
		cognitoUser.forgotPassword({
			onSuccess: function (data) {
				resolve(data);
			},
			onFailure: function (err) {
				reject(err);
			},
		});
	});
};

export const logoutUser = async (): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const cognitoUser = userPool.getCurrentUser();
		if (cognitoUser === null) return reject(new Error('No user found'));

		cognitoUser.signOut();
		resolve();
	});
};

export const refreshUserToken = async (): Promise<{
	session: CognitoUserSession | null;
}> => {
	return new Promise((resolve, reject) => {
		const cognitoUser = userPool.getCurrentUser();
		if (!cognitoUser) {
			resolve({ session: null });
			return;
		}

		cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
			if (err) return reject(err);

			resolve({ session: session as CognitoUserSession });
		});
	});
};

export const changePassword = async (
	email: string,
	oldPassword: string,
	newPassword: string,
): Promise<void> => {
	const userData = {
		Username: email,
		Pool: userPool,
	};

	const authenticationDetails = new AuthenticationDetails({
		Username: email,
		Password: oldPassword,
	});

	const userCognito = new CognitoUser(userData);
	userCognito.setAuthenticationFlowType('USER_PASSWORD_AUTH');

	return new Promise<void>((resolve, reject) => {
		userCognito.authenticateUser(authenticationDetails, {
			onSuccess: function () {
				userCognito.changePassword(oldPassword, newPassword, (err) => {
					if (err) {
						reject(err);
					} else {
						resolve();
					}
				});
			},
			onFailure: function (err) {
				reject(err);
			},
		});
	});
};

export const resendConfirmationCode = async (
	email: string,
): Promise<{ success: boolean; message: string }> => {
	const userData = {
		Username: email,
		Pool: userPool,
	};

	const userCognito = new CognitoUser(userData);
	return new Promise((resolve, reject) => {
		userCognito.resendConfirmationCode((err) => {
			if (err) {
				return reject(err);
			}
			return resolve({
				success: true,
				message: 'A new confirmation code has been sent',
			});
		});
	});
};
