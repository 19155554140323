import checkBoxIcon from '@/assets/svg/check-box-icon.svg';
import checkedBoxIcon from '@/assets/svg/checked-box-icon.svg';

interface SelectableListProps<T> {
	items: T[];
	renderItem: (item: T) => React.ReactNode;
	selectedItems: Set<number>;
	setSelectedItems: React.Dispatch<React.SetStateAction<Set<number>>>;
	onItemClick: (item: T) => void;
}

export function SelectableList<T>({
	items,
	renderItem,
	selectedItems,
	setSelectedItems,
	onItemClick,
}: SelectableListProps<T>) {
	const handleItemClick = (id: number, item: T) => {
		const newSelectedItems = new Set(selectedItems);
		if (newSelectedItems.has(id)) {
			newSelectedItems.delete(id);
		} else {
			newSelectedItems.add(id);
		}
		setSelectedItems(newSelectedItems);
		onItemClick(item);
	};

	return (
		<div>
			<ul className="pr-20">
				{items.map((item, index) => (
					<li key={index} className="flex items-center pr-20">
						<div
							data-cy={`update-${String(item)
								.toLowerCase()
								.replace(' ', '-')}-checklist`}
							className={`cursor-pointer mr-2 ${
								selectedItems.has(index) ? 'text-brandOrange' : ''
							}`}
							onClick={() => handleItemClick(index, item)}
						>
							<img
								src={selectedItems.has(index) ? checkedBoxIcon : checkBoxIcon}
								alt={selectedItems.has(index) ? 'Checked' : 'Unchecked'}
								className="w-6 h-6"
							/>
						</div>
						<span
							className={`${
								selectedItems.has(index) ? 'text-brandOrange' : ''
							}`}
						>
							{renderItem(item)}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
}
