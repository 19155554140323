import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import { COLLECTION_ERRORS_MESSAGES } from '@/error/collection-errors.enum';
import {
	ICollection,
	INewCollectionDto,
	IUpdateCollectionDto,
} from '@/interface/collection.interfaces';
import { IGetAllResponse } from '@/interface/get-all-response';
import { IPagination } from '@/interface/pagination.interface';
import Api from '@/service';

const RESOURCE = 'collection';

export const createCollection = async (
	newCollection: INewCollectionDto,
): Promise<ICollection> => {
	try {
		const { data } = await Api.post(`/${RESOURCE}`, newCollection);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(COLLECTION_ERRORS_MESSAGES.CREATE_COLLECTION_ERROR);
	}
};

export const getCollectionsByUser = async (
	options: IPagination,
): Promise<IGetAllResponse<ICollection>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<ICollection>>(
			`/${RESOURCE}/user/me`,
			{
				params: {
					take: options.take,
					skip: options.skip,
				},
			},
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(COLLECTION_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(COLLECTION_ERRORS_MESSAGES.GET_ALL_COLLECTION_ERROR);
	}
};

export const getAllCollectionsByUser = async ({
	userId,
	options,
}: {
	userId: number;
	options: IPagination;
}): Promise<IGetAllResponse<ICollection>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<ICollection>>(
			`/${RESOURCE}/user/${userId}`,
			{
				params: {
					take: options.take,
					skip: options.skip,
				},
			},
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(COLLECTION_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(COLLECTION_ERRORS_MESSAGES.GET_ALL_COLLECTION_ERROR);
	}
};

export const getCollectionByUuid = async (
	uuid: string,
): Promise<ICollection> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/uuid/${uuid}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(COLLECTION_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(COLLECTION_ERRORS_MESSAGES.GET_COLLECTION_BY_ID_ERROR);
	}
};

export const updateCollection = async ({
	id,
	title,
}: IUpdateCollectionDto): Promise<ICollection> => {
	try {
		const { data } = await Api.patch(`/${RESOURCE}/${id}`, { title });
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			COLLECTION_ERRORS_MESSAGES.UPDATE_COLLECTION_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const deleteCollection = async (id: number): Promise<void> => {
	try {
		await Api.delete(`/${RESOURCE}/${id}`);
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			COLLECTION_ERRORS_MESSAGES.DELETE_COLLECTION_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const addRecipeToCollection = async (
	collectionId: number,
	recipeId: number,
): Promise<ICollection> => {
	try {
		const response = await Api.post(
			`/collection/${collectionId}/recipe/${recipeId}`,
		);
		return response.data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			COLLECTION_ERRORS_MESSAGES.ADD_RECIPE_TO_COLLECTION_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const removeRecipeFromCollection = async (
	collectionId: number,
	recipeId: number,
): Promise<void> => {
	try {
		await Api.delete(`/${RESOURCE}/${collectionId}/recipe/${recipeId}`);
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			COLLECTION_ERRORS_MESSAGES.REMOVE_RECIPE_FROM_COLLECTION_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};
