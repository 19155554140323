import { useNavigate } from 'react-router-dom';

import { FollowButton } from '../buttons/FollowButton';

import instagramIcon from '@/assets/svg/instagram-icon.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { CdnImage } from '@/components/images/CdnImage';
import { Chef } from '@/context/interfaces';

interface ChefPresentationComponentProps {
	chef?: Chef;
	instagramLink: string;
	profile_image?: string;
}

export const ChefPresentationComponent = ({
	chef,
	instagramLink,
	profile_image,
}: ChefPresentationComponentProps) => {
	const navigate = useNavigate();

	return (
		<div
			data-cy="detail-page-chef-presentation"
			className="flex items-center gap-2 flex-wrap"
		>
			<div
				onClick={() => navigate(`/chefs/${chef?.uuid}`)}
				className="cursor-pointer flex justify-center  gap-2.5"
			>
				<CdnImage
					src={profile_image || userBlackIcon}
					alt="avatar"
					className="w-12 h-12 rounded-full object-cover"
				/>
				<div className="flex flex-col leading-3 h-auto  justify-around">
					<span className="text-xs text-slate-400 self-start">Recipe by</span>
					<span className="font-semibold font-roboto ">
						{chef?.user.name} {chef?.user.last_name}
					</span>
				</div>
			</div>
			<div className="flex justify-center items-center gap-2">
				<FollowButton chef={chef} />
				<div className="flex gap-x-4 ">
					<img
						src={instagramIcon}
						alt="instagram-icon"
						className=" hover:cursor-pointer"
						onClick={() => window.open(instagramLink, '_blank')}
					/>
				</div>
			</div>
		</div>
	);
};
