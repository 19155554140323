import { useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import pauseIcon from '@/assets/svg/pause-icon.svg';
import playIcon from '@/assets/svg/play-icon.svg';
import { InstagramPost } from '@/interface/instagram.interfaces';

interface InstagramPendingPostProps {
	post: InstagramPost;
	onGtpInstagramPostCheckMutation: (prompt: InstagramPost) => void;
}

const renderMedia = (
	mediaType: string,
	mediaUrl: string,
	play: boolean,
	handlePlayToggle: () => void,
) => {
	if (mediaType === 'IMAGE') {
		return (
			<img
				src={mediaUrl}
				alt="instagram_image"
				className="w-[300px] min-[430px]:w-96 h-96 rounded-3xl"
			/>
		);
	} else if (mediaType === 'VIDEO') {
		return (
			<div className="relative">
				<div className="rounded-3xl overflow-hidden w-[300px] min-[430px]:w-96">
					<ReactPlayer
						url={mediaUrl}
						playing={play}
						loop={play}
						width="100%"
						height="384px"
						style={{ objectFit: 'fill' }}
						muted
						playsinline
						className="video-player"
						controls={false}
					/>
				</div>
				<button
					onClick={handlePlayToggle}
					className="flex items-center justify-center absolute bottom-4 right-4 h-[40px] w-[40px] bg-white rounded-full shadow-black shadow-inner hover:bg-slate-200"
				>
					<img
						src={play ? pauseIcon : playIcon}
						alt={play ? 'pause-icon' : 'play-icon'}
					/>
				</button>
			</div>
		);
	}
	return null;
};

export const InstagramPendingPost = ({
	post,
	onGtpInstagramPostCheckMutation,
}: InstagramPendingPostProps) => {
	const [play, setPlay] = useState(false);
	const handlePlayToggle = () => setPlay(!play);

	return (
		<div
			data-cy="instagram-pending-post"
			className="flex flex-col items-center w-[300px] min-[430px]:w-96 h-[730px] gap-4 "
		>
			{renderMedia(post.media_type, post.media_url, play, handlePlayToggle)}
			<div className="flex flex-col grow gap-y-6 w-[300px] min-[430px]:w-[364px]">
				<div>
					<p className="text-justify line-clamp-[8]">{post.caption}</p>
				</div>
				<div className="flex-1"></div>
				<div className="flex flex-col gap-y-6">
					<Link to={post.permalink} className="text-brandOrange place-self-end">
						Link to the original post
					</Link>
					<button
						onClick={() => onGtpInstagramPostCheckMutation(post)}
						className="flex-1 py-2 px-4 text-white rounded-xl bg-brandOrange transition-colors hover:bg-[#E1B864]"
					>
						Convert to a Kitched recipe
					</button>
				</div>
			</div>
		</div>
	);
};
