import { useEffect, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import { useLocation } from 'react-router-dom';

import { searchBarTags } from '@/assets/mock/tags_menu_items.mock';

interface AlgoliaSearchBarProps {
	setFilter: (filter: string) => void;
	filter: string;
	onSearch: (query: string) => void;
	setClearAll: (clearAll: boolean) => void;
	clearAll: boolean;
}

export const AlgoliaSearchBar = ({
	setFilter,
	filter,
	onSearch,
	setClearAll,
	clearAll,
}: AlgoliaSearchBarProps) => {
	const [searchBarTagsState, setSearchBarTagsState] = useState(searchBarTags);
	const { clear } = useSearchBox();

	const location = useLocation();
	const params = new URLSearchParams(location.search);

	useEffect(() => {
		const initialTags = searchBarTagsState.map((tag) => ({
			...tag,
			isActive: params.get('searchTag') === tag.param,
		}));
		setSearchBarTagsState(initialTags);
	}, [location.search]);

	const onSetSearchBarTag = (param: string, id: number) => {
		clearAll && setClearAll(false);
		setSearchBarTagsState((prevTags) =>
			prevTags.map((tag) =>
				tag.id === id
					? { ...tag, isActive: true }
					: { ...tag, isActive: false },
			),
		);
		onSearch(param);
	};

	const onClearSearchBarTags = () => {
		setSearchBarTagsState((prevTags) =>
			prevTags.map((tag) => ({ ...tag, isActive: false })),
		);

		onSearch('');
		setFilter('');
		clear();
	};

	const onHandleFilter = () => {
		if (filter === 'isFree:true') {
			setFilter('');
		} else {
			setFilter('isFree:true');
		}
	};

	return (
		<div
			data-cy="tag-search-bar"
			className="flex flex-wrap gap-2 justify-center md:justify-start"
		>
			<button
				className="block grow sm:grow-0  text-sm text-slate-700 bg-slate-100 buttons-shape border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
				onClick={() => onHandleFilter()}
				style={{
					backgroundColor:
						filter === 'isFree:true' ? 'rgb(232 201 136 / 0.1)' : '',
					borderColor: filter === 'isFree:true' ? 'rgb(232 201 136)' : '',
					color: filter === 'isFree:true' ? 'rgb(232 201 136)' : '',
				}}
			>
				Free
			</button>
			{searchBarTagsState.map((tag) => (
				<button
					data-cy="algolia-bar-sort-item"
					key={tag.id}
					className="block grow sm:grow-0 px-4 py-2.5 text-sm text-slate-700 bg-slate-100 rounded-3xl border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
					onClick={() => onSetSearchBarTag(tag.param, tag.id)}
					style={{
						backgroundColor: tag.isActive ? 'rgb(232 201 136 / 0.1)' : '',
						borderColor: tag.isActive ? 'rgb(232 201 136)' : '',
						color: tag.isActive ? 'rgb(232 201 136)' : '',
					}}
				>
					{tag.name}
				</button>
			))}
			<button
				className="block grow sm:grow-0 px-4 py-2.5 text-sm text-green-700 bg-green-100 rounded-3xl border-[1px] border-green-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
				onClick={() => {
					setClearAll(true);
					onClearSearchBarTags();
				}}
			>
				Clear all
			</button>
		</div>
	);
};
