export enum RecipeDifficulty {
	EASY = 'easy',
	MEDIUM = 'medium',
	HARD = 'hard',
}

export enum RecipeServings {
	ONE = '1',
	TWO = '2',
	THREE = '3',
	FOUR = '4',
	FIVE = '5',
	SIX = '6',
	SEVEN = '7',
	MORE_THAN_SEVEN = '>7',
}

export enum RecipeTime {
	FIFTY_MIN = '15',
	THIRTY_MIN = '30',
	FORTYFIVE_MIN = '45',
	ONE_HOUR = '60',
	ONE_HALF_HOUR = '90',
	TWO_HOUR = '120',
	MORE_THAN_TWO_HOUR = '>120',
}
