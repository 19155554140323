import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { deleteSubscription } from '@/service/api/payments';

export const useDeleteFreeSubscription = () => {
	const { mutate, isPending } = useMutation({
		mutationFn: deleteSubscription,
		onSuccess: () => {
			notifySuccess('Subscription successfully removed');
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { mutate, isPending };
};
