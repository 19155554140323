import { chefSortItems } from '@/assets/mock/chef_sort_items.mock';
import { ChefSort } from '@/interface/filter.interface';

interface ChefSortBarProps {
	sort: ChefSort;
	setSort: (sort: ChefSort) => void;
}
export const ChefSortBar = ({ sort, setSort }: ChefSortBarProps) => {
	return (
		<div className="flex flex-wrap gap-x-4 gap-y-2">
			{chefSortItems.map((item) => {
				const isActive = item.sort === sort;
				return (
					<button
						data-cy="chef-sort-item"
						key={item.id}
						className="block grow sm:grow-0 buttons-shape text-sm text-slate-700 bg-slate-100  border-[1px] border-slate-300 hover:text-brandRed hover:border-brandRed hover:bg-brandRed/10"
						onClick={() => setSort(item.sort)}
						style={{
							backgroundColor: isActive ? 'rgb(203 52 51 / 0.1)' : '',
							borderColor: isActive ? 'rgb(203 52 51)' : '',
							color: isActive ? 'rgb(203 52 51)' : '',
						}}
					>
						{item.name}
					</button>
				);
			})}
		</div>
	);
};
