import { useNavigate } from 'react-router-dom';

export const PaymentCancelledPage = () => {
	const navigate = useNavigate();
	return (
		<div className="w-full h-[70vh] grid place-content-center">
			<div className="flex flex-col items-center gap-y-10 px-4 py-8 mx-2 bg-red-500 rounded-3xl shadow-xl ring-1 ring-gray-900/10">
				<h2
					data-cy="payment-cancel-page-title"
					className="text-2xl sm:text-3xl text-white text-center"
				>
					The payment was not processed
				</h2>
				<div className="w-full flex justify-center gap-4">
					<button
						data-cy="payment-cancel-page-try-again-button"
						className="w-32 py-2 bg-slate-100 text-slate-600 rounded-3xl"
						onClick={() => navigate('/subscriptions')}
					>
						Try again
					</button>
					<button
						data-cy="payment-cancel-page-home-button"
						className="w-32 py-2 bg-slate-100 text-slate-600 rounded-3xl"
						onClick={() => navigate('/home')}
					>
						Home
					</button>
				</div>
			</div>
		</div>
	);
};
