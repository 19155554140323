import { useState } from 'react';

import hidePasswordImg from '@/assets/svg/hide-password.svg';
import showPasswordImg from '@/assets/svg/show-password.svg';

interface InputPasswordProps {
	placeholder: string;
	label: string;
	name: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	value: string;
	error?: string;
}

export const InputPassword = ({
	placeholder,
	label,
	name,
	onChange,
	onBlur,
	value,
	error,
}: InputPasswordProps) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="relative">
			<div className="flex">
				<input
					type={showPassword ? 'text' : 'password'}
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50 pr-10"
				/>
				<button
					type="button"
					onClick={() => setShowPassword(!showPassword)}
					className="absolute right-3 top-1/2 transform -translate-y-1/2"
				>
					<img
						src={showPassword ? showPasswordImg : hidePasswordImg}
						alt="password-icon"
						className="w-[20px] h-[20px]"
					/>
				</button>
				<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
					<label htmlFor={name}>{label}</label>
				</div>
			</div>
			<div className="text-alertRed pl-6 text-[9px] mt-1">
				{error && <div data-cy="password-error">{error}</div>}
			</div>
		</div>
	);
};
