import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { IPagination } from '@/interface/pagination.interface';
import { getUserSavedRecipes } from '@/service/api/user';

export const useGetUserSavedRecipes = (options: IPagination) => {
	const location = useLocation();
	const isProfile =
		location.pathname.includes('/user/me') ||
		location.pathname.includes('/chef/profile');

	const {
		data: userSavedRecipes,
		isLoading: isLoadingUserSavedRecipes,
		isError: isErrorUserSavedRecipes,
		error: errorUserSavedRecipes,
		refetch: refetchUserSavedRecipes,
	} = useQuery({
		queryKey: ['user-saved-recipes', options.take, options.skip],
		queryFn: () => getUserSavedRecipes(options),
		retry: false,
		enabled: isProfile,
	});

	return {
		userSavedRecipes: userSavedRecipes,
		refetchUserSavedRecipes: refetchUserSavedRecipes,
		totalUserSavedEntities: userSavedRecipes?.total as number,
		isLoadingUserSavedRecipes: isLoadingUserSavedRecipes,
		isErrorUserSavedRecipes: isErrorUserSavedRecipes,
		errorUserSavedRecipes: errorUserSavedRecipes,
	};
};
