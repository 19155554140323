import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export const initGA = (measurementId: string) => {
	ReactGA.initialize(measurementId);
};

export const initGTM = (gtmId: string) => {
	TagManager.initialize({ gtmId });
};

export const logPageView = () => {
	ReactGA.send({
		hitType: 'pageview',
		page: window.location.pathname + window.location.search,
	});
};

export const logEvent = (
	eventName: string,
	params?: Record<string, unknown>,
) => {
	ReactGA.event('event', { eventName, ...params });
};

declare global {
	interface Window {
		dataLayer: unknown[];
	}
}

export const logRecipeView = (
	recipeId: string,
	recipeTitle: string,
	chefFullName: string,
) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'recipe_view',
			recipe_id: recipeId,
			recipe_title: recipeTitle,
			chef_full_name: chefFullName,
		});
	}
};

export const logVideoPlay = (
	recipeId: string,
	recipeTitle: string,
	chefFullName: string,
) => {
	if (window.dataLayer) {
		const videoEvent = {
			event: 'video_play',
			recipe_id: recipeId,
			recipe_title: recipeTitle,
			chef_full_name: chefFullName,
		};
		window.dataLayer.push(videoEvent);
	}
};

export const logChefProfileView = (chefId: string, chefFullName: string) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'chef_profile_view',
			chef_id: chefId,
			chef_full_name: chefFullName,
		});
	}
};

export const logRecipeLike = (
	recipeId: string,
	recipeTitle: string,
	chefId: string,
	chefFullName: string,
) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'recipe_like',
			recipe_id: recipeId,
			recipe_title: recipeTitle,
			chef_id: chefId,
			chef_full_name: chefFullName,
		});
	}
};

export const logChefFollow = (chefId: string, chefFullName: string) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'chef_follow',
			chef_id: chefId,
			chef_full_name: chefFullName,
		});
	}
};
