import * as Yup from 'yup';

export const loginValidationSchema = () => {
	return Yup.object({
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		password: Yup.string().required('Password is required'),
		rememberMe: Yup.boolean(),
	});
};
