import { useState } from 'react';

import ChefTable from '@/pages/admin/chef/ChefTable';

export const ManageChefs = () => {
	const [view, setView] = useState('chefs');

	return (
		<div className="container mx-auto mb-20">
			<h2
				data-cy="manage-chef-header"
				className="text-3xl font-semibold mb-20 mt-12 mx-auto text-center"
			>
				Manage Creators
			</h2>

			<nav className="mb-10 text-center text-xs text-gray-700 uppercase">
				<button
					onClick={() => setView('chefs')}
					className="mx-4 px-6 py-3 text-lg bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-lg hover:text-white hover:bg-gray-600 transition-all duration-300"
				>
					Add Creators
				</button>
			</nav>

			{view === 'chefs' && <ChefTable />}
		</div>
	);
};
