import { PopUp } from '@/components/common/PopUp';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';

interface DeleteCollectionConfirmModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

export const DeleteCollectionConfirmModal = ({
	isOpen,
	onClose,
	onConfirm,
}: DeleteCollectionConfirmModalProps) => {
	return (
		<PopUp open={isOpen} onClose={onClose} topCss="20vh">
			<div className="flex flex-col gap-y-2 p-6 font-helveticaNeue">
				<p className="text-lg text-center text-slate-600 font-semibold my-4">
					Are you sure you want to delete this collection?
				</p>
				<ActionButtons
					buttonOneText="No"
					buttonTwoText="Yes"
					onButtonClickOne={onClose}
					onButtonClickTwo={onConfirm}
				/>
			</div>
		</PopUp>
	);
};
