import { useFormik } from 'formik';
import { useState } from 'react';

import emailIcon from '@/assets/svg/email-icon.svg';
import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { Title } from '@/components/common/Title/Title';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';
import { InputPassword } from '@/components/common/input-password/InputPassword';
import { User } from '@/context/interfaces';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { changepasswordValidationSchema } from '@/helper/validations/changepassword.validations';
import { changePassword } from '@/service/cognito/cognito.services';

interface ChangePasswordSettingsProps {
	user: User;
}

export default function ChangePasswordSettings({
	user,
}: ChangePasswordSettingsProps) {
	const validationSchema = changepasswordValidationSchema();
	const [openConfirmModal, setOpenConfirmModal] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: user?.email || '',
			password: '',
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setOpenConfirmModal(false);

				await changePassword(values.email, values.password, values.newPassword);
				notifySuccess('Password successfully changed');
			} catch (error: unknown) {
				if (error instanceof Error) notifyError(error.message);
				else notifyError('Unexpected error');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const handleConfirm = () => {
		formik.handleSubmit();
	};

	return (
		<div className="flex flex-col items-center rounded-3xl">
			<span className="text-center text-slate-400 ">Credentials</span>
			<Title textTitle={`Account`} />

			<form
				className="flex flex-col gap-12 h-[440px] mt-2 pt-5 "
				onSubmit={(e) => {
					e.preventDefault();
					setOpenConfirmModal(true);
				}}
			>
				<div className="flex flex-col gap-4 min-w-[300px]">
					<div className="relative">
						<input
							type="email"
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							{...formik.getFieldProps('email')}
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="email">EMAIL</label>
						</div>
						<img
							src={emailIcon}
							alt="email-icon"
							className=" absolute top-[18px] right-5"
						/>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.email && formik.errors.email && (
								<div>{formik.errors.email}</div>
							)}
						</div>
					</div>

					<InputPassword
						name="password"
						placeholder="Enter your current password"
						label="PASSWORD"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.password}
						error={
							formik.touched.password && formik.errors.password
								? formik.errors.password
								: ''
						}
					/>

					<span className="font-semibold ml-3">Change Password</span>

					<InputPassword
						name="newPassword"
						placeholder="Enter a new password"
						label="CHANGE PASSWORD"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.newPassword}
						error={
							formik.touched.newPassword && formik.errors.newPassword
								? formik.errors.newPassword
								: ''
						}
					/>
					<InputPassword
						name="confirmPassword"
						placeholder="Confirm password"
						label="CONFIRM PASSWORD"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.confirmPassword}
						error={
							formik.touched.confirmPassword && formik.errors.confirmPassword
								? formik.errors.confirmPassword
								: ''
						}
					/>

					<div>
						<button
							data-cy="change-password-submit"
							type="submit"
							className={`w-[156px] h-[52px] rounded-full bg-brandRed text-white flex justify-center items-center mx-auto transition-all ${
								formik.isValid && !formik.isSubmitting
									? 'hover:text-brandRed hover:bg-white hover:ring-2 hover:ring-brandRed hover:cursor-pointer'
									: 'cursor-not-allowed opacity-50'
							}`}
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Loader size={1.4} />
							) : (
								<span>Save changes</span>
							)}
						</button>
					</div>

					<PopUp
						open={openConfirmModal}
						onClose={() => setOpenConfirmModal(false)}
					>
						<div className="flex flex-col gap-y-2 p-6">
							<p className="text-lg text-center text-slate-600 font-semibold my-4">
								Are you sure you want to save the changes?
							</p>
							<ActionButtons
								buttonOneText="Yes"
								buttonTwoText="No"
								onButtonClickOne={handleConfirm}
								onButtonClickTwo={() => setOpenConfirmModal(false)}
							/>
						</div>
					</PopUp>
				</div>
			</form>
		</div>
	);
}
