import { useQuery } from '@tanstack/react-query';

import { getAllUsers } from '@/service/api/user';

export const useGetAllUsers = (take: number, skip: number) => {
	const {
		data: allUsers,
		isLoading: isLoadingAllUsers,
		isError: isErrorAllUsers,
		error: errorAllUsers,
	} = useQuery({
		queryKey: ['all-users', take, skip],
		queryFn: () => getAllUsers(take, skip),
		retry: false,
		staleTime: 10000,
	});

	return {
		allUsers,
		isLoadingAllUsers,
		isErrorAllUsers,
		errorAllUsers,
	};
};
