import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { notifyError, notifySuccess } from '@/helper/notifications';
import useLoading from '@/hooks/useLoading';
import { ConfirmationForm } from '@/pages/forgot-password/ConfirmationForm';
import { EmailForm } from '@/pages/forgot-password/EmailForm';
import {
	confirmNewPassword,
	passwordRecovery,
} from '@/service/cognito/cognito.services';

export const PasswordRecoveryForm = () => {
	const [isLoading, execute] = useLoading();

	const [email, setEmail] = useState('');
	const [codeSent, setCodeSent] = useState(false);
	const navigate = useNavigate();

	const handleSendCode = async (values: { email: string }) => {
		try {
			await passwordRecovery(values.email);
			setCodeSent(true);
			setEmail(values.email);
			notifySuccess('Code sent to your email !!!');
		} catch (e: unknown) {
			if (e instanceof Error) notifyError(e.message);
			else notifyError("UNEXPECTED ERROR: Couldn't send code");
		}
	};

	const handleConfirm = async (values: {
		code: string;
		'new-password': string;
	}) => {
		try {
			await confirmNewPassword(values['new-password'], values.code, email);
			notifySuccess('Successfully confirmed new password');
			navigate('/login');
		} catch (e: unknown) {
			if (e instanceof Error) notifyError(e.message);
			else notifyError("UNEXPECTED ERROR: Couldn't send code");
		}
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (codeSent) {
		return (
			<ConfirmationForm
				onSubmit={(values) => execute(handleConfirm(values))}
				codeSent={codeSent}
			></ConfirmationForm>
		);
	}
	return (
		<EmailForm
			onSubmit={(values) => execute(handleSendCode(values))}
		></EmailForm>
	);
};
