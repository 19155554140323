import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import { NavigationButtons } from '../common/buttons/navigation-button/NavigationButtons';
import { ChefSmallCardInfo } from '../common/chef/ChefSmallCardInfo';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import { Loader } from '@/components/common/Loader';
import { Slider } from '@/components/common/slider/Slider';
import { useGetAllChefs } from '@/hooks/chef/useGetAllChefs';
import { usePagination } from '@/hooks/usePagination';
import { ChefSort } from '@/interface/filter.interface';

export const TrendingChefsSection = () => {
	const [skip, setSkip] = useState(0);
	const sort = ChefSort.MOST_POPULAR;
	const take = 12;
	const {
		chefs,
		isLoadingChefs,
		isErrorLoadingChefs,
		refetchChefs,
		totalEntities,
	} = useGetAllChefs({ take, skip, sort });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	useEffect(() => {
		refetchChefs();
	}, [sort, skip]);

	if (isLoadingChefs)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (isErrorLoadingChefs) return <></>;

	return (
		<div
			data-cy="trending-chefs-section"
			id="favourite-creators"
			className="w-full"
		>
			<div className="flex flex-col gap-y-4 px-5">
				<div className="flex items-center gap-x-4" data-cy="creators-redirect">
					<h2 className="text-2xl sm:text-3xl md:text-4xl 2xl:text-5xl font-semibold">
						Trending <span className="text-friendlyRed">Creators</span>
					</h2>
					<Link to={`/chefs`}>
						<button className="relative hidden md:flex py-3 px-4 bg-brandRed hover:bg-[#E1B864] rounded-full text-white font-semibold transition-all :text-brandRed :bg-white :ring-2 :ring-brandRed :cursor-pointer font-helveticaNeue tracking-[0.02em]">
							<span>All Creators</span>
							<img src={arrowUpRight} alt="arrow-up-right-icon" />
						</button>
					</Link>
				</div>
				<p className="text-slate-400 text-lg font-helveticaNeue ">
					Get inspired with recipe ideas and tips from your favorites food
					creators on Kitched
				</p>
			</div>

			<div className="flex flex-col justify-center items-center  lg:items-center lg:gap-x-4">
				<div className="w-full lg:w-[88%]">
					<Slider cardsType={{ type: 'SMALL' }}>
						{chefs?.data?.map((chef) => (
							<SwiperSlide key={chef.id} className="md:px-2">
								<Link to={`/chefs/${chef.uuid}`}>
									<ChefSmallCardInfo chef={chef} />
								</Link>
							</SwiperSlide>
						))}
					</Slider>
				</div>
				<div>
					<NavigationButtons
						currentPage={currentPage}
						totalPages={totalPages}
						handleNextClick={onHandleNext}
						handlePrevClick={onHandlePrevious}
					/>
				</div>
			</div>
		</div>
	);
};
