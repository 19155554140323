import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getChefByUuid } from '../../service/api/chef';

export const useFetchChefById = () => {
	const { uuid } = useParams<{ uuid: string }>();
	const chefUuid = uuid ? uuid : '';

	const {
		data: chef,
		isLoading: isLoadingChef,
		isFetching: isFetchingChef,
		isError: isErrorLoadingChef,
		error: errorChef,
	} = useQuery({
		queryKey: ['chef', chefUuid],
		queryFn: () => {
			return getChefByUuid(chefUuid);
		},
	});

	return {
		chef,
		isLoadingChef,
		isFetchingChef,
		isErrorLoadingChef,
		errorChef,
	};
};
