import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { initGA, initGTM, logPageView } from '@/service/analytics/analytics';

const AnalyticsTracker = () => {
	const location = useLocation();

	useEffect(() => {
		initGA(import.meta.env.VITE_GA_MEASUREMENT_ID || '');
		initGTM(import.meta.env.VITE_GTM_ID || '');
	}, []);

	useEffect(() => {
		logPageView();
	}, [location]);

	return null;
};

export default AnalyticsTracker;
