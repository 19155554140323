import { useState } from 'react';
import { Link } from 'react-router-dom';

import eyeIcon from '@/assets/svg/eye-icon.svg';
import favGrayIcon from '@/assets/svg/favorite-recipe-gray-icon.svg';
import favRedIcon from '@/assets/svg/favorite-recipe-red-icon.svg';
import likeIcon from '@/assets/svg/like-icon.svg';
import saveRecipeBlackIcon from '@/assets/svg/save-recipe-black-icon.svg';
import saveRecipeIcon from '@/assets/svg/save-recipe-color-icon.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { CdnImage } from '@/components/images/CdnImage';
import { getRandomBetween } from '@/components/recipe-detail/RecipeBasicInformation';
import { useManageFavouriteRecipe } from '@/hooks/user-favourite/useManageFavouriteRecipe';
import { useManageSavedRecipe } from '@/hooks/user-saved/useManageSavedRecipe';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { IRecipe } from '@/interface/recipe.interfaces';
import { logRecipeLike } from '@/service/analytics/analytics';

export interface CardInfoProps {
	recipe: IRecipe;
	isChefProfile?: boolean;
	isChefPage?: boolean;
	hideIcons?: boolean;
	setIndexToFavouriteAndSaved: boolean;
	dontHaveAccessToRecipe?: boolean;
	paginationOptions?: {
		take: number;
		skip: number;
	};
	isLandingPage?: boolean;
	recipeOverEffect?: boolean;
	children?: JSX.Element;
}

export const CardInfo = ({
	recipe,
	isChefPage,
	hideIcons,
	setIndexToFavouriteAndSaved,
	paginationOptions,
	isLandingPage,
	recipeOverEffect,
	children,
}: CardInfoProps) => {
	const { isSaved, addSavedRecipe, deleteSavedRecipe } = useManageSavedRecipe(
		recipe,
		paginationOptions || defaultPortfolioPaginationOptions,
	);
	const { isFavorite, addFavouriteRecipe, deleteFavouriteRecipe } =
		useManageFavouriteRecipe(
			recipe,
			paginationOptions || defaultPortfolioPaginationOptions,
		);

	const { title, views, tags, chef, uuid } = recipe;
	// Randomise selection whilst not currently dynamic
	const [currentLikes, setCurrentLikes] = useState(() =>
		getRandomBetween(30, 900),
	);
	const [randomizedViews, _setRandomizedViews] = useState(() =>
		getRandomBetween(2000, 5000),
	);

	const handleSave = () => {
		if (isSaved) {
			deleteSavedRecipe();
		} else {
			addSavedRecipe();
		}
	};

	const handleFavorite = () => {
		if (!isFavorite) {
			const chefFullName = `${chef?.user?.name || 'Unknown'} ${
				chef?.user?.last_name || 'Unknown'
			}`;
			logRecipeLike(uuid, title, chef?.uuid, chefFullName);
			addFavouriteRecipe({
				onSuccess: () => setCurrentLikes((prevLikes) => prevLikes + 1),
			});
		} else {
			deleteFavouriteRecipe({
				onSuccess: () => setCurrentLikes((prevLikes) => prevLikes - 1),
			});
		}
	};
	return (
		<div
			className={`w-full mt-2 ${isLandingPage ? 'pointer-events-none' : ''}`}
		>
			<div className="w-full flex px-1 gap-2.5 font-light text-xs text-grayText">
				{!hideIcons && (
					<>
						<div className="flex items-center">
							<img src={eyeIcon} alt="eye-icon" className="w-4 h-4" />
							<p className="ml-1 flex items-center font-bold mr-1">
								{/* Randomise selection whilst not currently dynamic */}
								{views?.views || randomizedViews}
							</p>
						</div>
						<div className="flex items-center">
							<img src={likeIcon} alt="like-icon" className="w-4 h-4" />
							<p
								className="ml-1 flex items-center font-bold mr-1"
								data-cy="like-counter"
							>
								{currentLikes}
							</p>
						</div>
					</>
				)}
				<div className="flex-1"></div>

				<img
					src={isFavorite ? favRedIcon : favGrayIcon}
					alt="favorite-icon"
					className={`w-[14px] h-[14px] hover:scale-110 hover:cursor-pointer transform ${
						setIndexToFavouriteAndSaved ? 'z-[-1]' : ''
					}`}
					data-cy="like-icon"
					onClick={handleFavorite}
				/>
				<img
					src={isSaved ? saveRecipeIcon : saveRecipeBlackIcon}
					alt="saved-icon"
					className={`w-[14px] h-[14px] hover:scale-110 hover:cursor-pointer transform ${
						setIndexToFavouriteAndSaved ? 'z-[-1]' : ''
					}`}
					data-cy="save-icon"
					onClick={handleSave}
				/>
			</div>

			<Link to={`/recipe/detail/${uuid}`}>
				<p
					className={`h-[30px] text-xl mt-1 px-1 font-bold tracking-tight break-words hover:text-brandRed ${
						recipeOverEffect && title.length > 20
							? 'min-[420px]:text-xs min-[420px]:leading-4 tracking-tighter line-clamp-2'
							: 'truncate'
					} `}
					data-cy="card-title"
				>
					{title}
				</p>
			</Link>

			{!isChefPage && (
				<div className="flex items-center gap-2 my-1.5 px-[4px] font-light text-gray-600">
					<CdnImage
						height={20}
						width={20}
						src={chef?.user?.profile_image?.src || userBlackIcon}
						alt="avatar"
						className="object-cover w-5 h-5 rounded-full"
					/>
					<p className="truncate text-xs tracking-normal font-helveticaNeue">
						{`${chef?.user?.name} ${chef?.user?.last_name}`}
					</p>
				</div>
			)}

			{recipeOverEffect && tags && tags.length == 0 ? (
				<div className="gap-x-1.5 gap-y-2 px-1 mb-4"></div>
			) : (
				<div className="flex flex-wrap mt-[9px] gap-x-1.5 gap-y-2 px-1 h-10 overflow-hidden">
					{tags?.map((tag, index) => (
						<Link
							data-cy="card-info-tag"
							to={`/search/tag-${tag.name}`}
							key={index}
							className="h-[35px] border-[1px] border-brandRed flex items-center justify-center buttons-shape text-[13px] leading-[13px] text-center font-normal text-brandRed tracking-[0.02em] font-helveticaNeue"
						>
							{tag.name}
						</Link>
					))}
				</div>
			)}
			{children}
		</div>
	);
};
