import DropdownMenu from '@/components/common/DropdownMenu';
import { Loader } from '@/components/common/Loader';
import { User } from '@/context/interfaces';
import { useUserPreferences } from '@/hooks/user/useUserPreferences ';
import { DietaryPreferences, ICookingLevel } from '@/interface/user.interfaces';

interface CustomChoicesMenusProps {
	user: User;
}

export const CustomChoicesMenus = ({ user }: CustomChoicesMenusProps) => {
	const {
		preferences,
		handledietaryPreferencesChange,
		handleCookingLevelChange,
		isPendingPreferencesChange,
	} = useUserPreferences(user);

	return (
		<div className="relative flex flex-col items-center">
			<h5 className="text-xl 2xl:text-3xl font-semibold text-center mb-4">
				Preferences
			</h5>

			{isPendingPreferencesChange ? (
				<div className="flex justify-center lg:mt-16">
					<Loader size={2} />
				</div>
			) : (
				<div className="w-full max-h-[250px] space-y-4 md:px-8 overflow-y-auto global-scrollbar">
					<DropdownMenu
						options={Object.values(ICookingLevel)}
						selectedValues={
							preferences.cookingLevel ? [preferences.cookingLevel] : []
						}
						label="COOKING EXPERIENCE"
						onChange={(level) =>
							handleCookingLevelChange(level as ICookingLevel)
						}
					/>

					<DropdownMenu
						options={Object.values(DietaryPreferences)}
						selectedValues={preferences.dietaryPreferences || []}
						label="DIETARY PREFERENCES"
						onChange={(preferences) =>
							handledietaryPreferencesChange(preferences)
						}
					/>
				</div>
			)}
		</div>
	);
};
