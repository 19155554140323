import { AxiosError } from 'axios';

import { Chef } from '@/context/interfaces';
import { CHEF_ERRORS_MESSAGES } from '@/error/instagram-errors.enum';
import {
	InstagramPageDirection,
	InstagramPost,
	InstagramPostsAndPaging,
} from '@/interface/instagram.interfaces';
import { IRecipe } from '@/interface/recipe.interfaces';
import Api from '@/service';

const RESOURCE = 'chef';

export const getInstagramCode = async (): Promise<string> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/instagram/code`);
		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(CHEF_ERRORS_MESSAGES.GET_INSTAGRAM_CODE_ERROR);
	}
};

export const setInstagramToken = async (
	chefId: number | undefined,
	code: string | null,
): Promise<Chef> => {
	try {
		const { data } = await Api.get(
			`/${RESOURCE}/${chefId}/instagram/set-token/${code}`,
		);

		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(CHEF_ERRORS_MESSAGES.GET_INSTAGRAM_SHORT_TOKEN_ERROR);
	}
};

export const getInstagramPosts = async (
	chefId: number,
	direction?: InstagramPageDirection,
): Promise<InstagramPostsAndPaging> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/${chefId}/instagram/posts`, {
			params: {
				direction,
			},
		});

		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(CHEF_ERRORS_MESSAGES.GET_INSTAGRAM_POSTS_ERROR);
	}
};

export const gptInstagramPostCheck = async (
	prompt: InstagramPost,
): Promise<IRecipe> => {
	try {
		const { data } = await Api.post(`/${RESOURCE}/instagram/post/check`, {
			prompt,
		});

		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(CHEF_ERRORS_MESSAGES.GET_INSTAGRAM_GPT_CHECK_ERROR);
	}
};
