import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import { Chef, SocialAccount, User } from '@/context/interfaces';
import { CHEF_ERRORS_MESSAGES } from '@/error/chef-errors.enum';
import { IGetAllResponse } from '@/interface/get-all-response';
import { IPagination } from '@/interface/pagination.interface';
import Api from '@/service';

const RESOURCE = 'chef';

export const fetchChefs = async (
	options: IPagination,
): Promise<IGetAllResponse<Chef>> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}`, { params: { ...options } });

		return data;
	} catch (error: unknown) {
		console.log({ error });
		const errorMessage = handleAxiosError(
			error,
			CHEF_ERRORS_MESSAGES.GET_ALL_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const fetchChefsNames = async (
	options: IPagination,
): Promise<IGetAllResponse<Chef>> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/names`, {
			params: { ...options },
		});

		return data;
	} catch (error: unknown) {
		console.log({ error });
		const errorMessage = handleAxiosError(
			error,
			CHEF_ERRORS_MESSAGES.GET_ALL_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const removeChef = async (id: number): Promise<void> => {
	try {
		await Api.delete(`${import.meta.env.VITE_API_BASE_URL}/${RESOURCE}/${id}`);
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			CHEF_ERRORS_MESSAGES.DELETE_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const createChef = async (user: User): Promise<Chef> => {
	try {
		const response = await Api.post<Chef>(`/${RESOURCE}`, {
			user: user.id,
		});
		return response.data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			CHEF_ERRORS_MESSAGES.CREATE_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const getChefByUuid = async (chefUuid: string): Promise<Chef> => {
	try {
		const { data } = await Api.get<Chef>(`/chef/${chefUuid}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(CHEF_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(CHEF_ERRORS_MESSAGES.GET_CHEF_BY_ID_ERROR);
	}
};

export const editChef = async (
	id: number,
	updateChef: { bio?: string; socialAccount?: SocialAccount[] },
): Promise<Chef> => {
	try {
		const { data } = await Api.patch<Chef>(`/chef/${id}`, updateChef);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			CHEF_ERRORS_MESSAGES.UPDATE_CHEF_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};
