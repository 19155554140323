import { useState } from 'react';
import ReactPlayer from 'react-player';

import { Loader } from '../common/Loader';
import { CdnImage } from '../images/CdnImage';

import favGrayIcon from '@/assets/svg/favorite-recipe-gray-icon.svg';
import favRedIcon from '@/assets/svg/favorite-recipe-red-icon.svg';
import pauseIcon from '@/assets/svg/pause-icon.svg';
import playIcon from '@/assets/svg/play-icon.svg';
import saveRecipeBlackIcon from '@/assets/svg/save-recipe-black-icon.svg';
import saveRecipeIcon from '@/assets/svg/save-recipe-color-icon.svg';
import shareBlackIcon from '@/assets/svg/share-black-icon.svg';
import { useManageFavouriteRecipe } from '@/hooks/user-favourite/useManageFavouriteRecipe';
import { useManageSavedRecipe } from '@/hooks/user-saved/useManageSavedRecipe';
import { IRecipe } from '@/interface/recipe.interfaces';
import { logRecipeLike } from '@/service/analytics/analytics';

import soundIcon from '@assets/image/sound-icon.png';
import soundlessIcon from '@assets/image/soundless-icon.png';

interface RecipeDetailImageProps {
	mediaUrl: string;
	isVideo?: boolean;
	onOpenPopUp?: () => void;
	recipe: IRecipe;
}

export const RecipeDetailImage = ({
	onOpenPopUp,
	mediaUrl,
	isVideo,
	recipe,
}: RecipeDetailImageProps) => {
	const [play, setPlay] = useState(false);
	const [videoMuted, setVideoMuted] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(
		isVideo || mediaUrl ? true : false,
	);
	const { isFavorite, addFavouriteRecipe, deleteFavouriteRecipe } =
		useManageFavouriteRecipe(recipe);
	const { isSaved, addSavedRecipe, deleteSavedRecipe } =
		useManageSavedRecipe(recipe);

	const handlePlayToggle = () => {
		setVideoMuted(false);
		setPlay(!play);
	};

	const handleFavoriteToggle = () => {
		if (!isFavorite) {
			const chefFullName = `${recipe.chef?.user?.name || 'Unknown'} ${
				recipe.chef?.user?.last_name || 'Unknown'
			}`;
			logRecipeLike(recipe.uuid, recipe.title, recipe.chef?.uuid, chefFullName);
			addFavouriteRecipe();
		} else {
			deleteFavouriteRecipe();
		}
	};

	const handleSaveToggle = () => {
		if (isSaved) {
			deleteSavedRecipe();
		} else {
			addSavedRecipe();
		}
	};

	return (
		<div className="relative w-full max-w-[500px]">
			{!isVideo ? (
				<>
					<CdnImage
						src={mediaUrl ? mediaUrl : '/images/recipe-pasta.png'}
						alt="Pasta"
						className={`w-full object-cover rounded-3xl ${
							isLoading && 'hidden'
						}`}
						setIsLoading={setIsLoading}
					/>
					{isLoading && (
						<div
							className="absolute top-[80%] left-1/2 mt-[50%]"
							data-cy="loader-recipe-details"
						>
							<Loader size={2} />
						</div>
					)}
				</>
			) : (
				<div
					className="relative w-full hover:cursor-pointer"
					onClick={handlePlayToggle}
				>
					<div className="w-full rounded-3xl sm:space-y-10 overflow-hidden">
						<ReactPlayer
							url={mediaUrl}
							playing={play}
							loop={play}
							width="100%"
							height="100%"
							style={{ objectFit: 'cover' }}
							muted={videoMuted}
							playsinline
							className="video-player"
							controls={false}
							onReady={() => setIsLoading(false)}
						/>
					</div>
					{isLoading ? (
						<div
							className="absolute top-[80%] left-1/2"
							data-cy="loader-recipe-details"
						>
							<Loader size={2} />
						</div>
					) : (
						<div className="flex justify-center items-center absolute bottom-4 right-4 gap-4">
							{play && (
								<button
									className="flex justify-center items-center h-[30px] w-[30px] rounded-full shadow-black shadow-inner p-[2px] "
									id="videoButton"
									onClick={(e) => {
										e.stopPropagation();
										setVideoMuted(!videoMuted);
									}}
								>
									{
										<img
											src={videoMuted ? soundlessIcon : soundIcon}
											alt={videoMuted ? 'soundless' : 'sound'}
											data-cy="sound-icon"
										/>
									}
								</button>
							)}
							<button className="flex justify-center items-center  h-[40px] w-[40px]">
								<img
									src={play ? pauseIcon : playIcon}
									alt={play ? 'pause-icon' : 'play-icon'}
								/>
							</button>
						</div>
					)}
				</div>
			)}
			{!isLoading && (
				<div className="flex gap-x-4 absolute top-8 right-4">
					<div
						className="bg-white p-3 rounded-full transform hover:scale-110 hover:cursor-pointer"
						onClick={handleFavoriteToggle}
					>
						<img
							src={isFavorite ? favRedIcon : favGrayIcon}
							alt="favorite-icon"
						/>
					</div>
					<div
						className="bg-white p-3 rounded-full transform hover:scale-110 hover:cursor-pointer"
						onClick={handleSaveToggle}
					>
						<img
							src={isSaved ? saveRecipeIcon : saveRecipeBlackIcon}
							alt="save-icon"
						/>
					</div>
					<div
						data-cy="detail-page-share-button"
						className="bg-white p-3 rounded-full transform hover:scale-110 hover:cursor-pointer"
						onClick={onOpenPopUp}
					>
						<img src={shareBlackIcon} alt="share-icon" />
					</div>
				</div>
			)}
		</div>
	);
};
