import { useState } from 'react';

interface CdnImageProps {
	src: string | undefined;
	alt: string;
	width?: number;
	height?: number;
	className?: string;
	dataCy?: string;
	setIsLoading?: (loading: boolean) => void;
}

export const CdnImage = ({
	src,
	alt,
	width,
	height,
	className,
	dataCy,
	setIsLoading,
}: CdnImageProps) => {
	const [imgSrc, setImgSrc] = useState(src);
	const fallbackSrc = src && src.split('/').slice(5).join('/');

	const handleError = () => {
		setImgSrc(fallbackSrc);
	};
	return (
		<img
			src={imgSrc}
			alt={alt}
			className={className}
			width={width}
			height={height}
			onError={handleError}
			data-cy={dataCy}
			onLoad={() => {
				setIsLoading && setIsLoading(false);
			}}
		/>
	);
};
