import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import { IPagination } from '@/interface/pagination.interface';
import { getUserLikedRecipes } from '@/service/api/user';

export const useGetUserLikedRecipes = (options: IPagination) => {
	const location = useLocation();
	const isProfile =
		location.pathname.includes('/user/me') ||
		location.pathname.includes('/chef/profile');
	const {
		data: userLikedRecipes,
		isLoading: isLoadingUserLikedRecipes,
		isError: isErrorUserLikedRecipes,
		error: errorUserLikedRecipes,
		refetch: refetchUserLikedRecipes,
	} = useQuery({
		queryKey: ['user-liked-recipes', options.take, options.skip],
		queryFn: () => getUserLikedRecipes(options),
		retry: false,
		enabled: isProfile,
	});

	return {
		userLikedRecipes: userLikedRecipes,
		refetchUserLikedRecipes: refetchUserLikedRecipes,
		totalUserLikedEntities: userLikedRecipes?.total as number,
		isLoadingUserLikedRecipes: isLoadingUserLikedRecipes,
		isErrorUserLikedRecipes: isErrorUserLikedRecipes,
		errorUserLikedRecipes: errorUserLikedRecipes,
	};
};
