import { AxiosError } from 'axios';

import { createPaymentSessionDto } from '@/interface/payment.interfaces';
import Api from '@/service';

const PAYMENT_RESOURCE = 'payments';

export const createPaymentSession = async ({
	suscriberPlan,
	customerDto,
}: createPaymentSessionDto): Promise<{ sessionUrl: string }> => {
	try {
		const { data } = await Api.post(
			`${PAYMENT_RESOURCE}/create-payment-session/${suscriberPlan}`,
			customerDto,
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data.message);
		}
		throw new Error('Error when creating payment session');
	}
};

export const openBillingPortal = async (): Promise<{ url: string }> => {
	try {
		const { data } = await Api.get(`${PAYMENT_RESOURCE}/billing-portal`);
		return data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data.message);
		}
		throw new Error('Error when opening billing portal');
	}
};

export const putSubscription = async (userId: number): Promise<void> => {
	try {
		await Api.put(`${PAYMENT_RESOURCE}/give-free-subscription/${userId}`);
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data.message);
		}
		throw new Error(
			'An error occurred while trying to provide this free subscription',
		);
	}
};

export const deleteSubscription = async (userId: number): Promise<void> => {
	try {
		await Api.delete<void>(
			`${PAYMENT_RESOURCE}/remove-free-subscription/${userId}`,
		);
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.response?.data.message);
		}
		throw new Error(
			'An error occurred while trying to remove this free subscription',
		);
	}
};
