import { PasswordRecoveryForm } from '@/pages/forgot-password/PasswordRecoveryForm';

import kitchedLogo from '@assets/svg/kitched-logo-login.svg';

export const ForgotPassword = () => {
	return (
		<div className="container mx-auto mt-12 flex flex-col items-center font-helveticaNeue">
			<img src={kitchedLogo} alt="logo" className="mt-5 w-[240px] h-[80px]" />
			<h1 className="text-center text-3xl font-bold mb-12">
				Recover your password
			</h1>
			<div className="max-w-lg w-full">
				<PasswordRecoveryForm />
			</div>
		</div>
	);
};
