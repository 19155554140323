import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetUserSavedRecipes } from '../user/useGetUserSavedRecipes';

import { User } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { notifyError } from '@/helper/notifications';
import {
	useDeleteUserSavedRecipe,
	usePutUserSavedRecipe,
} from '@/hooks/user-saved/useUserSavedRecipe';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { IRecipe } from '@/interface/recipe.interfaces';

export const useManageSavedRecipe = (
	recipe: IRecipe,
	paginationOptions?: { take: number; skip: number },
) => {
	const [isSaved, setIsSaved] = useState(false);
	const { user, setUser } = useAuth();
	const savedRecipeMutation = usePutUserSavedRecipe();
	const deleteSavedRecipeMutation = useDeleteUserSavedRecipe();
	const { refetchUserSavedRecipes } = useGetUserSavedRecipes(
		paginationOptions || defaultPortfolioPaginationOptions,
	);
	const location = useLocation();
	const isProfile =
		location.pathname === '/user/me' || location.pathname === '/chef/profile';
	const queryClient = useQueryClient();
	const { id } = recipe;

	useEffect(() => {
		if (user) {
			const saved = !!user.savedRecipes?.find((recipe) => recipe.id === id);
			setIsSaved(saved);
		}
	}, [user, id]);

	const addSavedRecipe = () => {
		savedRecipeMutation.mutate(
			{ userId: user?.id || 0, recipeId: id },
			{
				onSuccess: () => {
					setIsSaved(true);
					setUser((previousUser) => {
						if (!previousUser) return null;

						const updateUser = {
							...previousUser,
							savedRecipes: [...previousUser.savedRecipes, recipe],
						};
						isProfile && refetchUserSavedRecipes();
						return updateUser;
					});
				},
				onError: (error: Error) => {
					setIsSaved(false);
					notifyError(`${error.message}`);
				},
			},
		);
	};

	const deleteSavedRecipe = () => {
		deleteSavedRecipeMutation.mutate(
			{ userId: user?.id || 0, recipeId: id },
			{
				onSuccess: () => {
					setIsSaved(false);
					setUser((previousUser) => {
						if (!previousUser) return null;

						const updateUser: User = {
							...previousUser,
							savedRecipes:
								previousUser?.savedRecipes?.filter(
									(recipe) => recipe.id !== id,
								) || [],
						};
						isProfile &&
							queryClient.invalidateQueries({
								queryKey: [
									'user-saved-recipes',
									paginationOptions?.take,
									paginationOptions?.skip,
								],
							});

						isProfile && refetchUserSavedRecipes();
						return updateUser as User;
					});
				},
				onError: (error: Error) => {
					setIsSaved(true);
					notifyError(`${error.message}`);
				},
			},
		);
	};

	return {
		isSaved,
		addSavedRecipe,
		deleteSavedRecipe,
	};
};
