import { PopUp } from '@/components/common/PopUp';

interface TermsModalProps {
	open: boolean;
	onClose: () => void;
}

export const TermsAndConditionsModal = ({ open, onClose }: TermsModalProps) => {
	return (
		<PopUp open={open} onClose={onClose} topCss="10vh">
			<div className="p-4 max-h-[70vh] overflow-y-auto modal-content">
				<h2 className="text-2xl font-semibold mb-4">Terms & Conditions</h2>
				<h3 className="text-lg font-semibold mt-4">
					1. WEBSITE TERMS & CONDITIONS OF USE
				</h3>
				<p>
					Welcome to kitched.com (Website). The Website allows you to access and
					use the recipe and cooking media (Services).
				</p>
				<p>
					The Website is operated by Kitched, ACN/ABN 672477771. Access to and
					use of the Website, or any of its associated products or Services, is
					provided by Kitched. Please read these terms and conditions (Terms)
					carefully. By using, browsing and/or reading the Website, this
					signifies that you have read, understood and agree to be bound by the
					Terms. If you do not agree with the Terms, you must cease usage of the
					Website, or any of Services, immediately.
				</p>
				<p>
					Kitched reserves the right to review and change any of the Terms by
					updating this page at its sole discretion. When Kitched updates the
					Terms, it will use reasonable endeavours to provide you with notice of
					updates to the Terms. Any changes to the Terms take immediate effect
					from the date of their publication. Before you continue, we recommend
					you keep a copy of the Terms for your records.
				</p>

				<h3 className="text-lg font-semibold mt-4">
					2. Acceptance of the Terms
				</h3>
				<p>
					You accept the Terms by remaining on the Website. Where the option is
					available in the user interface, you may also accept the Terms by
					clicking to accept or agree to the Terms.
				</p>

				<h3 className="text-lg font-semibold mt-4">3. About the Service</h3>
				<p>
					Recipe and cooking media is shared from the social media profiles of
					onboarded creators. Free and paid for accounts are currently offered.
					You acknowledge and agree that the accounts offered, as well as the
					account features, may change from time to time, and may be governed by
					separate terms which apply specific to the account. Where special
					account-specific terms apply, you will be informed, and must accept
					those terms before you are given such an account. For the avoidance of
					doubt, these Terms apply unless otherwise agreed or amended by
					account-specific terms.
				</p>

				<h3 className="text-lg font-semibold mt-4">
					4. Acceptable use of the Service
				</h3>
				<p>
					Recipe and cooking media, its related features, and website must only
					be used lawfully. Kitched reserves the right to suspend, cancel, or
					otherwise deny access to users and accounts who use the service:
				</p>
				<ul className="list-disc list-inside">
					<li>
						To engage in any act that would disrupt the access, availability,
						and security of recipe and cooking media and other Kitched services.
					</li>
					<li>
						For any illegal purpose, or to violate any laws, including and
						without limitation to data, privacy, and export control laws.
					</li>
					<li>
						To stalk, harass or threaten users and any member of the public.
					</li>
					<li>
						To misrepresent or defraud any user or member of the public through
						phishing, spoofing, manipulating headers or other identifiers,
						impersonating anyone else, or falsely implying any sponsorship or
						association with Kitched or any third party.
					</li>
					<li>
						To access or search any part of the Service, or any other Service
						owned by Kitched other than our publicly supported interface, or
						otherwise allowed for in an applicable Software Licensing Agreement.
					</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">
					5. Security and Data Privacy
				</h3>
				<p>
					Kitched takes your privacy seriously and information provided through
					your use of the Website and/or Services are subject to Kitched’s
					Privacy Policy, which is available on the Website. The Privacy Policy
					also addresses Kitched’s processes, policies, and obligations in
					respect of recipe and cooking media security breaches.
				</p>

				<h3 className="text-lg font-semibold mt-4">6. Data Use</h3>
				<p>
					Kitched collects, stores, and processes your data on recipe and
					cooking media. The data is used to provide Services to you, as well as
					to facilitate Kitched’s business operations. The Privacy Policy
					outlined how your data is collected, stored, and processed by Kitched.
					The Privacy Policy also addresses Kitched's processes, policies, and
					obligations in respect of data encryption and removal requests.
				</p>

				<h3 className="text-lg font-semibold mt-4">
					7. Subscription to use the Service
				</h3>
				<p>
					In order to access the Services, you must first purchase a
					subscription through the Website (Subscription) and pay the applicable
					fee for the selected Subscription (Subscription Fee). After purchasing
					a Subscription, you will be considered a member (‘Member’).
				</p>
				<ul className="list-disc list-inside">
					<li>
						In purchasing the Subscription, you acknowledge and agree that it is
						your responsibility to ensure that the Subscription you elect to
						purchase is suitable for your use.
					</li>
					<li>
						Before, during or after you have purchased the Subscription, you
						will then be required to register for an account through the Website
						before you can access the Services (Account).
					</li>
					<li>
						As part of the registration process, or as part of your continued
						use of the Services, you may be required to provide personal
						information about yourself (such as identification or contact
						details), including but not limited to name, email address, credit
						card details, food and cooking preferences, social media profiles
						and dietary needs.
					</li>
					<li>
						You warrant that any information you give to Kitched in the course
						of completing the registration process will always be accurate,
						correct and up to date.
					</li>
					<li>
						Once you have completed the registration process, you will be a
						registered user of the Website and agree to be bound by the Terms
						("User"). As a Member you will be granted immediate access to the
						Services from the time you have completed the registration process
						until the subscription period expires (Subscription Period).
					</li>
					<li>
						You may not use the Services and may not accept the Terms if you are
						not of legal age to form a binding contract with Kitched; or you are
						a person barred from receiving the Services under the laws of
						Australia or other countries including the country in which you are
						resident or from which you use the Services.
					</li>
				</ul>
				<h3 className="text-lg font-semibold mt-4">8. Payments</h3>
				<p>
					Subject to the terms of any applicable Software License Agreement, the
					Subscription Fee may be paid by all payment methods available on the
					Website, and may change from time to time.
				</p>
				<p>
					Payments made in the course of your use of recipe and cooking media
					may be made using third-party applications and services not owned,
					operated, or otherwise controlled by Kitched. You acknowledge and
					agree that Kitched will not be liable for any losses or damage arising
					from the operations of third-party payment applications and services.
					You further acknowledge and warrant that you have read, understood and
					agree to be bound by the terms and conditions of the applicable
					third-party payment applications and services you choose to use as a
					payment method for recipe and cooking media services.
				</p>
				<p>
					You acknowledge and agree that where a request for the payment of the
					Subscription Fee is returned or denied, for whatever reason, by your
					financial institution or is unpaid by you for any other reason, then
					you are liable for any costs, including banking fees and charges,
					associated with the Subscription Fee.
				</p>
				<p>
					You agree and acknowledge that Kitched can vary the Subscription Fee
					at any time and that the varied Subscription Fee will come into effect
					following the conclusion of the existing Subscription.
				</p>

				<h3 className="text-lg font-semibold mt-4">9. Refund Policy</h3>
				<p>
					Kitched offers refunds in accordance with the Australian Consumer Law
					and on the terms set out in these terms and conditions. Any benefits
					set out in these terms and conditions may apply in addition to
					consumer's rights under the Australian Consumer Law.
				</p>
				<p>
					Kitched will only provide you with a refund of the Subscription Fee in
					the event they are unable to continue to provide the Services or if
					the manager of Kitched makes a decision, at its absolute discretion,
					that it is reasonable to do so under the circumstances. Where this
					occurs, the refund will be in the proportional amount of the
					Subscription Fee that remains unused by the Member (Refund).
				</p>

				<h3 className="text-lg font-semibold mt-4">
					10. Copyright and Intellectual Property
				</h3>
				<p>
					The Website, the Services and all of the related products of Kitched
					are subject to copyright. The material on the Website is protected by
					copyright under the laws of Australia and through international
					treaties. Unless otherwise indicated, all rights (including copyright)
					in the Services and compilation of the Website (including but not
					limited to text, graphics, logos, button icons, video images, audio
					clips, Website code, scripts, design elements and interactive
					features) or the Services are owned or controlled for these purposes,
					and are reserved by Kitched or its contributors.
				</p>
				<p>
					All trademarks, service marks and trade names are owned, registered
					and/or licensed by Kitched, who grants to you a worldwide,
					non-exclusive, royalty-free, revocable license whilst you are a User
					to:
				</p>
				<ul className="list-disc list-inside">
					<li>use the Website pursuant to the Terms;</li>
					<li>
						copy and store the Website and the material contained in the Website
						in your device's cache memory; and
					</li>
					<li>
						print pages from the Website for your own personal and
						non-commercial use.
					</li>
				</ul>
				<p>
					Kitched does not grant you any other rights whatsoever in relation to
					the Website or the Services. All other rights are expressly reserved
					by Kitched.
				</p>
				<p>
					Kitched retains all rights, title and interest in and to the Website
					and all related Services. Nothing you do on or in relation to the
					Website will transfer any:
				</p>
				<ul className="list-disc list-inside">
					<li>
						business name, trading name, domain name, trade mark, industrial
						design, patent, registered design or copyright, or
					</li>
					<li>
						a right to use or exploit a business name, trading name, domain
						name, trade mark or industrial design, or
					</li>
					<li>
						a thing, system or process that is the subject of a patent,
						registered design or copyright (or an adaptation or modification of
						such a thing, system or process), to you.
					</li>
				</ul>

				<p>
					You may not, without the prior written permission of Kitched and the
					permission of any other relevant rights owners: broadcast, republish,
					upload to a third party, transmit, post, distribute, show or play in
					public, adapt or change in any way the Services or third party
					Services for any purpose, unless otherwise provided by these Terms.
					This prohibition does not extend to materials on the Website, which
					are freely available for re-use or are in the public domain.
				</p>

				<h3 className="text-lg font-semibold mt-4">11. General Disclaimer</h3>
				<p>
					Nothing in the Terms limits or excludes any guarantees, warranties,
					representations or conditions implied or imposed by law, including the
					Australian Consumer Law (or any liability under them) which by law may
					not be limited or excluded.
				</p>
				<p>Subject to this clause, and to the extent permitted by law:</p>
				<ul className="list-disc list-inside">
					<li>
						all terms, guarantees, warranties, representations or conditions
						which are not expressly stated in the Terms are excluded; and
					</li>
					<li>
						Kitched will not be liable for any special, indirect or
						consequential loss or damage (unless such loss or damage is
						reasonably foreseeable resulting from our failure to meet an
						applicable Consumer Guarantee), loss of profit or opportunity, or
						damage to goodwill arising out of or in connection with the Services
						or these Terms (including as a result of not being able to use the
						Services or the late supply of the Services), whether at common law,
						under contract, tort (including negligence), in equity, pursuant to
						statute or otherwise.
					</li>
				</ul>
				<p>
					Use of the Website and the Services is at your own risk. Everything on
					the Website and the Services is provided to you "as is" and "as
					available" without warranty or condition of any kind. None of the
					affiliates, directors, officers, employees, agents, contributors and
					licensors of Kitched make any express or implied representation or
					warranty about the Services or any products or Services (including the
					products or Services of Kitched) referred to on the Website. This
					includes (but is not restricted to) loss or damage you might suffer as
					a result of any of the following:
				</p>
				<ul className="list-disc list-inside">
					<li>
						failure of performance, error, omission, interruption, deletion,
						defect, failure to correct defects, delay in operation or
						transmission, computer virus or other harmful component, loss of
						data, communication line failure, unlawful third party conduct, or
						theft, destruction, alteration or unauthorised access to records;
					</li>
					<li>
						the accuracy, suitability or currency of any information on the
						Website, the Services, or any of its Services related products
						(including third party material and advertisements on the Website);
					</li>
					<li>
						costs incurred as a result of you using the Website, the Services or
						any of the products of Kitched; and
					</li>
					<li>
						the Services or operation in respect to links which are provided for
						your convenience.
					</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">
					12. Limitation of Liability
				</h3>
				<p>
					Kitched's total liability arising out of or in connection with the
					Services or these Terms, however arising, including under contract,
					tort (including negligence), in equity, under statute or otherwise,
					will not exceed the resupply of the Services to you.
				</p>
				<p>
					You expressly understand and agree that Kitched, its affiliates,
					employees, agents, contributors and licensors shall not be liable to
					you for any direct, indirect, incidental, special consequential or
					exemplary damages which may be incurred by you, however caused and
					under any theory of liability. This shall include, but is not limited
					to, any loss of profit (whether incurred directly or indirectly), any
					loss of goodwill or business reputation and any other intangible loss.
				</p>

				<h3 className="text-lg font-semibold mt-4">
					13. Termination of Contract
				</h3>
				<p>
					The Terms will continue to apply until terminated by either you or by
					Kitched as set out below.
				</p>
				<ul className="list-disc list-inside">
					<li>
						If you want to terminate the Terms, you may do so by not renewing
						the Subscription prior to the end of the Subscription Period;
						providing Kitched with 30 days’ notice of your intention to
						terminate; and closing your accounts for all of the services which
						you use, where Kitched has made this option available to you.
					</li>
					<li>
						Kitched may at any time, terminate the Terms with you if you do not
						renew the Subscription at the end of the Subscription Period; you
						have breached any provision of the Terms or intend to breach any
						provision; Kitched is required to do so by law; the provision of the
						Services to you by Kitched is, in the opinion of Kitched, no longer
						commercially viable.
					</li>
					<li>
						Subject to local applicable laws, Kitched reserves the right to
						discontinue or cancel your Subscription or Account at any time and
						may suspend or deny, in its sole discretion, your access to all or
						any portion of the Website or the Services without notice if you
						breach any provision of the Terms or any applicable law or if your
						conduct impacts Kitched's name or reputation or violates the rights
						of those of another party.
					</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">14. Indemnity</h3>
				<p>
					You agree to indemnify Kitched, its affiliates, employees, agents,
					contributors, third party content providers and licensors from and
					against:
				</p>
				<ul className="list-disc list-inside">
					<li>
						all actions, suits, claims, demands, liabilities, costs, expenses,
						loss and damage (including legal fees on a full indemnity basis)
						incurred, suffered or arising out of or in connection with your
						content;
					</li>
					<li>
						any direct or indirect consequences of you accessing, using or
						transacting on the Website or attempts to do so; and/or
					</li>
					<li>any breach of the Terms.</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">15. Dispute Resolution</h3>
				<p>
					If a dispute arises out of or relates to the Terms, either party may
					not commence any Tribunal or Court proceedings in relation to the
					dispute, unless the following clauses have been complied with (except
					where urgent interlocutory relief is sought).
				</p>
				<ul className="list-disc list-inside">
					<li>
						A party to the Terms claiming a dispute (Dispute) has arisen under
						the Terms, must give written notice to the other party detailing the
						nature of the dispute, the desired outcome and the action required
						to settle the Dispute.
					</li>
					<li>
						On receipt of that notice (Notice) by that other party, the parties
						to the Terms (Parties) must: Within 28 days of the Notice endeavour
						in good faith to resolve the Dispute expeditiously by negotiation or
						such other means upon which they may mutually agree; If for any
						reason whatsoever, 28 days after the date of the Notice, the Dispute
						has not been resolved, the Parties must either agree upon selection
						of a mediator or request that an appropriate mediator be appointed
						by the Website; The Parties are equally liable for the fees and
						reasonable expenses of a mediator and the cost of the venue of the
						mediation and without limiting the foregoing undertake to pay any
						amounts requested by the mediator as a precondition to the mediation
						commencing. The Parties must each pay their own costs associated
						with the mediation; The mediation will be held in Sydney, Australia.
					</li>
					<li>
						All communications concerning negotiations made by the Parties
						arising out of and in connection with this dispute resolution clause
						are confidential and to the extent possible, must be treated as
						"without prejudice" negotiations for the purpose of applicable laws
						of evidence.
					</li>
					<li>
						If 2 months have elapsed after the start of a mediation of the
						Dispute and the Dispute has not been resolved, either Party may ask
						the mediator to terminate the mediation and the mediator must do so.
					</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">
					16. Venue and Jurisdiction
				</h3>
				<p>
					The Services offered by Kitched is intended to be viewed by residents
					of Australia. In the event of any dispute arising out of or in
					relation to the Website, you agree that the exclusive venue for
					resolving any dispute shall be in the courts of New South Wales.
				</p>

				<h3 className="text-lg font-semibold mt-4">17. Governing Law</h3>
				<p>
					The Terms are governed by the laws of New South Wales. Any dispute,
					controversy, proceeding or claim of whatever nature arising out of or
					in any way relating to the Terms and the rights created hereby shall
					be governed, interpreted and construed by, under and pursuant to the
					laws of New South Wales, Australia without reference to conflict of
					law principles, notwithstanding mandatory rules. The validity of this
					governing law clause is not contested. The Terms shall be binding to
					the benefit of the parties hereto and their successors and assigns.
				</p>

				<h3 className="text-lg font-semibold mt-4">18. Severance</h3>
				<p>
					If any part of these Terms is found to be void or unenforceable by a
					Court of competent jurisdiction, that part shall be severed and the
					rest of the Terms shall remain in force.
				</p>

				<h2 className="text-2xl font-semibold mb-4 pt-8">
					WEBSITE PRIVACY POLICY
				</h2>

				<p>
					This Privacy Policy applies to all personal information collected by
					Kitched (we, us or our) via the website located at www.kitched.com
					(Website).
				</p>

				<h3 className="text-lg font-semibold mt-4">
					What information do we collect?
				</h3>
				<p>
					The kind of Personal Information that we collect from you will depend
					on how you use the website. The Personal Information which we collect
					and hold about you may include:
				</p>
				<ul className="list-disc list-inside">
					<li>login credentials</li>
					<li>payment details</li>
					<li>email address</li>
					<li>name</li>
					<li>phone number</li>
					<li>address</li>
					<li>dietary needs and preferences</li>
					<li>social media profiles</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">Types of information</h3>
				<p>
					The Privacy Act 1998 (Cth) (Privacy Act) defines types of information,
					including Personal Information and Sensitive Information.
				</p>
				<ul className="list-disc list-inside">
					<li>
						Personal Information means information or an opinion about an
						identified individual or an individual who is reasonably
						identifiable:
					</li>
					<ul className="list-disc list-inside">
						<li>whether the information or opinion is true or not; and</li>
						<li>
							whether the information or opinion is recorded in a material form
							or not.
						</li>
					</ul>
					<li>
						If the information does not disclose your identity or enable your
						identity to be ascertained, it will in most cases not be classified
						as “Personal Information” and will not be subject to this privacy
						policy.
					</li>
					<li>
						Sensitive Information is defined in the Privacy Act as including
						information or opinion about such things as an individual's racial
						or ethnic origin, political opinions, membership of a political
						association, religious or philosophical beliefs, membership of a
						trade union or other professional body, criminal record or health
						information.
					</li>
					<li>
						Sensitive Information will be used by us only:
						<ul className="list-disc list-inside">
							<li>for the primary purpose for which it was obtained;</li>
							<li>
								for a secondary purpose that is directly related to the primary
								purpose; and
							</li>
							<li>with your consent or where required or authorised by law.</li>
						</ul>
					</li>
				</ul>

				<h3 className="text-lg font-semibold mt-4">
					How we collect your Personal Information
				</h3>
				<p>
					We may collect Personal Information from you whenever you input such
					information into the Website, related app or provide it to Us in any
					other way.
				</p>
				<p>
					We may also collect cookies from your computer which enable us to tell
					when you use the Website and also to help customise your Website
					experience. As a general rule, however, it is not possible to identify
					you personally from our use of cookies.
				</p>
				<p>
					We generally don’t collect Sensitive Information, but when we do, we
					will comply with the preceding paragraph.
				</p>
				<p>
					Where reasonable and practicable we collect your Personal Information
					from you only. However, sometimes we may be given information from a
					third party, in cases like this we will take steps to make you aware
					of the information that was provided by a third party.
				</p>

				<h3 className="text-lg font-semibold mt-4">Purpose of collection</h3>
				<p>
					We collect Personal Information to provide you with the best service
					experience possible on the Website and keep in touch with you about
					developments in our business.
				</p>
				<p>
					We customarily only disclose Personal Information to our service
					providers who assist us in operating the Website. Your Personal
					Information may also be exposed from time to time to maintenance and
					support personnel acting in the normal course of their duties.
				</p>
				<p>
					By using our Website, you consent to the receipt of direct marketing
					material. We will only use your Personal Information for this purpose
					if we have collected such information direct from you, and if it is
					material of a type which you would reasonably expect to receive from
					use. We do not use sensitive Personal Information in direct marketing
					activity. Our direct marketing material will include a simple means by
					which you can request not to receive further communications of this
					nature, such as an unsubscribe button link.
				</p>

				<h3 className="text-lg font-semibold mt-4">
					Security, Access and correction
				</h3>
				<p>
					We store your Personal Information in a way that reasonably protects
					it from unauthorised access, misuse, modification or disclosure. When
					we no longer require your Personal Information for the purpose for
					which we obtained in, we will take reasonable steps to destroy and
					anonymise or de-identify it. Most of the Personal Information that is
					stored in our client files and records will be kept for a maximum of 7
					years to fulfill our record keeping obligations.
				</p>
				<p>
					The Australian Privacy Principles:
					<ul className="list-disc list-inside">
						<li>
							permit you to obtain access to the Personal Information we hold
							about you in certain circumstances (Australian Privacy Principle
							12); and
						</li>
						<li>
							allow you to correct inaccurate Personal Information subject to
							certain exceptions (Australian Privacy Principle 13).
						</li>
					</ul>
				</p>
				<p>
					Where you would like to obtain such access, please contact us in
					writing on the contact details set out at the bottom of this privacy
					policy.
				</p>

				<h3 className="text-lg font-semibold mt-4">Complaint procedure</h3>
				<p>
					If you have a complaint concerning the manner in which we maintain the
					privacy of your Personal Information, please contact us as on the
					contact details set out at the bottom of this policy. All complaints
					will be considered by Lianna Patete and we may seek further
					information from you to clarify your concerns. If we agree that your
					complaint is well founded, we will, in consultation with you, take
					appropriate steps to rectify the problem. If you remain dissatisfied
					with the outcome, you may refer the matter to the Office of the
					Australian Information Commissioner.
				</p>

				<h3 className="text-lg font-semibold mt-4">Overseas transfer</h3>
				<p>
					Your Personal Information may be transferred to recipients located in
					Argentina. You consent to us transferring your Personal Information to
					Argentina in the knowledge that if your Personal Information is
					mishandled in that jurisdiction, we disclaim responsibility and you
					will not have a remedy under Australian law.
				</p>

				<h3 className="text-lg font-semibold mt-4">GDPR</h3>
				<p>
					In some circumstances, the European Union General Data Protection
					Regulation (GDPR) provides additional protection to individuals
					located in Europe. Where this is the case, there may be additional
					rights and remedies available to you under the GDPR if your Personal
					Information is handled in a manner inconsistent with that law.
				</p>

				<h3 className="text-lg font-semibold mt-4">
					How to contact us about privacy
				</h3>
				<p>
					If you have any queries, or if you seek access to your Personal
					Information, or if you have a complaint about our privacy practices,
					you can contact us through: hello@kitched.com.
				</p>
			</div>
		</PopUp>
	);
};
