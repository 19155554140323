import { useMutation, useQueryClient } from '@tanstack/react-query';

import { User } from '@/context/interfaces';
import { notifyError, notifyInfo } from '@/helper/notifications';
import { deleteUserFollowChef, putUserFollowChef } from '@/service/api/user';

interface FollowData {
	userId: number;
	chefId: number;
}

const usePutUserFollowChef = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation<User, Error, FollowData, User>({
		mutationFn: async (data: FollowData) => {
			const { userId, chefId } = data;
			return putUserFollowChef(userId, chefId);
		},
		onError: (context) => {
			if (context) {
				queryClient.setQueryData(['user'], context);
			}
			notifyError('Error when following the chef');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
	});

	return mutation;
};

const useDeleteUserUnfollowChef = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation<User, Error, FollowData, User>({
		mutationFn: async (data: FollowData) => {
			const { userId, chefId } = data;
			return deleteUserFollowChef(userId, chefId);
		},
		onError: (context) => {
			if (context) {
				queryClient.setQueryData(['user'], context);
			}
			notifyError('Failed to unfollow the chef');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
		onSuccess: () => notifyInfo('Unfollow successful'),
	});

	return mutation;
};

export { usePutUserFollowChef, useDeleteUserUnfollowChef };
