import { flexRender } from '@tanstack/react-table';
import { useState } from 'react';

import AddNewChefModal from '@/components/admin/chef/AddNewChefModal';
import { PopUp } from '@/components/common/PopUp';
import useChefTable from '@/hooks/chef/useChefTable';

const ChefTable = () => {
	const [open, setOpen] = useState(false);
	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);
	const { table, isDeletingChef, isLoading, renderLoading, renderError } =
		useChefTable();

	return (
		<div className="px-6">
			<div className="flex mb-4 align-middle items-center justify-between">
				<div className="flex items-center">
					<h1 className="text-2xl mr-2">Current creators</h1>
				</div>
				<PopUp open={open} onClose={closeModal} topCss="20vh">
					<AddNewChefModal closeModal={closeModal} />
				</PopUp>

				<button
					className="rounded bg-brandOrange text-white p-2 font-semibold hover:bg-emerald-600 disabled:bg-emerald-300 disabled:cursor-not-allowed"
					disabled={isDeletingChef || isLoading}
					data-cy="add-chef-button"
					onClick={openModal}
				>
					Add a new creator
				</button>
			</div>
			<div className="relative overflow-x-auto min-h-[250px]">
				{renderLoading()}
				<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id} data-cy="table-header">
								{headerGroup.headers.map((header) => (
									<th key={header.id} className="px-6 py-3">
										{flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr
								key={row.id}
								data-cy="chef-row"
								className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
							>
								{row.getVisibleCells().map((cell) => (
									<td
										key={cell.id}
										className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-white"
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}

						{renderError()}
					</tbody>
				</table>
			</div>

			<div className="gap-2 flex items-center justify-center mt-4">
				<button
					onClick={() => table.previousPage()}
					className="p-1 rounded bg-slate-500 text-white hover:bg-slate-600 disabled:opacity-50 disabled:hover:bg-slate-500"
					disabled={!table.getCanPreviousPage()}
					data-cy="previous-page-button"
				>
					Previous
				</button>
				<button
					onClick={() => table.nextPage()}
					className="p-1 rounded bg-slate-500 text-white hover:bg-slate-600 disabled:opacity-50 disabled:hover:bg-slate-500"
					disabled={!table.getCanNextPage()}
					data-cy="next-page-button"
				>
					Next
				</button>
				<span data-cy="page-indicator">
					Page{' '}
					<strong>
						{(table.getState().pagination.pageIndex + 1).toString()}
					</strong>{' '}
					of <strong>{table.getPageCount().toString()}</strong>
				</span>
			</div>
		</div>
	);
};

export default ChefTable;
