import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { createCollection } from '@/service/api/collection';

export const useCreateCollection = (closeModal: () => void) => {
	const queryClient = useQueryClient();

	const createCollectionMutation = useMutation({
		mutationFn: createCollection,
		onSuccess: () => {
			notifySuccess('Collection created successfully');
			queryClient.invalidateQueries({ queryKey: ['collections'] });
			closeModal();
		},
		onError: () => notifyError('Collection creation failed'),
	});

	return {
		createCollectionMutation,
	};
};
