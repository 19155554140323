import { useMutation } from '@tanstack/react-query';

import { notifyError } from '@/helper/notifications';
import { uploadRecipePicture } from '@/service/api/recipe';

export const useUploadRecipePicture = () => {
	const uploadRecipePictureMutation = useMutation({
		mutationFn: uploadRecipePicture,
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { uploadRecipePictureMutation };
};
