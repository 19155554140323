import ReactPlayer from 'react-player';

import { CdnImage } from '../images/CdnImage';

import { IInstagramInformation } from '@/interface/recipe.interfaces';

export interface ReactPlayerComponentProps {
	mediaInfo: IInstagramInformation;
}

export const ReactPlayerComponent = ({
	mediaInfo,
}: ReactPlayerComponentProps) => {
	const isVideo = mediaInfo?.media_type === 'VIDEO';
	const mediaUrl = mediaInfo?.media_url || '/images/recipe-pasta.png';

	return (
		<div data-cy="video-player-container" className="z-10">
			{isVideo ? (
				<div
					className={`w-[200px] 2xl:w-[235px] h-[300px] rounded-3xl overflow-hidden relative`}
				>
					<ReactPlayer
						data-cy="home-background-img"
						url={mediaUrl}
						playing={true}
						loop
						playbackRate={0.75}
						muted
						controls={false}
						width={'100%'}
						height={'fit-content'}
						style={{ objectFit: 'cover', position: 'absolute' }}
					/>
				</div>
			) : (
				<CdnImage
					src={mediaUrl}
					alt="recipe-image"
					className={`w-[200px] h-[300px] rounded-3xl object-cover`}
				/>
			)}
		</div>
	);
};
