import { Images } from '@/components/sign-up/images/Images';
import { FormSignUp } from '@/pages/sign-up/form/FormSignUp';

export const SignUp = () => {
	return (
		<section className="min-w-auto max-w-[992px] h-[770px] mb-5 px-5 mx-auto flex justify-between rounded-3xl sm-custom:justify-center">
			<div className="gap-14 flex">
				<div className="hidden ml-custom:block">
					<Images />
				</div>
				<div className="w-full lg:w-1/2 px-4">
					<FormSignUp />
				</div>
			</div>
		</section>
	);
};
