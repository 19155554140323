export const enum RECIPE_ERRORS_MESSAGES {
	NOT_FOUND = 'We are sorry, but the recipe you are looking for does not exist.',
	GET_ALL_RECIPE_ERROR = 'Oops! There was a problem loading the recipes. Please try again later!',
	GET_RECIPE_BY_ID_ERROR = 'Oops! There was a problem loading the recipe. Please try again later!',
	GET_RECIPE_BY_NAME_ERROR = 'Oops! There was an error serching the recipe. Please try again later!',
	GET_RECIPES_TAG_ERROR = 'Oops! An error occurred while loading the recipe with this tag. Please try again later!',
	CREATE_RECIPE_ERROR = 'Oops! There was a problem creating the recipe. Please try again later!',
	UPDATE_RECIPE_ERROR = 'Oops! There was a problem updating the recipe. Please try again later!',
	DELETE_RECIPE_ERROR = 'Oops! There was a problem deleting the recipe. Please try again later!',
	INGREDIENTS_CHECK_ERROR = 'Error checking ingredients',
}
