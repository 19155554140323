import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { updateCollection } from '@/service/api/collection';

export const useUpdateCollection = () => {
	const updateCollectionMutation = useMutation({
		mutationFn: updateCollection,
		onSuccess: () => {
			notifySuccess('Collection updated successfully');
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { updateCollectionMutation };
};
