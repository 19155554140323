import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import hidePasswordImg from '@/assets/svg/hide-password.svg';
import showPasswordImg from '@/assets/svg/show-password.svg';
import DropdownMenuComingSoon from '@/components/common/DropdownMenuComingSoon';
import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { SocialIcons } from '@/components/sign-up/social-icons/SocialIcons';
import { Chef } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { REGISTER_SUCCESSFUL } from '@/error/authentication.notify.messages';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { signupValidationSchema } from '@/helper/validations/signup.validations';
import { useGetAllChefsNames } from '@/hooks/chef/useGetAllChefsNames';
import { usePagination } from '@/hooks/usePagination';
import { TermsAndConditionsModal } from '@/pages/sign-up/TAC/modal/TermsAndConditionsModal';
import { createUser } from '@/service/api/user';

import kitchedLogo from '@assets/svg/kitched-red-logo.svg';

export const FormSignUp = () => {
	const { login } = useAuth();
	const navigate = useNavigate();
	const validationSchema = signupValidationSchema();
	const [openTermsModal, setOpenTermsModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [showingChefs, setShowingChefs] = useState<Chef[]>([]);
	const [skip, setSkip] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [openReferralsModal, setOpenReferralsModal] = useState(false);

	const handleReferralsModalState = () =>
		setOpenReferralsModal(!openReferralsModal);
	const onOpenTermsModal = () => setOpenTermsModal(true);
	const onCloseTermsModal = () => setOpenTermsModal(false);

	const take = 50;
	const chefReferringName = localStorage.getItem('chef');
	const [chefRefersUser, setChefRefersUser] = useState<Chef | null>(null);

	const {
		chefs,
		isLoadingChefs,
		errorChefs,
		isErrorLoadingChefs,
		totalEntities,
		refetchChefs,
	} = useGetAllChefsNames({ take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	const formik = useFormik({
		initialValues: {
			name: '',
			last_name: '',
			email: '',
			password: '',
			confirmPassword: '',
			agreeTerms: false,
			referringChefUser: chefRefersUser?.id || undefined,
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				await createUser(values);

				notifySuccess(REGISTER_SUCCESSFUL);
				// attempt to login with the new user data
				await login(values);

				navigate('/home');
			} catch (error: unknown) {
				notifyError(`${error}`);
			} finally {
				setSubmitting(false);
				handleReferralsModalState();
			}
		},
	});

	useEffect(() => {
		if (chefs) {
			setShowingChefs((prevChefs) => {
				const newChefs = chefs.data.filter(
					(chef) => !prevChefs.some((prevChef) => prevChef.id === chef.id),
				);
				return [...prevChefs, ...newChefs];
			});
			setHasMore(currentPage < totalPages);
		}
	}, [chefs]);

	useEffect(() => {
		refetchChefs();
	}, [skip]);

	useEffect(() => {
		formik.setFieldValue('referringChefUser', chefRefersUser?.id || null);
	}, [chefRefersUser]);

	useEffect(() => {
		if (chefs?.data) {
			const defaultChef = chefs.data.find(
				(chef) =>
					(chef.user.name.trim() + ' ' + chef.user.last_name.trim()).trim() ===
					chefReferringName,
			);

			if (defaultChef) {
				handleChefChange(defaultChef);
			} else if (currentPage < totalPages) {
				setSkip((prevSkip) => prevSkip + take);
			}
		}
	}, [chefs]);

	useEffect(() => {
		refetchChefs();
	}, [skip]);

	const handleChefChange = (chef: Chef) => {
		setChefRefersUser(chef);
	};

	const handleConfirm = () => {
		formik.handleSubmit();
	};

	if (isLoadingChefs && chefs?.data.length === 0) {
		return (
			<div className="flex justify-center items-center">
				<Loader size={4} />
			</div>
		);
	}

	return (
		<div className="w-[320px] mt-20 mx-[30px] flex flex-col">
			<div className="w-[271px] h-[92px] text-5xl text-center mx-auto ">
				<h1 className="text-[52px] w-full font-semibold -mt-9 flex flex-col items-center">
					<p>Sign Up to</p>
					<img
						src={kitchedLogo}
						alt="logo"
						className="mt-3 w-[190px] h-[45px]"
					/>
				</h1>
			</div>

			<SocialIcons />

			<form
				className="flex flex-col gap-[42px] w-[320px] h-[420px] max-w-lg mx-auto md:max-w-md lg:max-w-lg xl:max-w-xl mt-2"
				onSubmit={formik.handleSubmit}
			>
				<div className="flex flex-col gap-[16px] w-[320px]">
					<div className="relative">
						<input
							type="text"
							name="name"
							placeholder="Enter your first name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="name">NAME</label>
						</div>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.name && formik.errors.name && (
								<div>{formik.errors.name}</div>
							)}
						</div>
					</div>

					<div className="relative">
						<input
							type="text"
							name="last_name"
							placeholder="Enter your last name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.last_name}
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="last_name">LAST NAME</label>
						</div>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.last_name && formik.errors.last_name && (
								<div>{formik.errors.last_name}</div>
							)}
						</div>
					</div>

					<div className="relative">
						<input
							type="email"
							name="email"
							placeholder="Enter your email"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.email}
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="email">EMAIL</label>
						</div>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.email && formik.errors.email && (
								<div>{formik.errors.email}</div>
							)}
						</div>
					</div>
					<div className="relative">
						<div className="flex">
							<input
								type={showPassword ? 'text' : 'password'}
								name="password"
								placeholder="Enter your password"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.password}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50 pr-10"
								data-cy="signup-password-input"
							/>
							<button
								type="button"
								onClick={() => setShowPassword(!showPassword)}
								className="absolute right-3 top-1/2 transform -translate-y-1/2"
							>
								<img
									src={showPassword ? showPasswordImg : hidePasswordImg}
									alt="password-icon"
									className="w-[20px] h-[20px]"
								/>
							</button>
						</div>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="password">PASSWORD</label>
						</div>
						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.password && formik.errors.password && (
								<div data-cy="signup-password-error">
									{formik.errors.password}
								</div>
							)}
						</div>
					</div>

					<div className="relative">
						<div className="flex">
							<input
								type={showConfirmPassword ? 'text' : 'password'}
								name="confirmPassword"
								placeholder="Confirm your password"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.confirmPassword}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50 pr-10"
							/>
							<button
								type="button"
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}
								className="absolute right-3 top-1/2 transform -translate-y-1/2"
							>
								<img
									src={showConfirmPassword ? showPasswordImg : hidePasswordImg}
									alt="password-icon"
									className="w-[20px] h-[20px]"
								/>
							</button>
						</div>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="confirmPassword">CONFIRM YOUR PASSWORD</label>
						</div>
						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.confirmPassword &&
								formik.errors.confirmPassword && (
									<div>{formik.errors.confirmPassword}</div>
								)}
						</div>
					</div>

					<div className="relative">
						<div className="flex items-center gap-2">
							<input
								type="checkbox"
								name="agreeTerms"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								checked={formik.values.agreeTerms}
								className="w-5 h-4 rounded-sm accent-brandRed"
							/>
							<label
								htmlFor="agreeTerms"
								className="text-[13px] -mt-[3px] text-gray-90 cursor-pointer hover:underline"
								onClick={onOpenTermsModal}
							>
								I agree to the Terms & Conditions
							</label>
						</div>
						{formik.touched.agreeTerms && formik.errors.agreeTerms && (
							<div className="text-alertRed pl-7 text-[9px]  -mt-1">
								{formik.errors.agreeTerms}
							</div>
						)}
					</div>

					<button
						data-cy="pop-up-referrals"
						type="button"
						className="disabled:cursor-not-allowed w-[156px] h-[52px] rounded-full bg-brandRed text-white flex justify-center items-center mx-auto  transition-all hover:text-brandRed hover:bg-white hover:ring-2 hover:ring-brandRed hover:cursor-pointer"
						onClick={handleReferralsModalState}
						disabled={formik.isSubmitting || !formik.isValid}
					>
						<span className="text-[15px] font-semibold">Sign Up</span>
						<span className="p-[4px] bg-brandRed rounded-full border-2 border-white absolute ml-36">
							<img
								src={arrowUpRight}
								alt="arrow-icon"
								style={{ width: '24px' }}
							/>
						</span>
					</button>
				</div>
				<PopUp
					open={openReferralsModal}
					onClose={handleReferralsModalState}
					topCss="10vh"
				>
					<div className="h-[300px] py-4 space-y-4 items-center justify-center text-center ">
						<p className="text-xl">Were you referred by a creator?</p>
						<small className="text-slate-400 text-sm text-center items-center justify-center font-helveticaNeue">
							Please select who referred you
						</small>

						<DropdownMenuComingSoon
							options={showingChefs}
							selectedValues={chefRefersUser ? [chefRefersUser] : []}
							label="Select your Creator"
							formStyle={true}
							onChange={handleChefChange}
							hasMore={hasMore}
							currentPage={currentPage}
							totalPages={totalPages}
							onHandleNext={onHandleNext}
							onHandlePrevious={onHandlePrevious}
							renderItem={(chef) => (
								<span>
									{chef.user.name} {chef.user.last_name}
								</span>
							)}
						/>

						{isErrorLoadingChefs && (
							<div
								className="flex mt-20 justify-center items-center  flex-col w-full   gap-6"
								data-cy="explore-recipes-error"
							>
								<p className="text-black text-3xl sm:text-4xl text-center font-sans font-bold tracking-tighter w-[50%]">
									{errorChefs?.message}
								</p>
							</div>
						)}

						<button
							data-cy="sign-up-submit"
							type="submit"
							className="w-[156px] h-[52px] rounded-full bg-brandRed text-white flex justify-center items-center mx-auto transition-all hover:text-brandRed hover:bg-white hover:ring-2 hover:ring-brandRed hover:cursor-pointer disabled:cursor-not-allowed"
							disabled={formik.isSubmitting || !formik.isValid}
							onClick={handleConfirm}
						>
							{formik.isSubmitting ? (
								<Loader size={1.4} />
							) : (
								<span className="text-[15px] font-semibold">Sign Up</span>
							)}
						</button>
					</div>
				</PopUp>
			</form>

			<TermsAndConditionsModal
				open={openTermsModal}
				onClose={onCloseTermsModal}
			/>

			<div className="flex items-center pt-10">
				<p className="p-2 pl-3 md:p-0 md:mr-1 text-sm md:text-md text-loginTextGrey mb-0 whitespace-nowrap">
					Already have a Kitched account?
				</p>
				<span className="ml-2 text-sm md:text-md cursor-pointer text-brandRed hover:underline whitespace-nowrap">
					<Link to="/login" data-cy="log-in-redirect">
						Log In
					</Link>
				</span>
			</div>
		</div>
	);
};
