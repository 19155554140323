import { Bounce, toast } from 'react-toastify';

export const notifyError = (message: string) => {
	return toast.error(message, {
		position: 'bottom-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	});
};

export const notifySuccess = (message: string) => {
	return toast.success(message, {
		position: 'bottom-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	});
};

export const notifyInfo = (message: string) => {
	return toast.info(message, {
		position: 'bottom-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'colored',
	});
};

export const notifyIngredients = (component: JSX.Element) => {
	return toast.info(component, {
		position: 'top-center',
		autoClose: false,
		hideProgressBar: true,
		closeOnClick: false,
		draggable: true,
		theme: 'light',
		icon: false,
		transition: Bounce,
	});
};
