import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Table from '../../components/common/Table';

import { Loader } from '@/components/common/Loader';
import { useGetAllEarlyUsers } from '@/hooks/early-users/useGetAllEarlyUsers';
import { EarlyAccessUserDto } from '@/interface/early-access-user.interfaces';

const { Tr, Th, Td, THead, TBody } = Table;

export const EarlyUsersTable = () => {
	const [earlyUsers, setEarlyUsers] = useState<EarlyAccessUserDto[]>([]);
	const [skip, setSkip] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const take = 18;

	const { allEarlyUsers, isLoadingAllEarlyUsers, isErrorAllEarlyUsers } =
		useGetAllEarlyUsers(take, skip);

	useEffect(() => {
		if (allEarlyUsers) {
			setEarlyUsers((prevEarlyUser) => [
				...prevEarlyUser,
				...allEarlyUsers.data,
			]);
			setHasMore(allEarlyUsers.data.length > 0);
		}
	}, [allEarlyUsers]);

	const fetchMoreData = () => {
		if (!isLoadingAllEarlyUsers && hasMore) {
			setSkip((prevSkip) => prevSkip + take);
		}
	};

	if (isLoadingAllEarlyUsers && earlyUsers.length === 0) {
		return (
			<div className="flex justify-center items-center">
				<Loader size={4} />
			</div>
		);
	}

	if (isErrorAllEarlyUsers) {
		return (
			<div
				data-cy="explore-early-users-error"
				className="flex justify-center text-2xl items-center text-alertRed mt-5 mb-5"
			>
				<p>Error loading early access users</p>
			</div>
		);
	}

	if (earlyUsers.length === 0) {
		return (
			<div className="flex justify-center text-3xl/[34px] items-center pt-10">
				<p>No early access users yet</p>
			</div>
		);
	}

	return (
		<div className="mx-10 mt-10">
			<InfiniteScroll
				dataLength={earlyUsers.length}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={
					<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl">
						Loading more early access users...
					</p>
				}
				endMessage={
					<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl">
						No more early access users
					</p>
				}
			>
				<Table>
					<THead>
						<Tr>
							<Th>Name</Th>
							<Th>Email</Th>
							<Th>Wants to become a creator</Th>
							<Th>Referring Chef</Th>
						</Tr>
					</THead>
					<TBody>
						{earlyUsers?.map((earlyUser: EarlyAccessUserDto, index) => (
							<Tr key={index}>
								<Td>{earlyUser.name}</Td>
								<Td>{earlyUser.email}</Td>
								<Td>{earlyUser.wantsToBeCreator ? 'Yes' : 'No'}</Td>
								<Td>
									{earlyUser.referringChef
										? `${earlyUser.referringChef?.user.name} ${earlyUser.referringChef?.user.last_name}`
										: '-'}
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</InfiniteScroll>
		</div>
	);
};
