import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { upperCaseFirstLetter } from '../../helper/upperCaseFirstLetter';

import chevronDownBlackIcon from '@/assets/svg/chevron-down-black.svg';

interface DropdownMenuProps<T> {
	options: T[];
	selectedValues: T[];
	label: string;
	onChange: (value: T) => void;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

function DropdownMenu<T>({
	options,
	selectedValues,
	label,
	onChange,
}: DropdownMenuProps<T>) {
	const handleItemClick = (item: T) => {
		onChange(item);
	};

	return (
		<Menu as="div" className="inline-block text-left w-full">
			<div className="flex flex-col items-center w-full">
				<div className="relative w-full flex justify-center rounded-3xl bg-slate-100 hover:bg-gray-300 border-none ring-none shadow-sm">
					<div className="absolute  left-5 top-2.5 text-[9px] text-loginTextGrey">
						<label htmlFor={label}>{label}</label>
					</div>
					<Menu.Button
						data-cy={`button-${label.toLowerCase().replace(' ', '-')}-menu`}
						className="w-full inline-flex justify-between gap-x-1.5 pl-1 pr-3 text-sm font-semibold text-black px-6 pt-1"
					>
						{selectedValues.length === 0 && (
							<div className="pl-3.5 py-2 lg:pt-3.5">
								<span className="block pl-1 pt-2 text-left text-sm">
									Select your {label.toLowerCase()}
								</span>
							</div>
						)}
						{selectedValues.length > 0 && (
							<div className="pl-3.5 pt-2 lg:pt-3.5">
								{selectedValues.map((item, index) => (
									<span
										data-cy={`selected-${item}`}
										key={index}
										className="block pl-1 py-2 text-left text-sm"
									>
										{upperCaseFirstLetter(item as string)}
									</span>
								))}
							</div>
						)}
						<img src={chevronDownBlackIcon} className="mt-2" />
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute max-h-52 w-[90%] lg:w-[80%] z-10 mt-14 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black bg-slate-100 border-none overflow-y-auto global-scrollbar">
						<div className="py-1">
							{options?.map((option, index) => (
								<Menu.Item key={index}>
									{({ active }) => (
										<div
											data-cy={`update-${label
												.toLowerCase()
												.replace(' ', '-')}-${String(option)
												.toLowerCase()
												.replace(' ', '-')}`}
											className={classNames(
												active ? 'bg-gray-300/50' : '',
												'flex items-center pl-2 cursor-pointer',
												selectedValues.includes(option)
													? 'opacity-50 cursor-not-allowed'
													: '',
											)}
											onClick={() => handleItemClick(option)}
										>
											<span className="px-2 py-1 text-sm">
												{upperCaseFirstLetter(option as string)}
											</span>
										</div>
									)}
								</Menu.Item>
							))}
						</div>
					</Menu.Items>
				</Transition>
			</div>
		</Menu>
	);
}

export default DropdownMenu;
