import { ErrorMessage, Field } from 'formik';

type Props = {
	label: string;
	id: string;
	name: string;
	type: string;
	placeholder: string;
	disabled?: boolean;
	options?: string[];
	rows?: number;
};

const fieldComponents: Record<string, (props: Props) => React.ReactElement> = {
	select: (props) => (
		<Field
			as="select"
			className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
			{...props}
		>
			{props.options &&
				props.options.map((option, index) => (
					<option key={`${option}-${index + 1}`} value={option}>
						{option}
					</option>
				))}
		</Field>
	),
	radio: (props) => (
		<>
			{props.options &&
				props.options.map((option, index) => (
					<span
						key={`${option}-${index + 1}`}
						className="ml-2 text-sm font-medium text-gray-900"
					>
						<Field
							value={option}
							className="mr-1"
							{...props}
							id={`${props.name}${index === 0 ? '' : index}`}
						/>
						{option}
					</span>
				))}
		</>
	),
	textarea: (props) => (
		<Field
			as="textarea"
			className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
			{...props}
		/>
	),
	default: (props) => (
		<Field
			className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
			{...props}
		/>
	),
};

export const FormField = (props: Props) => (
	<div className="w-full mb-4">
		<label
			htmlFor={props.id}
			className="block mb-2 text-sm font-medium text-gray-900"
		>
			{props.label}
		</label>

		{fieldComponents[props.type]?.(props) || fieldComponents.default(props)}

		<ErrorMessage
			data-cy="form-field-error"
			name={props.name}
			component="div"
			className="text-red-500 text-sm mt-1"
		/>
	</div>
);
