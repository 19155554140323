import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetUserLikedRecipes } from '../user/useGetUserLikedRecipes';

import { User } from '@/context/interfaces';
import { useAuth } from '@/context/useAuth';
import { notifyError } from '@/helper/notifications';
import {
	useDeleteUserFavouriteRecipe,
	usePutUserFavouriteRecipe,
} from '@/hooks/user-favourite/useUserFavouriteRecipe';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { IRecipe } from '@/interface/recipe.interfaces';

interface FavouriteRecipeCallbacks {
	onSuccess?: () => void;
}

export const useManageFavouriteRecipe = (
	recipe: IRecipe,
	paginationOptions?: { take: number; skip: number },
) => {
	const [isFavorite, setIsFavorite] = useState(false);
	const { user, setUser } = useAuth();
	const favouriteRecipeMutation = usePutUserFavouriteRecipe();
	const deleteFavouriteRecipeMutation = useDeleteUserFavouriteRecipe();
	const { refetchUserLikedRecipes } = useGetUserLikedRecipes(
		paginationOptions || defaultPortfolioPaginationOptions,
	);
	const location = useLocation();
	const isProfile =
		location.pathname === '/user/me' || location.pathname === '/chef/profile';
	const queryClient = useQueryClient();

	const { id } = recipe;

	useEffect(() => {
		if (user) {
			const favorite = !!user.favoritesRecipes?.find(
				(recipe) => recipe.id === id,
			);
			setIsFavorite(favorite);
		}
	}, [user, id]);

	const addFavouriteRecipe = (callbacks: FavouriteRecipeCallbacks = {}) => {
		favouriteRecipeMutation.mutate(
			{ userId: user?.id || 0, recipeId: id },
			{
				onSuccess: () => {
					setIsFavorite(true);
					setUser((previousUser) => {
						if (!previousUser) return null;

						const updateUser = {
							...previousUser,
							favoritesRecipes: [...previousUser.favoritesRecipes, recipe],
						};

						return updateUser;
					});
					isProfile && refetchUserLikedRecipes();
					if (callbacks.onSuccess) callbacks.onSuccess();
				},

				onError: (error: Error) => {
					notifyError(`${error.message}`);
					setIsFavorite(true);
				},
			},
		);
	};

	const deleteFavouriteRecipe = (callbacks: FavouriteRecipeCallbacks = {}) => {
		deleteFavouriteRecipeMutation.mutate(
			{ userId: user?.id || 0, recipeId: id },
			{
				onSuccess: () => {
					setIsFavorite(false);
					setUser((previousUser) => {
						if (!previousUser) return null;

						const updateUser: User = {
							...previousUser,
							favoritesRecipes:
								previousUser?.favoritesRecipes?.filter(
									(recipe) => recipe.id !== id,
								) || [],
						};
						isProfile &&
							queryClient.invalidateQueries({
								queryKey: [
									'user-liked-recipes',
									paginationOptions?.take,
									paginationOptions?.skip,
								],
							});
						isProfile && refetchUserLikedRecipes();
						return updateUser as User;
					});
					if (callbacks.onSuccess) callbacks.onSuccess();
				},
				onError: (error: Error) => {
					notifyError(`${error.message}`);
					setIsFavorite(true);
				},
			},
		);
	};

	return {
		isFavorite,
		addFavouriteRecipe,
		deleteFavouriteRecipe,
	};
};
