interface ActionButtonsProps {
	buttonOneText: string;
	buttonTwoText: string;
	onButtonClickOne: () => void;
	onButtonClickTwo: () => void;
}

export const ActionButtons = ({
	buttonOneText,
	buttonTwoText,
	onButtonClickOne,
	onButtonClickTwo,
}: ActionButtonsProps) => {
	return (
		<div className="flex flex-col gap-y-2 mt-4">
			<button
				data-cy="action-button-one"
				onClick={onButtonClickOne}
				className="py-2 px-4 text-white rounded-3xl bg-brandGreen transition-colors hover:bg-emerald-400"
			>
				{buttonOneText}
			</button>
			<button
				data-cy="action-button-two"
				onClick={onButtonClickTwo}
				className="py-2 px-4 text-white rounded-3xl bg-alertRed/70 transition-colors hover:bg-alertRed"
			>
				{buttonTwoText}
			</button>
		</div>
	);
};
