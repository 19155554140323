import recipe1 from '@/assets/image/card-1.webp';
import recipe2 from '@/assets/image/card-2.webp';
import recipe3 from '@/assets/image/card-3.webp';
import recipe4 from '@/assets/image/card-4.webp';

export const Images = () => {
	return (
		<section className="w-[480px] h-[738px] mt-4 rounded-[32px] justify-center items-center hidden md:flex bg-brandOrange">
			<img
				src={recipe1}
				alt="Food"
				className="absolute w-[322px] h-[412px] rounded-[32px] z-40"
			/>
			<img
				src={recipe2}
				alt="Food"
				className="w-[322px] h-[412px] rounded-[32px] z-30 rotate-[-8.2deg]"
			/>

			<img
				src={recipe3}
				alt="Food"
				className="absolute w-[322px] h-[412px] rounded-[32px] z-20 rotate-[-17.13deg]"
			/>
			<img
				src={recipe4}
				alt="Food"
				className="absolute w-[322px] h-[412px] rounded-[32px] z-10 rotate-[-28.25deg]"
			/>
		</section>
	);
};
