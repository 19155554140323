import { useState } from 'react';

import { Loader } from '@/components/common/Loader';
import { SelectableList } from '@/components/settings/SelectableList';
import { User } from '@/context/interfaces';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';
import { useUserPreferences } from '@/hooks/user/useUserPreferences ';
import { CountryCuisinePreferences } from '@/interface/user.interfaces';

interface FavouriteCuisinesMenuProps {
	user: User;
}

export const FavouriteCuisinesMenu = ({ user }: FavouriteCuisinesMenuProps) => {
	const { handleCountryCuisineChange, isPendingPreferencesChange } =
		useUserPreferences(user);

	const [selectedItems, setSelectedItems] = useState<Set<number>>(
		new Set(
			user.countryCuisinePreferences?.map((rp) =>
				Object.values(CountryCuisinePreferences).indexOf(rp),
			) || [],
		),
	);

	const handleItemClick = (recipePref: CountryCuisinePreferences) => {
		handleCountryCuisineChange(recipePref);
	};

	return (
		<div className="flex flex-col md:px-8 lg:px-0 2xl:px-8">
			<div className="flex justify-between items-center w-full space-x-5 pb-5">
				<h5 className="text-xl font-semibold text-left w-full whitespace-nowrap">
					Favourite Cuisines
				</h5>
				<div
					data-cy="items-selected"
					className="inline-flex justify-center items-center rounded-full whitespace-nowrap pl-4 pr-4 py-[4px] text-xs font-semibold shadow-sm text-black bg-slate-50 border-none ring-none"
				>
					{selectedItems.size} SELECTED
				</div>
			</div>
			<div className="h-[250px] space-y-4 overflow-y-auto global-scrollbar">
				{isPendingPreferencesChange ? (
					<div className="flex justify-center lg:mt-20">
						<Loader size={2} />
					</div>
				) : (
					<>
						<SelectableList
							items={Object.values(CountryCuisinePreferences)}
							renderItem={(recipePref) => (
								<span className="block px-2 py-2 text-sm">
									{upperCaseFirstLetter(recipePref)}
								</span>
							)}
							selectedItems={selectedItems}
							setSelectedItems={setSelectedItems}
							onItemClick={handleItemClick}
						/>
					</>
				)}
			</div>
		</div>
	);
};
