import { Images } from '@/components/sign-up/images/Images';
import { Form } from '@/pages/log-in/form/Form';

export const LogIn = () => {
	return (
		<section className="  h-[770px] mb-5   flex justify-center rounded-3xl">
			<div className="gap-14 flex">
				<div className="hidden ml-custom:block">
					<Images />
				</div>
				<div>
					<Form />
				</div>
			</div>
		</section>
	);
};
