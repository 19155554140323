import { usePagination } from 'react-instantsearch';

import { NavigationButtons } from '../common/buttons/navigation-button/NavigationButtons';

export const AlgoliaPagination = () => {
	const { currentRefinement, nbPages, refine } = usePagination();
	return (
		<div className="w-full">
			{nbPages > 1 && (
				<NavigationButtons
					currentPage={currentRefinement}
					totalPages={nbPages}
					handleNextClick={() => refine(currentRefinement + 1)}
					handlePrevClick={() => refine(currentRefinement - 1)}
				/>
			)}
		</div>
	);
};
