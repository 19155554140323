import { RecipeDinamicCard } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCard';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { IRecipe } from '@/interface/recipe.interfaces';

interface MasonryColumnProps {
	recipes: IRecipe[] | undefined;
	isReverse?: boolean;
	userHasSubscription?: boolean;
}

export const MasonryColumn = ({
	recipes = [],
	isReverse,
	userHasSubscription,
}: MasonryColumnProps) => {
	return (
		<section className="flex flex-col items-center">
			{recipes[0] ? (
				<RecipeDinamicCard
					height={isReverse ? 'high' : 'normal'}
					recipe={recipes[0]}
					mediaUrl={
						recipes[0].instagramInformation?.media_url ||
						'/images/recipe-pasta.png'
					}
					isVideo={recipes[0].instagramInformation?.media_type === 'VIDEO'}
					thumbnailUrl={recipes[0].instagramInformation?.thumbnail_url}
					userHasSubscription={userHasSubscription}
				/>
			) : (
				<RecipeDinamicCardSkeletond height={isReverse ? 'high' : 'normal'} />
			)}
			{recipes[1] ? (
				<RecipeDinamicCard
					height={isReverse ? 'normal' : 'higher'}
					recipe={recipes[1]}
					mediaUrl={
						recipes[1].instagramInformation?.media_url ||
						'/images/recipe-pasta.png'
					}
					isVideo={recipes[1].instagramInformation?.media_type === 'VIDEO'}
					thumbnailUrl={recipes[1].instagramInformation?.thumbnail_url}
					userHasSubscription={userHasSubscription}
				/>
			) : (
				<RecipeDinamicCardSkeletond height={isReverse ? 'normal' : 'higher'} />
			)}
			{recipes[2] ? (
				<RecipeDinamicCard
					height={isReverse ? 'higher' : 'normal'}
					recipe={recipes[2]}
					mediaUrl={
						recipes[2].instagramInformation?.media_url ||
						'/images/recipe-pasta.png'
					}
					isVideo={recipes[2].instagramInformation?.media_type === 'VIDEO'}
					thumbnailUrl={recipes[2].instagramInformation?.thumbnail_url}
					userHasSubscription={userHasSubscription}
				/>
			) : (
				<RecipeDinamicCardSkeletond height={isReverse ? 'higher' : 'normal'} />
			)}
		</section>
	);
};
