import { useQuery } from '@tanstack/react-query';

import { ICollection } from '@/interface/collection.interfaces';
import { getCollectionByUuid } from '@/service/api/collection';

export const useGetCollectionByUuid = (uuid: string) => {
	const {
		data: collection,
		isLoading,
		isError,
		error,
	} = useQuery<ICollection, Error>({
		queryKey: ['collection', uuid],
		queryFn: () => getCollectionByUuid(uuid),
		enabled: !!uuid,
	});

	return {
		collection,
		isLoading,
		isError,
		error,
	};
};
