import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Chef } from '@/context/interfaces';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { removeChef } from '@/service/api/chef';

export const useDeleteChef = () => {
	const queryClient = useQueryClient();
	const { mutateAsync: deleteChef, isPending: isDeletingChef } = useMutation({
		mutationFn: removeChef,
		onMutate: async (chefId: number) => {
			await queryClient.cancelQueries({ queryKey: ['chefs'] });
			const previousChefs = queryClient.getQueryData<Chef[]>(['chefs']);
			const deletedChef = previousChefs?.find((chef) => chef.id === chefId);

			if (previousChefs) {
				queryClient.setQueryData(
					['chefs'],
					previousChefs.filter((chef) => chef.id !== chefId),
				);
			}
			queryClient.setQueryData(['userByEmail', deletedChef?.user.email], {
				...deletedChef?.user,
				chef: null,
			});

			return { previousChefs, chefId };
		},
		onError: (_err, _variables, context) => {
			if (context?.previousChefs) {
				queryClient.setQueryData(['chefs'], context.previousChefs);
			}
			notifyError('Failed to delete chef');
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['chefs'] });
			notifySuccess('Creator deleted successfully');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['chefs'] });
		},
	});

	return { deleteChef, isDeletingChef };
};
