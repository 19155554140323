import { User } from '@/context/interfaces';

export interface Subscription {
	uuid: string;
	createdAt?: Date;
	updatedAt?: Date;
	deletedAt?: Date;
	customerId: string;
	customerEmail: string;
	user: User;
	subscriptionId: string;
	subscriptionStartDate: Date;
	subscriptionEndDate: Date;
	activePlan: SuscriberPlanEnum;
	isPlanCancelled: boolean;
}

export enum SuscriberPlanEnum {
	NONE = 'none',
	ESSENTIAL = 'essential',
	PRO = 'pro',
}
