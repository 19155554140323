import * as Yup from 'yup';

export const editUserValidationSchema = () => {
	return Yup.object({
		name: Yup.string()
			.matches(
				/^[A-Za-z]+(?: [A-Za-z]+)*$/,
				'First name must contain only letters and single spaces between words',
			)
			.required('First name is required'),
		last_name: Yup.string()
			.matches(
				/^[A-Za-z]+(?: [A-Za-z]+)*$/,
				'Last name must contain only letters and single spaces between words',
			)
			.required('Last name is required'),
		bio: Yup.string().max(500, 'Bio must be at most 500 characters long'),
		socialAccount: Yup.array().of(
			Yup.object().shape({
				username: Yup.string()
					.min(2, 'Username should be at least 2 character long')
					.max(25, 'Username should be less than 25 characters')
					.matches(/^(?!.*\/).*$/, 'Username should not contain /')
					.trim()
					.matches(/^\S*$/, 'Username cannot contain spaces'),
			}),
		),
	});
};
