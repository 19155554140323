import { useState } from 'react';
import { Link } from 'react-router-dom';

import { RenderVideoOrImage } from '../common/recipe/RenderVideoOrImage';

import lockBlackIcon from '@/assets/svg/lock-black-icon.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { CdnImage } from '@/components/images/CdnImage';
import { useAuth } from '@/context/useAuth';
import { HitProps } from '@/interface/algolia.interfaces';

export const AlgoliaHit: React.FC<HitProps> = ({ hit }) => {
	const [play, setPlay] = useState(false);
	const [isVideoFetched, setIsVideoFetched] = useState(false);

	const { fakeUserHasSubscription } = useAuth();
	const isFree = true;
	const dontHaveAccessToRecipe = !fakeUserHasSubscription && !isFree;
	const isVideo = hit.instagramInformation?.media_type === 'VIDEO';
	const hasThumbnail = isVideo && hit.instagramInformation?.thumbnail_url;
	const mediaUrl =
		hit.instagramInformation?.media_url || '/images/recipe-pasta.png';

	const handlePlayToggle = () => {
		setIsVideoFetched(true);
		setPlay(!play);
	};

	return (
		<div className="flex flex-col w-[250px] min-[375px]:w-full 2xl:w-[235px]">
			<div data-cy="standard-recipe-card" className="relative">
				{dontHaveAccessToRecipe && (
					<div
						className={`absolute top-2 left-2 p-2 rounded-full bg-white z-20`}
					>
						<img src={lockBlackIcon} alt="lock" className="w-4" />
					</div>
				)}
				{RenderVideoOrImage({
					isVideoFetched,
					mediaUrl,
					hasThumbnail,
					play,
					isVideo,
					handlePlayToggle: () => {
						handlePlayToggle();
					},
					recipeUuid: hit.objectID,
					recipeTitle: hit.title,
					chefFullName: `${hit.chef?.user?.name || 'Unknown'} ${
						hit.chef?.user?.last_name || 'Unknown'
					}`,
				})}
			</div>

			<div className="w-full mt-2">
				<Link to={`/recipe/detail/${hit.objectID}`}>
					<p
						className="text-xl px-1 font-bold tracking-tight truncate hover:text-brandRed"
						data-cy="card-title"
					>
						{hit.title}
					</p>
				</Link>

				<div className="flex items-center gap-2 mt-1.5 text-xs px-[2px] font-light tracking-normal text-gray-600">
					<CdnImage
						height={20}
						width={20}
						src={hit.chef?.user?.profile_picture || userBlackIcon}
						alt="avatar"
						className="object-cover w-5 h-5 rounded-full"
					/>
					<p className="truncate text-[14px] tracking-[0.02em] font-helveticaNeue">
						{`${hit.chef?.user?.name} ${hit.chef?.user?.last_name}`}
					</p>
				</div>

				<div className="flex flex-wrap mt-2 gap-1.5 px-1 h-10 overflow-hidden">
					{hit.tags?.map((tag, index) => (
						<Link
							data-cy="card-info-tag"
							to={`/search/tag-${tag.name}`}
							key={index}
							className="h-[34px] border-[1px] border-brandRed flex items-center justify-center buttons-shape text-[13px] leading-[13px] text-center font-normal text-brandRed tracking-[0.02em] font-helveticaNeue"
						>
							{tag.name}
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};
