import * as yup from 'yup';

export const createRecipeValidationSchema = yup.object({
	title: yup.string().required('Required').min(3, 'At least 3 characters'),
	description: yup
		.string()
		.required('Description is required')
		.min(8, 'At least 8 characters'),
	ingredients: yup
		.string()
		.required('Required')
		.min(8, 'At least 8 characters'),
	instructions: yup.string().min(8, 'At least 8 characters'),
});

const recipeTagSchema = yup.object({
	name: yup.string().min(3, 'At least 3 characters').required('Required'),
});
const recipeInstructionSchema = yup.object({
	number: yup.number().positive("Number can't be negative"),
	description: yup
		.string()
		.min(3, 'At least 3 characters')
		.required('Description is required'),
});
const recipeIngredientSchema = yup.object({
	name: yup
		.string()
		.min(3, 'At least 3 characters for name')
		.required('Name ingredient is required'),
	unit: yup.string().nullable(),
	quantity: yup.string().nullable(),
});
const nutritionalInformationSchema = yup.object({
	calories: yup.number().positive("Number can't be negative"),
	fat: yup.number().positive("Number can't be negative"),
	protein: yup.number().positive("Number can't be negative"),
	carbohydrates: yup.number().positive("Number can't be negative"),
});

export const editRecipeValidationSchema = yup.object({
	title: yup
		.string()
		.min(3, 'At least 3 characters')
		.required('Title is required'),
	description: yup
		.string()
		.min(3, 'At least 3 characters')
		.required('Description is required'),
	time: yup
		.string()
		.oneOf(['15', '30', '45', '60', '90', '120', '>120'], 'Invalid time value')
		.default('15'),
	difficulty: yup
		.string()
		.oneOf(['easy', 'medium', 'hard'], 'Invalid difficulty level')
		.default('easy'),
	servings: yup
		.string()
		// Temporarily use 1 character as OpenAI occasionally returns values outside this range.
		.min(1, 'At least 1 character')
		// .oneOf(['1', '2', '3', '4', '5', '6', '7', '>7'], 'Invalid servings value')
		.default('1'),
	tags: yup.array().of(recipeTagSchema),
	instructions: yup.array().of(recipeInstructionSchema),
	ingredients: yup.array().of(recipeIngredientSchema),
	nutritionalInformation: nutritionalInformationSchema,
});
