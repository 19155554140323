import { useMutation } from '@tanstack/react-query';

import { notifyError } from '@/helper/notifications';
import { gptInstagramPostCheck } from '@/service/api/instagram';

export const useGptInstagramPostCheck = () => {
	const gtpInstagramPostCheckMutation = useMutation({
		mutationFn: gptInstagramPostCheck,
		onError: () => notifyError('Artificial Intelligence recipe check failed'),
	});

	return { gtpInstagramPostCheckMutation };
};
