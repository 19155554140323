import { PaymentWallComponent } from '@/components/payments/PaymentWallComponent';
// import { useAuth } from '@/context/useAuth';
import { ScrollToTop } from '@/helper/scrollToTop';

// import { useCreatePaymentSession } from '@/hooks/payments/useCreatePaymentSession';

export const PaymentPage = () => {
	// const { user } = useAuth();

	// const { createPaymentSessionMutation } = useCreatePaymentSession();

	return (
		<div className="flex flex-col items-center py-6 bg-gradient-to-b from-brandBeigeStrong to-white">
			<ScrollToTop />
			<div className="mx-auto max-w-md lg:max-w-3xl pb-10 text-center">
				<p
					data-cy="paymentwall-title"
					className="text-3xl font-bold tracking-tight text-slate-700 mt-2 sm:text-4xl "
				>
					Upgrade your plan to enjoy unlimited exclusive content
				</p>
			</div>
			<div className="flex justify-center flex-wrap lg:grid-cols-3 mx-auto px-2 gap-4">
				<PaymentWallComponent
				// createPaymentSessionMutation={createPaymentSessionMutation}
				// customerDto={{
				// 	name: user?.name as string,
				// 	last_name: user?.last_name as string,
				// 	email: user?.email as string,
				// }}
				/>
			</div>
			<p className="mt-12 text-base leading-7 text-gray-600 ">
				You can unsubscribe at any time!
			</p>
		</div>
	);
};
