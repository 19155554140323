import { useNavigate } from 'react-router-dom';

export const PaymentSuccessPage = () => {
	const navigate = useNavigate();
	return (
		<div className="h-[70vh] grid place-content-center">
			<div className="flex flex-col items-center gap-y-10 px-4 py-8 mx-2 bg-emerald-500 rounded-3xl shadow-xl ring-1 ring-gray-900/10">
				<div className="flex flex-col items-center gap-y-2">
					<h2
						data-cy="payment-success-page-title"
						className="text-2xl sm:text-3xl text-white text-center"
					>
						The payment was successfully processed
					</h2>
					<p className="text-lg sm:text-xl text-white text-center">
						Enjoy your subscription on Kitched
					</p>
				</div>
				<div className="w-full flex justify-center gap-4">
					<button
						data-cy="payment-success-page-home-button"
						className="w-32 py-2 bg-slate-100 text-slate-600 rounded-3xl"
						onClick={() => navigate('/home')}
					>
						Home
					</button>
				</div>
			</div>
		</div>
	);
};
