import { AxiosError } from 'axios';
import { useFormik } from 'formik';

import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { notifyError } from '@/helper/notifications';
import { createCollectionValidationSchema } from '@/helper/validations/collection.validations';
import { useCreateCollection } from '@/hooks/collection/useCreateCollection';

interface CreateCollectionModalProps {
	open: boolean;
	onClose: () => void;
	onCreate: (name: string) => void;
}

export const CreateCollectionModal = ({
	open,
	onClose,
	onCreate,
}: CreateCollectionModalProps) => {
	const validationSchema = createCollectionValidationSchema();
	const { createCollectionMutation } = useCreateCollection(onClose);

	const formik = useFormik({
		initialValues: {
			title: '',
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await createCollectionMutation.mutateAsync(values);
				formik.resetForm();
				onCreate(values.title);
			} catch (error) {
				if (error instanceof AxiosError) {
					const errorMessageFromAPI = error.response?.data.message;
					if (Array.isArray(errorMessageFromAPI)) {
						const message = errorMessageFromAPI.join(', ');
						notifyError(message);
					}
				}
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<PopUp open={open} onClose={onClose} topCss="10vh">
			<div className="bg-white rounded-lg p-3 w-[80vw] max-w-[420px] -ml-3 font-helveticaNeue">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-2xl sm:text-3xl font-semibold">
						Create Collection
					</h2>
				</div>
				<form onSubmit={formik.handleSubmit} className="mb-4 mt-7">
					<div>
						<input
							type="text"
							name="title"
							className="w-full h-12 rounded-full focus:outline-none px-4 sm:px-6 text-lg focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							placeholder="Enter your collection name"
							value={formik.values.title}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.touched.title && formik.errors.title ? (
							<div className="text-alertRed pl-4 text-sm mt-1">
								{formik.errors.title}
							</div>
						) : null}
					</div>
					<div className="flex justify-end space-x-2 sm:space-x-4 mt-7">
						<button
							type="button"
							className="flex items-center justify-center gap-x-2 h-10 sm:h-12 w-24 sm:w-28 py-2 px-4 border-2 rounded-3xl bg-white border-brandRed text-brandRed text-sm hover:border-[#E1B864] hover:text-[#E1B864]"
							onClick={onClose}
						>
							Cancel
						</button>
						<button
							type="submit"
							className="flex items-center justify-center gap-x-2 h-10 sm:h-12 w-24 sm:w-28 py-2 px-4 bg-brandRed rounded-3xl text-white text-sm hover:bg-[#E1B864]"
							disabled={formik.isSubmitting}
						>
							{formik.isSubmitting ? <Loader size={1.4} /> : 'Create'}
						</button>
					</div>
				</form>
			</div>
		</PopUp>
	);
};
