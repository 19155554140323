import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import searchIconRed from '@/assets/svg/search-icon-red.svg';

interface ISearchInputProps {
	isInSearchPage?: boolean;
	isHomeFixedSearchInput?: boolean;
}

export const SearchInput = ({
	isInSearchPage,
	isHomeFixedSearchInput,
}: ISearchInputProps) => {
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchParam, setSearchParam] = useState<string>('');
	const navigate = useNavigate();

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.startsWith('#')) {
			setSearchValue(event.target.value);
			setSearchParam(`tag-${event.target.value.slice(1)}`);
		} else {
			setSearchValue(event.target.value);
			setSearchParam(event.target.value);
		}
	};

	const handleSearch = () => {
		if (searchValue.trim()) {
			navigate(`/search/${searchParam}`);
			setSearchValue('');
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') handleSearch();
	};

	return (
		<div
			className={`ring-1 ring-brandRed  rounded-[28px]  border-brandRed p-1 ${
				!isInSearchPage &&
				!isHomeFixedSearchInput &&
				'hidden sm:flex sm:justify-between sm:items-center'
			} ${isInSearchPage && 'w-full sm:w-96'} ${
				isHomeFixedSearchInput && 'w-full flex justify-between fixed'
			}  gap-x-2 items-center relative focus-within:text-gray-400 `}
			data-cy="navbar-search-input-container"
		>
			<input
				data-cy="navbar-search-input"
				type="text"
				value={searchValue}
				onChange={handleInputChange}
				onKeyDown={handleKeyPress}
				className={` ${
					isInSearchPage && 'w-full'
				} text-sm text-brandRed placeholder-brandRed bg-transparent focus:outline-none focus:text-brandRed pl-2`}
				placeholder="Search recipes..."
			/>
			<span className="flex items-center pr-2">
				<span
					className="p-1 focus:outline-none focus:shadow-outline cursor-pointer"
					onClick={handleSearch}
				>
					<img src={searchIconRed} alt="search-icon" />
				</span>
			</span>
		</div>
	);
};
