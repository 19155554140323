import { AxiosError } from 'axios';

import { EARLY_USERS_ERRORS_MESSAGES } from '@/error/early-users-errors.enum';
import {
	EarlyAccessUser,
	EarlyAccessUserDto,
} from '@/interface/early-access-user.interfaces';
import { IGetAllResponse } from '@/interface/get-all-response';
import Api from '@/service';

export const createEarlyAccessUser = async (
	user: EarlyAccessUser,
): Promise<EarlyAccessUser> => {
	try {
		const { data } = await Api.post('/early-access-user', user);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(EARLY_USERS_ERRORS_MESSAGES.CREATE_EARLY_USERS_ERROR);
	}
};

export const getAllEarlyAccessUser = async (
	take: number,
	skip: number,
): Promise<IGetAllResponse<EarlyAccessUserDto>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<EarlyAccessUserDto>>(
			'/early-access-user',
			{
				params: { take: take, skip: skip },
			},
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(EARLY_USERS_ERRORS_MESSAGES.GET_ALL_EARLY_USERS_ERROR);
	}
};

export const putEarlyUserByEmail = async ({
	email,
}: {
	email: string;
}): Promise<EarlyAccessUserDto> => {
	try {
		const { data } = await Api.put<EarlyAccessUserDto>(
			'/early-access-user/become/chef',
			{ email },
		);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(
			EARLY_USERS_ERRORS_MESSAGES.UPDATE_BECOME_CHEF_EARLY_USERS_ERROR,
		);
	}
};
