import { useState } from 'react';

import Spinner from '@/components/common/spinner/Spinner';
import useCreateChef from '@/hooks/chef/useCreateChef';
import useGetUserByEmail from '@/hooks/useGetUserByEmail';

const AddNewChefModal = ({ closeModal }: { closeModal: () => void }) => {
	const [email, setEmail] = useState('');
	const { createChefMutation } = useCreateChef(closeModal);
	const { user, isFetchingUser, refetchUser, isErrorLoadingUser } =
		useGetUserByEmail(email);

	const handleSearch = () => {
		if (email) {
			refetchUser();
		}
	};

	const handleAddChef = () => {
		if (user?.id) {
			createChefMutation.mutate(user);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleSearch();
		}
	};

	return (
		<div className="h-[450px] md:p-10">
			<h1 className="text-3xl font-semibold my-12 text-center">Add new chef</h1>
			<p className="mb-1 md:text-lg">Search user by email</p>
			<div className="flex gap-2 mb-4">
				<input
					type="email"
					data-cy="email-input"
					placeholder="Enter user email"
					className="border-2 border-gray-300 p-2 rounded-md flex-1 w-[200px] md:w-full"
					value={email}
					onKeyDown={handleKeyPress}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<button
					data-cy="search-button"
					className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md disabled:bg-blue-300 disabled:cursor-not-allowed"
					onClick={handleSearch}
					disabled={isFetchingUser || createChefMutation.isPending || !!user}
				>
					Search
				</button>
			</div>
			<div>
				{isFetchingUser && (
					<div className="flex justify-center mt-1">
						<Spinner></Spinner>
					</div>
				)}
				{user && (
					<div
						data-cy="user-details"
						className="max-w-md mx-auto bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 p-4"
					>
						<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
							User Details
						</h3>
						<div className="mt-2">
							<p className="text-sm text-gray-600 dark:text-gray-400">
								<span className="font-semibold">Name:</span> {user?.name}{' '}
								{user?.last_name}
							</p>
							<p className="text-sm text-gray-600 dark:text-gray-400">
								<span className="font-semibold">Email:</span> {user?.email}
							</p>
							{user.chef?.id && (
								<p className="text-sm font-semibold mt-3 text-center text-gray-600 dark:text-gray-400">
									This user is already a creator
								</p>
							)}
						</div>
						<div className="mt-4">
							<button
								data-cy="add-chef-button"
								className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:cursor-not-allowed disabled:bg-blue-300"
								onClick={handleAddChef}
								disabled={createChefMutation.isPending || !!user.chef}
							>
								{createChefMutation.isPending ? 'Adding...' : ' Add as Creator'}
							</button>
						</div>
					</div>
				)}
				{isErrorLoadingUser && (
					<div className="flex justify-center mt-1">
						<p className="text-red-500">User not found</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddNewChefModal;
