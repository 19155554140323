export interface CreateUserDto {
	name: string;
	email: string;
	referringChefUser?: number;
}

export interface UserPreferences {
	cookingLevel?: ICookingLevel;
	countryCuisinePreferences?: CountryCuisinePreferences[];
	dietaryPreferences?: DietaryPreferences[];
	dietaryConcernsAndAllergies?: DietaryConcernsAndAllergies[];
}

export enum ICookingLevel {
	AMATEUR = 'amateur',
	MEDIUM = 'medium',
	EXPERT = 'expert',
}
export enum DietaryPreferences {
	GLUTEN_FREE = 'glutenfree',
	DAIRY_FREE = 'dairyfree',
	VEGAN = 'vegan',
	VEGETARIAN = 'vegetarian',
	LOW_CARB = 'lowcarb',
	KETO = 'keto',
	PESCATARIAN = 'pescatarian',
	NUT_FREE = 'nutfree',
	LOW_SUGAR = 'lowsugar',
	HIGH_PROTEIN = 'highprotein',
	LOW_FAT = 'lowfat',
	HIGH_FIBER = 'highfiber',
	LOW_SODIUM = 'lowsodium',
	SUGAR_FREE = 'sugarfree',
	ORGANIC = 'organic',
	LOW_CALORIE = 'lowcalorie',
}

export enum DietaryConcernsAndAllergies {
	MILK = 'milk',
	EGG = 'egg',
	PEANUT = 'peanut',
	NUTS = 'nuts',
	SOY = 'soy',
	WHEAT = 'wheat',
	FISH = 'fish',
	SHELLFISH = 'shellfish',
}

export enum CountryCuisinePreferences {
	AFRICAN = 'african',
	AMERICAN = 'american',
	AUSTRALIAN = 'australian',
	ASIAN = 'asian',
	BRITISH = 'british',
	CHINESE = 'chinese',
	CANADIAN = 'canadian',
	CARIBBEAN = 'caribbean',
	MEXICAN = 'mexican',
	KOREAN = 'korean',
	ITALIAN = 'italian',
}
