import { Loader } from '../common/Loader';
import { CdnImage } from './CdnImage';

import userBlackIcon from '@/assets/svg/user-black.svg';
import { useAuth } from '@/context/useAuth';

interface CdnImageProps {
	loading: boolean;
	alt: string;
	width?: number;
	height?: number;
	className?: string;
	dataCy?: string;
}

export const ProfileImage = ({
	loading,
	alt,
	width,
	height,
	className,
	dataCy,
}: CdnImageProps) => {
	const { user, loading: authLoading } = useAuth();

	if (loading || authLoading) {
		return (
			<div className="w-full h-full flex justify-center items-center ">
				<Loader size={1} />
			</div>
		);
	}

	return (
		<CdnImage
			src={user?.profile_image?.src || userBlackIcon}
			alt={alt}
			className={className}
			width={width}
			height={height}
			data-cy={dataCy}
		/>
	);
};
