import follow from '@/assets/svg/follow.svg';
import { Chef } from '@/context/interfaces';
import { useManageFollowingChef } from '@/hooks/user-following/useManageFollowChef';
import { logChefFollow } from '@/service/analytics/analytics';

interface FollowButtonProps {
	chef: Chef | null | undefined;
	onFollowChange?: (isFollowing: boolean) => void;
	isCardStyle?: boolean;
}

export const FollowButton = ({
	chef,
	onFollowChange,
	isCardStyle,
}: FollowButtonProps) => {
	const {
		isFollow,
		addFollowChef,
		deleteUnfollowChef,
		isPendingFollowChef,
		isPendingunFollowChef,
	} = useManageFollowingChef(chef || ({ id: 0 } as Chef));

	const handleFollow = async () => {
		if (isFollow) {
			deleteUnfollowChef();
			if (onFollowChange) onFollowChange(false);
		} else {
			if (chef) {
				const chefFullName = `${chef.user?.name || 'Unknown'} ${
					chef.user?.last_name || 'Unknown'
				}`;
				logChefFollow(chef.uuid, chefFullName);
			}
			addFollowChef();
			if (onFollowChange) onFollowChange(true);
		}
	};

	return (
		<button
			onClick={handleFollow}
			className={`flex items-center justify-center disabled:cursor-not-allowed gap-x-1 bg-brandRed  text-sm text-white transition-colors hover:bg-[#E1B864] disabled:opacity-50 ${
				isCardStyle ? 'w-full rounded-b-3xl' : 'buttons-shape'
			}`}
			disabled={isPendingFollowChef || isPendingunFollowChef}
		>
			<img src={follow} alt="follow-icon" className="w-3" />
			<span className="pb-[2px]">{isFollow ? 'Unfollow' : 'Follow'}</span>
		</button>
	);
};
