import { useMutation } from '@tanstack/react-query';

import { User } from '@/context/interfaces';
import { notifyError } from '@/helper/notifications';
import {
	deleteUserFavouriteRecipe,
	putUserAddFavouriteRecipe,
} from '@/service/api/user';

interface SaveRecipeData {
	userId: number;
	recipeId: number;
}

const usePutUserFavouriteRecipe = () => {
	const mutation = useMutation<User, Error, SaveRecipeData>({
		mutationFn: async (data: SaveRecipeData) => {
			const { userId, recipeId } = data;
			return putUserAddFavouriteRecipe(userId, recipeId);
		},
		onError: (error: Error) => {
			notifyError(`${error.message}`);
		},
	});

	return mutation;
};

const useDeleteUserFavouriteRecipe = () => {
	const mutation = useMutation<User, Error, SaveRecipeData>({
		mutationFn: async (data: SaveRecipeData) => {
			const { userId, recipeId } = data;
			return deleteUserFavouriteRecipe(userId, recipeId);
		},
		onError: (error: Error) => {
			notifyError(`${error.message}`);
		},
	});

	return mutation;
};

export { usePutUserFavouriteRecipe, useDeleteUserFavouriteRecipe };
