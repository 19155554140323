import { PopUp } from '@/components/common/PopUp';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { ICollection } from '@/interface/collection.interfaces';

interface SelectRecipeCollectionModalProps {
	open: boolean;
	onClose: () => void;
	collection: ICollection;
}

export const HomeCollectionModal = ({
	open,
	onClose,
	collection,
}: SelectRecipeCollectionModalProps) => {
	const { recipes, title } = collection;

	return (
		<PopUp open={open} onClose={onClose}>
			<div className="h-[85vh] min-w-[89vw] flex flex-col gap-y-4 p-2 min-[400px]:modal-content font-helveticaNeue overflow-y-auto global-scrollbar">
				<h1 className="mt-2 text-2xl tracking-tight font-bold text-center sm:text-left sm:ml-2">
					{title}
				</h1>

				<section className="grid justify-center gap-y-10 gap-x-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ">
					{recipes?.map((recipe) => (
						<div key={recipe.id} className="w-full max-w-[235px]">
							<RecipesCard
								recipe={recipe}
								disableNavigation={true}
								userHasSubscription={true}
							/>
						</div>
					))}
				</section>
			</div>
		</PopUp>
	);
};
