import crossIcon from '@/assets/svg/cross.svg';
import DropdownMenuComingSoon from '@/components/common/DropdownMenuComingSoon';
import { Loader } from '@/components/common/Loader';
import { User } from '@/context/interfaces';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';
import { useUserPreferences } from '@/hooks/user/useUserPreferences ';
import { DietaryConcernsAndAllergies } from '@/interface/user.interfaces';

interface FavouriteCuisinesMenuProps {
	user: User;
}

export const ConcernsAndAllergiesMenu = ({
	user,
}: FavouriteCuisinesMenuProps) => {
	const {
		handledietaryConcernsAndAllergiesChange,
		isPendingPreferencesChange,
		preferences,
	} = useUserPreferences(user);

	const handleItemClick = (item: DietaryConcernsAndAllergies) => {
		handledietaryConcernsAndAllergiesChange(item);
	};

	return (
		<div className="flex flex-col gap-y-5 md:px-6 lg:px-0 2xl:px-6">
			<h5 className="text-xl font-semibold text-left">
				Dietary Concerns & Allergies
			</h5>

			<DropdownMenuComingSoon
				options={Object.values(DietaryConcernsAndAllergies)}
				selectedValues={preferences.dietaryConcernsAndAllergies || []}
				label="Concerns or Allergies"
				onChange={handleItemClick}
				renderItem={(item) => <span>{upperCaseFirstLetter(item)}</span>}
				formStyle={false}
				currentPage={1}
				totalPages={1}
				onHandleNext={() => console.log('next')}
				onHandlePrevious={() => console.log('previous')}
				hasMore={false}
			/>

			<div className="w-full">
				{isPendingPreferencesChange ? (
					<div className="flex justify-center lg:mt-4">
						<Loader size={2} />
					</div>
				) : (
					<div className="flex flex-wrap gap-x-1 gap-y-2 items-center max-h-[180px] overflow-y-auto global-scrollbar">
						{preferences?.dietaryConcernsAndAllergies?.map((item, index) => (
							<div
								data-cy={`user-concern-${item}`}
								key={index}
								className="flex items-center gap-x-3 px-3 py-2 bg-slate-100 rounded-3xl hover:bg-slate-200"
							>
								<span key={index} className="text-xs uppercase">
									{item}
								</span>
								<button onClick={() => handleItemClick(item)}>
									<img
										src={crossIcon}
										alt="cross-icon"
										className="w-3 pt-[2px]"
									/>
								</button>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
