import { useEffect, useState } from 'react';

import { PopUp } from '@/components/common/PopUp';

interface EditCollectionModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (newTitle: string) => void;
	initialTitle: string;
}

export const EditCollectionModal = ({
	isOpen,
	onClose,
	onSave,
	initialTitle,
}: EditCollectionModalProps) => {
	const [newTitle, setNewTitle] = useState<string>(initialTitle);

	useEffect(() => {
		setNewTitle(initialTitle);
	}, [initialTitle]);

	return (
		<PopUp open={isOpen} onClose={onClose} topCss="20vh">
			<div className="flex flex-col gap-y-4 p-6 font-helveticaNeue">
				<input
					data-cy="edit-collection-modal-input"
					type="text"
					value={newTitle}
					onChange={(event) => setNewTitle(event.target.value)}
					placeholder="Enter new collection name"
					className="w-full h-12 rounded-full focus:outline-none px-4 sm:px-6 text-lg focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50"
				/>
				<div className="flex justify-end space-x-2 mt-4">
					<button
						className="bg-white border-2 border-brandOrange text-brandOrange py-2 px-4 rounded-full hover:border-[#E1B864] hover:text-[#E1B864]"
						onClick={onClose}
					>
						Cancel
					</button>
					<button
						data-cy="edit-collection-modal-save-button"
						className="bg-brandOrange text-white py-2 px-4 rounded-full hover:bg-[#E1B864]"
						onClick={() => onSave(newTitle)}
					>
						Save
					</button>
				</div>
			</div>
		</PopUp>
	);
};
